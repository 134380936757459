/* eslint-disable react-hooks/exhaustive-deps */
import {
  Container,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { login, signInWithGoogle, signInWithFacebook, fetchEmailProviders } from "../../api/auth";
import { getGolferFromUserId, addGolfer } from "../../api/db";
import { emailValidate, passwordValidate } from "./validation";
import { AuthContext } from "../../contexts/AuthContext";
import { auth } from "../../config/firebase";
import { isNative, useQuery, getReferrer } from "../../utils/commonFunctions";
import AuthComponent from "./AuthComponent";
import { getIpLocation } from "../../config/geo";
import TagManager from 'react-gtm-module';
import { Box, styled, CircularProgress } from "@mui/material";

const LoadingWrapper = styled(Box)(() => ({
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  position: 'absolute',
  padding: 0,
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 100,
}))

export default function Login() {
  const navigate = useNavigate();
  const { updateUserData } = useContext(AuthContext);
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [location, setLocation] = useState("");
  const queryObj = useQuery();

  const { email, password } = user;

  useEffect(() => {
    if (!location) {
        getGeo();
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: 'smooth',
        });
    }
  }, [location]);

  // fetch geolocation and set for sending with the golfer record
  const getGeo = async () => {
    const loc = await getIpLocation();
    setLocation({...loc, referrer: getReferrer()});
  };


  useEffect(() => {
    const emailVal = queryObj.get("email");
    const errorVal = queryObj.get("error");

    // if an email address is set as a parameter, pre-populate the username
    if (!!emailVal) {
      setUser({
        ...user,
        email: emailVal,
      });
    }
    // if an error is set as a parameter, set the error message
    if(!!errorVal && !isNative) {
      switch (errorVal) {
        case "incorrect_signup":
          setErrorMessage("Something went wrong, please try again or contact us at support@ucoachugolf.com if the error persists.");
          break;
        default:
          setErrorMessage(errorVal);
          break;
      }
    }
  }, [queryObj]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage("");
    // setEmailErrorMessage("")
    // setPasswordErrorMessage("");

    const validateEmail = emailValidate(email);
    const validatePassword = passwordValidate(password);

    console.log("Email   ", validateEmail)

    if (email === "" || !validateEmail) {
      // setEmailErrorMessage("Please enter a valid email address");
      // setEmailError(true);
      setErrorMessage("Please enter a valid email address");
      setLoading(false);
      return
    }

    if (validatePassword) {
      // setPasswordErrorMessage(validatePassword);
      // setPasswordError(true);
      setErrorMessage(validatePassword);
      setLoading(false);
      return
    }

    if (email && password && validateEmail && !validatePassword) {
      login(user).then((data) => {
        if (data.error) {
          setErrorMessage(data.error);
        } else {
          updateUserData(auth.currentUser).then(() => {
            navigate("/dashboard");
          });
        }
        setLoading(false);
      });
    }
  };

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value.trim() });
  };

  const newUser = async (data) => {
    addGolfer({
      height: null,
      ability: null,
      handicap: null,
      userId: data.user.uid,
      name: "",
      _id: data.user.uid,
      golfMembershipId: "",
      registrationChannel : isNative ? "android" : "web",
      registrationChannelInfo : location
    }).then(() => {
      //send data layer sign up form event
      TagManager.dataLayer({
        dataLayer: {
          user_id: data.user.uid,
          event: "sign_up"
        }
      });
      updateUserData(data.user);
      // redirect to the dashboard
      setTimeout(() => {
        setLoading(false);
        navigate("/dashboard");
      }, 500);
    });
  }

  const navigateToSignup = () => {
    navigate('/signup')
  }

  const handleGoogleLogin = () => {
    setLoading(true)
    signInWithGoogle().then((data) => {
      getGolferFromUserId(data.user.uid).then((golfer) => {
        if (golfer && golfer.id) {
          updateUserData(data.user);
          // redirect to the dashboard
          setTimeout(() => {
            setLoading(false);
            navigate("/dashboard");
          }, 500);
        } else {
          newUser(data)
        }
      })
    }).catch(() => {
      setLoading(false);
    })
  }

  const handleFacebookLogin = () => {
    setLoading(true)
    signInWithFacebook().then((data) => {
      getGolferFromUserId(data.user.uid).then((golfer) => {
        if (golfer && golfer.id) {
          updateUserData(data.user);
          // redirect to the dashboard
          setTimeout(() => {
            setLoading(false);
            navigate("/dashboard");
          }, 500);
        } else {
          newUser(data)
        }
      })
    }).catch((e) => {
      if (e.code === 'auth/account-exists-with-different-credential') {
        fetchEmailProviders(e.customData.email)
          .then(providers => {
            let providerMethod = "a different method";
            if (providers && providers.includes("password")) {
              providerMethod = "email and password";
            }
            const errorMessage = `You are registered with ${e.customData.email} using ${providerMethod},
                                  please retry using that.`;
            setErrorMessage(errorMessage);
          })
      }
      setLoading(false);
    })
  }

  return (
    <Container disableGutters maxWidth={false} sx={{position: 'relative'}}>
      <AuthComponent
        navigateFunc={navigateToSignup}
        errorMessage={errorMessage}
        passwordErrorMessage={passwordErrorMessage}
        emailErrorMessage={emailErrorMessage}
        onChange={handleChange}
        onSubmit={handleSubmit}
        loading={loading}
        onGoogle={handleGoogleLogin}
        onFacebook={handleFacebookLogin}
       />
       {
          loading && (
            <LoadingWrapper>
              <CircularProgress style={{color: '#FFF'}}></CircularProgress>
            </LoadingWrapper>
          )
        }
    </Container>
  );
}
