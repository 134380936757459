import React from "react";
import { Box, Divider, Grid, Typography } from "@mui/material";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { swingDescriptionText, resourceURL } from "../../../utils/commonFunctions";

const SwingRow = (props) => {
  const {
    thumbnail_path,
    createdAt,
    processed,
    errors = [],
    video_name,
    warnings = [],
    score = 0,
    title,
    progress,
  } = props.videoData;
  const isAnalyzing = processed === 0 ? true : false;
  const warningMsg = warnings.length > 0 ? warnings[0] : "";
  const errorMsg = errors.length > 0 && processed === -1 ? errors[0] : "";
  let createdDate = new Date(0); // Epoch
  createdDate.setUTCSeconds(createdAt.seconds); // set seconds and convert it back to local date
  const progressPercentage =
    Math.round(progress * 100) > 100 ? 100 : Math.round(progress * 100);
  let navigate = useNavigate();
  return (
    <Grid
      item
      container
      sx={{
        backgroundColor: !isAnalyzing ? "primary.main" : "secondary.main",
        color: !isAnalyzing
          ? errorMsg
            ? "red.secondary"
            : "secondary.main"
          : "primary.main",
        display: "flex",
        flexDirection: { xs: "column-reverse", sm: "row" },
        my: 1,
        cursor: errorMsg ? "not-allowed" : "pointer",
        marginBottom: "64px",
        paddingLeft: "16px",
      }}
      spacing={1}
      onClick={() => {
        if (Math.floor(score * 100) > 0) {
          navigate("/swing-breakdown/" + props.videoData.id);
        }
      }}
    >
      <Grid
        container
        alignItems={"center"}
        justifyContent={"center"}
        columnSpacing={4}
        item
        sx={{ flex: 1, pb: 2}}
      >
        <Grid item container sm={3} xl={2.5} sx={{ alignItems: "center" }}>
          {!isAnalyzing ? (
            errorMsg ? (
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/error.png`}
                alt="Error"
                height={70}
              />
            ) : (
              <Typography
                variant="h2"
                component="h2"
                sx={{ fontFamily: "JetBrains Mono", fontSize: "65px" }}
              >
                {Math.floor(score * 100)}
              </Typography>
            )
          ) : (
            <Box sx={{ textAlign: "center" }}>
              <img
                src={"/assets/images/spinner.svg"}
                alt="React Logo"
                height={60}
                width={60}
              />
              <Typography
                variant="p"
                component="p"
                sx={{
                  fontSize: "14px",
                  fontFamily: "Inter",
                  fontWeight: "500",
                }}
              >
                {`${progressPercentage}%`}
              </Typography>
              <Typography
                variant="p"
                component="p"
                sx={{
                  fontSize: "14px",
                  fontFamily: "Inter",
                  fontWeight: "500",
                }}
              >
                Analyzing Swing
              </Typography>
            </Box>
          )}
        </Grid>
        <Grid item container sm={8} xl={9} columnSpacing={2}>
          <Divider
            variant={"middle"}
            orientation={"vertical"}
            color={"#CDCDCF"}
            sx={{ height: 36, display: { sm: "block", xs: "none" } }}
          />
          <Grid
            item
            container
            sm={8}
            md={6}
            xl={3.85}
            sx={{ alignItems: "center", pt: 1 }}
          >
            <Grid item xs={12}>
              <Typography
                variant="p"
                component="p"
                sx={{
                  fontSize: "18px",
                  color: isAnalyzing ? "primary.main" : "secondary.main",
                }}
              >
                {moment(createdDate).format("ddd DD MMM HH:mm a")}
              </Typography>
            </Grid>
          </Grid>
          <Divider
            variant={"middle"}
            orientation={"vertical"}
            color={"#CDCDCF"}
            sx={{ height: 36, display: { xl: "block", xs: "none" } }}
          />
          <Grid item sm={12} xl={8} sx={{ pt: 1 }}>
            <Typography
              variant="p"
              component="p"
              sx={{
                fontSize: "16px",
                overflowWrap: "break-word",
                paddingTop: "8px",
                color: isAnalyzing ? "primary.main" : "secondary.main",
              }}
            >
              {swingDescriptionText(title)}
            </Typography>
          </Grid>
          {(!!errorMsg || !!warningMsg) && (
            <Grid item xs={12}>
              <Typography
                variant="p"
                component="p"
                sx={{
                  fontFamily: "Inter",
                  fontSize: "14px",
                  color: !!errorMsg ? "red.secondary" : "yellow.secondary",
                  mb: "-20px",
                }}
              >
                {!!errorMsg ? errorMsg : warningMsg}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid
        item
        sx={{
          backgroundColor: "#EEECEC",
          width: { xs: "100%", sm: "154px" },
          maxHeight: "154px",
          padding: "0px !important",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          alignSelf: "center",
          overflow: "hidden",
        }}
      >
        <img
          src={resourceURL(thumbnail_path)}
          style={{
            height: "154px",
          }}
          alt={video_name}
        />
        <Box
          sx={{
            height: 1,
            width: 1,
            position: "absolute",
            top: 0,
            backgroundColor: "#00000077",
          }}
        >
          <img
            src={"/assets/images/rightArrow.svg"}
            alt="React Logo"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-7px",
              marginLeft: "-7px",
              width: "15px",
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default SwingRow;
