/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import SubHeader from "../../../components/subHeader";
import IssueInfoRow from "../../../components/issueInfoRow/IssueInfoRow";
import { useNavigate, useParams } from "react-router-dom";
import SwingRow from "./SwingRow";
import SwingLineRow from "../../../components/swingLineRow/SwingLineRow";
import BackButton from "../../../components/button/BackButton";
import { AuthContext } from "../../../contexts/AuthContext";
import { getSwingDetail } from "../../../api/swing";
import { isEmpty } from "lodash";
import ListingPlaceHolder from "../../../components/placeHolder/ListingPlaceHolder";
import { CustomButtonBlue } from "../../../components/button/CustomButton";
import UploadVideo from "../../../components/uploadVideo/UploadVideo";
import { useDispatch, useSelector } from "react-redux";
import { getCategoryByLevel } from "../../../utils/commonFunctions";
import { toggleUpgradeModal } from "../../../store/toastSlice";

const SwingBreakdown = () => {
  const breadCrumbArr = [
    { displayLink: "Dashboard", path: "/dashboard" },
    { displayLink: "Swing History", path: "/swing-history" },
    { displayLink: "Swing Breakdown", path: "/swing-breakdown" },
  ];
  const { swingId } = useParams();
  const { userData } = useContext(AuthContext);
  const dispatch = useDispatch()
  let navigate = useNavigate();
  const [swingLineReport, setSwingLineReport] = useState({});
  const [loading, toggleLoading] = useState(true);
  const isLocked = !(swingLineReport?.access === "full");
  const golferData = useSelector((state) => state.golfer.golferObj);
  let viewAs = false;

  useEffect(() => {
    const getSwingData = async (userId) => {
      if (!isEmpty(swingId)) {
        const lineReport = await getSwingDetail(userId, swingId, viewAs, userData?.uid); //use this in future to get details separately
        if (!lineReport)
          navigate("/dashboard?error=swing_detail_not_found");
        setSwingLineReport(lineReport);
      } else {
        navigate("/swing-history");
      }
      toggleLoading(false);
    };

    if (golferData?.userId) {
      viewAs = false;
      if (golferData?.userId !== userData?.uid){
        viewAs = true;
      }
      getSwingData(golferData?.userId);
    } else if (userData?.uid) {
      getSwingData(userData?.uid);
      viewAs = false;
    }
  }, [userData, golferData]);

  // Scroll to the top of the page when opened
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const RightContent = () => {
    return (
      <Grid container sx={{ justifyContent: { xs: "center", md: "flex-end" } }}>
        <Grid item xs={"auto"} sx={{ alignSelf: "center" }}>
          <IssueInfoRow />
        </Grid>
        { isLocked && <Grid item>
            <CustomButtonBlue onClick={() => {
                dispatch(toggleUpgradeModal(true))
              }}>
              <Typography
                component={"p"}
                sx={{
                  padding: "10px 4px",
                  fontSize: "16px",
                }}
              >
                Unlock Full Report
              </Typography>
            </CustomButtonBlue>
          </Grid>
        }
      </Grid>
    );
  };
  return (
    <Container maxWidth="xl">
      <SubHeader
        breadCrumbArr={breadCrumbArr}
        rightContent={<RightContent />}
      />
      <Box sx={{ marginBottom: 6 }}>
        {loading && <ListingPlaceHolder />}
        {!loading && (
          <>
            <BackButton />
            <SwingRow videoData={swingLineReport} />
            {Object.values(swingLineReport.swing_breakdown).map(
              (item, index) => {
                return (
                  <div key={index}>
                    { (index === 0 || index === 1 || index === 4 || index === 6 || index === 8 ) && <Typography
                      sx={{
                        fontSize: "20px",
                        fontFamily: "JetBrains Mono",
                        color: "secondary.main",
                        paddingTop: 2,
                      }}
                    >
                      { getCategoryByLevel(index) }
                    </Typography>}
                    <SwingLineRow
                      swingId={swingId}
                      swingBreakdownData={item}
                      rowId={index}
                      videoData={swingLineReport}
                      isLocked={
                        isLocked && index !== 0
                      }
                      onLockedClick={() => dispatch(toggleUpgradeModal(true))}
                    />
                  </div>
                );
              }
            )}
          </>
        )}
      </Box>
      <UploadVideo subscribeOnly />
    </Container>
  );
};

export default SwingBreakdown;
