/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Accordion, Container, Grid, Typography, Box } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BackButton from "../../../components/button/BackButton";
import IssueInfoRow from "../../../components/issueInfoRow/IssueInfoRow";
import SubHeader from "../../../components/subHeader";
import AccordionHeader from "./AccordionHeader";
import AccordionData from "./AccordionData";
import ListingPlaceHolder from "../../../components/placeHolder/ListingPlaceHolder";
import { AuthContext } from "../../../contexts/AuthContext";
import { isEmpty } from "lodash";
import { getSwingDetail } from "../../../api/swing";

const LineBreakdown = () => {
  let location = useLocation();
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [videoData, setVideoData] = useState({});
  const { swingId, lineNumber } = useParams();
  const { userData } = useContext(AuthContext);
  const golferData = useSelector((state) => state.golfer.golferObj);
  let viewAs = false;
  let navigate = useNavigate();
  useEffect(() => {
    const getSwingData = async (userId) => {
      if (
        !isEmpty(location) &&
        !isEmpty(location.state) &&
        !isEmpty(location.state.videoData)
      ) {
        setVideoData(location.state.videoData);
      } else if (!isEmpty(swingId) && !isEmpty(userId)) {
        const lineReport = await getSwingDetail(userId, swingId, viewAs, userData?.uid); //use this in future to get details separately
        if (!lineReport)
          navigate("/dashboard?error==swing_detail_not_found");
        setVideoData(lineReport);
      } else {
        navigate("/swing-history");
      }
      setLoading(false);
    };

    if (golferData?.userId) {
      viewAs = false;
      if (golferData?.userId !== userData?.uid){
        viewAs = true;
      }
      getSwingData(golferData?.userId);
    } else if (userData?.uid) {
      getSwingData(userData?.uid);
      viewAs = false;
    }
  }, [userData, golferData]);

  // Scroll to the top of the page when opened
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  let {
    // errors,
    swing_breakdown = {},
    metric_keys = [],
    metrics = [],
    line_urls = [],
  } = videoData;

  swing_breakdown = Object.values(swing_breakdown);
  const breadCrumbArr = [
    { displayLink: "Dashboard", path: "/dashboard" },
    { displayLink: "Swing History", path: "/swing-history" },
    { displayLink: "Swing Breakdown", path: "/swing-breakdown/" + swingId },
    { displayLink: "Line " + lineNumber, path: "/line/" + lineNumber },
  ];
  const accordionMainData = [];
  if (!isEmpty(metric_keys[lineNumber])) {
    metric_keys[lineNumber].forEach((item) => {
      accordionMainData.push(metrics[item]);
    });
  }

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const RightContent = () => {
    return (
      <Grid container sx={{ justifyContent: { xs: "center", md: "flex-end" } }}>
        <Grid item xs={"auto"} sx={{ alignSelf: "center" }}>
          <IssueInfoRow />
        </Grid>
      </Grid>
    );
  };

  const SwingLineRow = () => {
    return (
      <Grid
        container
        sx={{
          marginBottom: "15px",
          bgcolor: "primary.main",
          alignItems: "center",
          textAlign: { xs: "center", sm: "start" },
        }}
      >
        <Grid container
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            minHeight: "300px",
          }}
          >
          <Typography
            sx={{
              fontSize: "20px",
              fontFamily: "JetBrains Mono",
              color: "secondary.main",
              marginX: 2,
              margin: "auto",
              maxWidth: "50%",
              textAlign: "center",
            }}
          >
            {swing_breakdown[lineNumber].label} -{" "}
            {swing_breakdown[lineNumber].subtitle}
          </Typography>
          <Box
            component="img"
            sx={{
              maxHeight: { xs: 212, sm: 280 },
              maxWidth: { xs: 300, sm: 380 },
              margin: "auto",
            }}
            alt={swing_breakdown[lineNumber].subtitle}
            src={line_urls[lineNumber]}
          />
        </Grid>
      </Grid>
    );
  };
  return (
    <Container maxWidth="xl" sx={{ marginBottom: 6 }}>
      <SubHeader
        breadCrumbArr={breadCrumbArr}
        rightContent={<RightContent />}
      />
      {loading && <ListingPlaceHolder />}
      {!loading && (
        <>
          <BackButton />
          <SwingLineRow />
          {accordionMainData.map((item, index) => {
            return (
              <Accordion
                expanded={expanded === item.name}
                onChange={handleChange(item.name)}
                sx={{ marginBottom: 2 }}
                key={index}
              >
                <AccordionHeader data={item} />
                <AccordionData data={item} />
              </Accordion>
            );
          })}
        </>
      )}
    </Container>
  );
};

export default LineBreakdown;
