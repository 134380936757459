import React from "react";
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { styled } from '@mui/material'
import Paper from '@mui/material/Paper';
import { Link, useLocation } from "react-router-dom"
import HomeIcon from "../../assets/icons/Home";
import SwingIcon from "../../assets/icons/Swing";
import AccountIcon from "../../assets/icons/Account";

const MyBottomNavigationAction = styled(BottomNavigationAction)(`
  color: white;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: black;
  &.Mui-selected {
    color: white;
    border-top-color: red;
  }
`);

const TabBar = () => {
  const [value, setValue] = React.useState(1);
  const location = useLocation();

  React.useEffect(() => {
    if (location.pathname === '/') {
      setValue(1);
    } else if (location.pathname === '/newswing') {
      setValue(2);
    } else if (location.pathname === '/profile') {
      setValue(3);
    }
  }, [location.pathname])

  return (
    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <MyBottomNavigationAction label="Home" icon={<HomeIcon />} value={1} LinkComponent={Link} to="/" />
        <MyBottomNavigationAction label="Swing" icon={<SwingIcon />} value={2} LinkComponent={Link} to="/newswing" />
        <MyBottomNavigationAction label="Account" icon={<AccountIcon />} value={3} LinkComponent={Link} to="/profile"/>
      </BottomNavigation>
    </Paper>
  ) 
}

export default TabBar