import {
  getDoc,
  doc,
} from "@firebase/firestore";
import { db } from "../config/firebase";
import * as Sentry from "@sentry/react";

export const getSampleDrills = async () => {
  try {
    const docRef = doc(db, "misc", "sample_drills");
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      let drills = docSnap.data().drills;
      return drills;
    }
    return [];
  } catch (e) {
    Sentry.captureMessage("failed query fetch sample drills", "debug");
    Sentry.captureMessage(e.message+";"+e.code, "debug");
    return [];
  }
};