/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Container, Grid } from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import SubHeader from "../../../components/subHeader";
import SwingRow from "./SwingRow";
import MobileSwingRow from "./MobileSwingRow"
import { getSwingHistoryQuery } from "../../../api/swing";
import { AuthContext } from "../../../contexts/AuthContext";
import ListingPlaceHolder from "../../../components/placeHolder/ListingPlaceHolder";
import UploadVideo from "../../../components/uploadVideo/UploadVideo";
import "./swingHistory.css";
import { isNative } from "../../../utils/commonFunctions";
import BasePage from "../base/base";

export default function SwingHistory() {
  const breadCrumbArr = [
    { displayLink: "Dashboard", path: "/dashboard" },
    { displayLink: "Swing History", path: "/swing-history" },
  ];
  const { userData } = useContext(AuthContext);
  const [videos, setVideos] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [loading, toggleLoading] = useState(true);
  const [lastDoc, setLastDoc] = useState();
  const golferData = useSelector((state) => state.golfer.golferObj);
  let viewAs = false;
  let swingDataOnSnapShot = function () {}; //to unsubscribe onSnapshot on unmount

  useEffect(() => {
    if (golferData?.userId) {
      viewAs = false;
      if (golferData?.userId !== userData?.uid){
        viewAs = true;
      }
      getSwingData(golferData?.userId);
    } else if (userData?.uid) {
      getSwingData(userData?.uid);
      viewAs = false;
    }

    return () => swingDataOnSnapShot();
  }, [userData, golferData]);

  useEffect(() => {
    if (videos.length % 20 !== 0) {
      setHasMore(false);
    }
  }, [videos]);

  // Scroll to the top of the page when opened
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getSwingData = async (userId) => {
    let historyQuery = {
      userId: userId ? userId : golferData?.userId,
      setSwings: setVideos,
      toggleLoading,
      lastDoc,
      setLastDoc,
      existingSwings: videos,
      viewAs,
      loggedinUserId: userData?.uid,
    }
    if (isNative) {
      historyQuery = {
        ...historyQuery,
        forMobile: true
      }
    }
    swingDataOnSnapShot = await getSwingHistoryQuery(historyQuery);
  };

  return (
    <BasePage maxWidth="xl">
      <SubHeader breadCrumbArr={breadCrumbArr} rightContent={<UploadVideo />} />
      <Grid
        container
        sx={{ my: 4, flex: 1, display: "flex", flexDirection: "column", maxWidth: '100%' }}
      >
        {loading && <ListingPlaceHolder />}
        <InfiniteScroll
          dataLength={videos.length} //This is important field to render the next data
          next={getSwingData}
          hasMore={hasMore}
          style={{ overflowY: 'hidden', overflowX: 'hidden' }}
          loader={
            !loading && (
              <p style={{ textAlign: "center" }}>
                <b>Loading more swings...</b>
              </p>
            )
          }
          scrollThreshold={"100px"}
        >
          {!loading &&
            videos.map((item, index) => {
              if (isNative) return <MobileSwingRow videoData={item} key={index} k={index} userId={userData?.uid} />;
              return <SwingRow videoData={item} key={index} k={index} userId={userData?.uid} />;
            })}
        </InfiniteScroll>
      </Grid>
    </BasePage>
  );
}
