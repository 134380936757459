import React from "react";
import { Container } from "@mui/material";
import "./base.css";
import { isNative } from "../../../utils/commonFunctions";
import { Box, styled, CircularProgress, Dialog, DialogContent } from "@mui/material";
import { useSelector } from "react-redux";

const LoadingWrapper = styled(Box)(() => ({
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  position: 'absolute',
  padding: 0,
  left: 0,
  top: 0,
  width: '100vw',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 3000
}))

const BasePage = ({children}) => {
  const upgrading = useSelector((state) => state.toast.upgrading);

  return (
    <Container maxWidth="xl" style={{paddingBottom: isNative ? '60px': 0, position: 'relative'}}>
      {children}
      {
          isNative && (
            <Dialog
              open={upgrading}
              onClose={() => {}}
            >
                <DialogContent
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CircularProgress style={{color: '#FFF'}}></CircularProgress>
                </DialogContent>
            </Dialog>
          )
      }
    </Container>
  )
}

export default BasePage;