import React from "react";
import { Box, Divider, Grid, Typography } from "@mui/material";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { swingDescriptionText, resourceURL } from "../../../utils/commonFunctions";
import ErrorIcon from "../../../assets/icons/Error";
import UploadIcon from "../../../assets/icons/Upload";
// import DownloadIcon from "../../../assets/icons/Download";
import ArrowRightIcon from "../../../assets/icons/ArrowRight";
import { uploadVideoWithSwingData, deleteDocument } from "../../../api/swing";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Ucoachu } from "ucoachu-plugin";
import { ActionSheet, ActionSheetButtonStyle } from '@capacitor/action-sheet';

const SwingRow = (props) => {
  const {
    createdAt,
    processed,
    errors = [],
    video_name,
    warnings = [],
    score = 0,
    title,
    has_path,
    progress,
    id
  } = props.videoData;
  const isUploaded = has_path;
  const isAnalyzing = processed === 0 ? true : false;
  const warningMsg = warnings.length > 0 ? warnings[0] : "";
  const errorMsg = errors.length > 0 && processed === -1 ? errors[0] : "";
  let createdDate = new Date(0); // Epoch
  createdDate.setUTCSeconds(createdAt.seconds); // set seconds and convert it back to local date
  let navigate = useNavigate();
  const progressPercentage =
    Math.round(progress * 100) > 100 ? 100 : Math.round(progress * 100);
  const [uploading, setUploading] = React.useState(false);

  const uploadVideo = async () => {
    setUploading(true)
    try {
      await uploadVideoWithSwingData(video_name, id, props.userId)
    } catch {}
    setUploading(false)
  }

  const showActions = async () => {
    const result = await ActionSheet.showActions({
      title: '',
      message: '',
      options: [
        {
          title: 'Delete Video',
        },
        {
          title: 'Cancel',
          style: ActionSheetButtonStyle.Destructive,
        },
      ],
    });
  
    console.log('Action Sheet result:', JSON.stringify(result));
    if (result.index === 0) {
      deleteVideo()
    }
  };

  const deleteVideo = async () => {
    try {
      Ucoachu.deleteVideo({videoPath: video_name});
    } catch {}
    await deleteDocument(props.userId, id)
  }

  return (
    <Grid
      item
      container
      sx={{
        color: !isAnalyzing
          ? errorMsg
            ? "red.secondary"
            : "secondary.main"
          : "primary.main",
        backgroundColor: !isAnalyzing ? "transparent" : "secondary.main",
        display: "flex",
        flexDirection: { xs: "column-reverse", sm: "row" },
        my: 1,
        paddingY: '5px',
        cursor: errorMsg ? "not-allowed" : "pointer",
      }}
      onClick={() => {
        if (Math.floor(score * 100) > 0) {
          navigate("/swing-breakdown/" + props.videoData.id);
        }
      }}
    >
      <Grid
        container
        flexDirection={'row'}
        alignItems={"flex-start"}
        justifyContent={"center"}
        item
        sx={{ flex: 1}}
      >
        <Grid item container xs={2} sm={2} sx={{ alignItems: "center" }}>
          { !isUploaded ? (
            !uploading ? (
              <Box
                onClick={() => uploadVideo()}
              ><UploadIcon /></Box>
            ) : (
              <Box sx={{ textAlign: "center", width: '50px', height: '50px' }}>
                <img
                  src={"/assets/images/spinner.svg"}
                  alt="React Logo"
                  height={50}
                  width={50}
                />
              </Box>
            )
           ) :
            !isAnalyzing ? (
            errorMsg ? (
              <ErrorIcon />
            ) : (
              <Box alignItems={"center"} justifyContent={"center"} display={'flex'} sx={{backgroundColor: "green.primary", width: '50px', height: '50px'}}>
                <Typography
                  sx={{
                    fontSize: "20px",
                    fontFamily: "JetBrains Mono",
                    fontWeight: "400",
                  }}
                >
                  {Math.floor(score * 100)}
                </Typography>
              </Box>
            )
          ) : (
            <Box sx={{ textAlign: "center", width: '50px', height: '50px' }}>
              <img
                src={"/assets/images/spinner.svg"}
                alt="React Logo"
                height={50}
                width={50}
              />
            </Box>
          )}
        </Grid>
        <Grid item container xs={9} sm={9}>
          <Grid
            item
            container
            sm={8}
            md={6}
            xl={3.85}
            sx={{ alignItems: "center", pt: 1 }}
          >
            <Grid item xs={12}>
              <Typography
                variant="p"
                component="p"
                sx={{
                  fontSize: "18px",
                  color: !isUploaded ? "secondary.main" : isAnalyzing ? "primary.main" : "secondary.main",
                }}
              >
                {moment(createdDate).format("H:mma - ddd DD MMM")}
              </Typography>
            </Grid>
          </Grid>
          <Grid item sm={12} xl={8} sx={{ pt: 1 }}>
            <Typography
              variant="p"
              component="p"
              sx={{
                fontSize: "18px",
                overflowWrap: "break-word",
                color: !isUploaded ? "secondary.main" : isAnalyzing ? "primary.main" : "secondary.main",
              }}
            >
              {swingDescriptionText(title)}
            </Typography>
          </Grid>
          {(!!errorMsg || !!warningMsg) && (
            <Grid item xs={12}>
              <Typography
                variant="p"
                component="p"
                sx={{
                  fontSize: "14px",
                  color: !!errorMsg ? "red.secondary" : "yellow.secondary",
                  mb: "-20px",
                }}
              >
                {!!errorMsg ? errorMsg : warningMsg}
              </Typography>
            </Grid>
          )}
        </Grid>
        <Grid item container xs={1} sm={1} sx={{ alignItems: "center", paddingTop: '15px' }}>
          {isUploaded ? (<ArrowRightIcon />) : (
            <MoreHorizIcon
              onClick={() => showActions()}
              sx={{color: "secondary.main"}}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SwingRow;
