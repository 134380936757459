import { FirebaseCrashlytics } from '@capacitor-firebase/crashlytics';
import { isNative } from '../utils/commonFunctions';

export const crash = async () => {
  await FirebaseCrashlytics.crash({ message: 'Test' });
}

export const setUserId = async (userId) => {
  if (isNative) {
    await FirebaseCrashlytics.setUserId({
      userId,
    });
  }
};

export const log = async (message) => {
  if (isNative) {
    await FirebaseCrashlytics.log({
      message,
    });
  }
};

export const recordNonFatalException = async (message) => {
  if (isNative) {
    await FirebaseCrashlytics.recordException({ message })
  }
}