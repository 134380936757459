/* eslint-disable no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, InputLabel, TextField, Typography, Link } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import CustomButton, {
  CustomButtonOutlined,
} from "../../../components/button/CustomButton";
import { inputLabelProps } from "../../../styles/auth";
import { AuthContext } from "../../../contexts/AuthContext";
import * as _ from "lodash";
import { updateUserPassword, updateUserName, deleteUser, logout } from "../../../api/auth";
import { passwordValidate } from "../../../components/auth/validation";
import { useNavigate } from "react-router-dom";
import { updateGolfer } from "../../../api/db";
import { useDispatch, useSelector } from "react-redux";
import SubscriptionStatus from "./SubscriptionStatus";
import { toastError, toastSuccess } from "../../../utils/commonFunctions";
import {
  PASSWORD_CHANGED_MSG,
  SUCCESSFULLY_UPDATED,
  SUBSCRIPTION_ACTIVE,
  DELETE_ERROR,
} from "../../../_constants/globals";

const ProfileForm = () => {
  const [user, setUser] = useState({
    email: "",
    name: "",
    password: "",
    newPassword: "",
    confirmPassword: "",
    golfMembershipId: "",
  });
  const { userData, updateUserData } = useContext(AuthContext);
  const [nameError, setNameError] = useState(false);
  const [currentPasswordError, setCurrentPasswordError] = useState(false);
  const [deleteCurrentPasswordError, setDeleteCurrentPasswordError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [deleting, toggleDeleting] = useState(false);
  const navigate = useNavigate();
  const golferData = useSelector((state) => state.golfer.golferObj);
  const dispatch = useDispatch();
  useEffect(() => {
    const userObj = {
      email: userData.email,
      name: userData.displayName,
      golfMembershipId: golferData.golfMembershipId,
    };
    setUser(userObj);
  }, [golferData]);

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setNameError(false);
    setPasswordError(false);
    setCurrentPasswordError(false);
    setConfirmPasswordError(false);

    if (user.name === "") {
      setNameError(true);
    } else if (!_.isEmpty(user.name) && userData.displayName !== user.name) {
      updateUserName(user.name);
      updateUserData({
        ...userData,
        displayName: user.name,
      });
      toastSuccess(SUCCESSFULLY_UPDATED, dispatch);
    }

    if (user.golfMembershipId !== golferData.golfMembershipId
        || (!_.isEmpty(user.name) && golferData.name !== user.name)) {
      const newGolferData = {
        ...golferData,
        golfMembershipId: user.golfMembershipId,
        name: user.name,
      };
      updateGolfer(newGolferData).then((res) => {
        if (res) {
          toastSuccess(SUCCESSFULLY_UPDATED, dispatch);
        }
      });
    }

    if (
      !_.isEmpty(user.password) ||
      !_.isEmpty(user.newPassword) ||
      !_.isEmpty(user.confirmPassword)
    ) {
      const validatePassword = passwordValidate(user.password || "");
      const validateNewPassword = passwordValidate(user.newPassword || "");
      if (validatePassword) {
        setCurrentPasswordError(validateNewPassword);
      } else if (validateNewPassword) {
        setPasswordError(validateNewPassword);
      } else if (user.newPassword !== user.confirmPassword) {
        setConfirmPasswordError(true);
      } else {
        const status = await updateUserPassword(
          user.password,
          user.newPassword
        );
        if (status.error) {
          setCurrentPasswordError(status.error);
          toastError(status.error, dispatch);
        } else {
          toastSuccess(PASSWORD_CHANGED_MSG, dispatch);
        }
        setUser({
          ...user,
          password: "",
          newPassword: "",
          confirmPassword: "",
        });
      }
    }
  };

  // Account deletion routine
  const deleteAccount = async (e) => {
    e.preventDefault();
    toggleDeleting(true);
    setDeleteCurrentPasswordError(false);
    if (golferData.subscriptionStatus === "active" &&
              golferData.subscriptionAutoRenews){
      toastError(SUBSCRIPTION_ACTIVE, dispatch);
      toggleDeleting(false);
    } else {
      const newGolferData = {
        ...golferData,
        isDeleted: true,
      };
      updateGolfer(newGolferData).then((res) => {
        if (res) {
          deleteUser(user.deletePassword).then((delRes) =>{
            if (delRes.error) {
              console.log(delRes.error)
              toastError(DELETE_ERROR, dispatch);
            } else {
              logout().then(() => {
                updateUserData({});
                navigate("/signup");
              });
            }
          });
        }
      });
    }
  }

  return (
    <form onSubmit={handleSubmit} noValidate autoComplete="off">
      <Typography variant="h6" sx={{ mb: 3, fontSize: "18px" }}>
        Personal Information
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <InputLabel sx={{ ...inputLabelProps }}>Name</InputLabel>
          <TextField
            sx={{
              backgroundColor: "black",
              mt: 1,
              width: 1,
            }}
            required
            variant="outlined"
            color="secondary"
            name="name"
            onChange={handleChange}
            value={user.name}
            error={nameError}
            helperText={nameError ? "Please enter a Name" : ""}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputLabel sx={{ ...inputLabelProps }}>Email</InputLabel>
          <TextField
            sx={{
              backgroundColor: "black",
              mt: 1,
              width: 1,
            }}
            required
            variant="outlined"
            color="secondary"
            name="email"
            onChange={handleChange}
            value={user.email}
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputLabel sx={{ ...inputLabelProps }}>
            Golf Membership Id
          </InputLabel>
          <TextField
            sx={{
              backgroundColor: "black",
              mt: 1,
              width: 1,
            }}
            variant="outlined"
            color="secondary"
            name="golfMembershipId"
            onChange={handleChange}
            value={user.golfMembershipId}
          />
        </Grid>
      </Grid>
      <Typography variant="h6" sx={{ mb: 2, mt: 4, fontSize: "18px" }}>
        Subscription
      </Typography>
      <SubscriptionStatus />
      <Typography variant="h6" sx={{ my: 3, fontSize: "18px" }}>
        Change Password
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <InputLabel sx={{ ...inputLabelProps }}>Current Password</InputLabel>
          <TextField
            sx={{
              backgroundColor: "black",
              mt: 1,
              width: 1,
            }}
            required
            variant="outlined"
            type={"password"}
            color="secondary"
            name="password"
            value={user.password}
            onChange={handleChange}
            error={currentPasswordError}
            helperText={currentPasswordError ? currentPasswordError : ""}
            autoComplete="new-password"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputLabel sx={{ ...inputLabelProps }}>New Password</InputLabel>
          <TextField
            sx={{
              backgroundColor: "black",
              mt: 1,
              width: 1,
            }}
            required
            variant="outlined"
            type={"password"}
            color="secondary"
            name="newPassword"
            value={user.newPassword}
            onChange={handleChange}
            error={passwordError}
            helperText={passwordError ? passwordError : ""}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputLabel sx={{ ...inputLabelProps }}>Confirm Password</InputLabel>
          <TextField
            sx={{
              backgroundColor: "black",
              mt: 1,
              width: 1,
            }}
            required
            variant="outlined"
            type={"password"}
            color="secondary"
            name="confirmPassword"
            onChange={handleChange}
            value={user.confirmPassword}
            error={confirmPasswordError}
            helperText={
              confirmPasswordError ? "Entered passwords do not match" : ""
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ my: 2, paddingBottom: "16px" }}>
        <Grid item xs={12} sm={6}>
          <CustomButton
            type="submit"
            onClick={handleSubmit}
            sx={{ mr: 2, fontSize: "16px" }}
          >
            Save
          </CustomButton>
          <CustomButtonOutlined
            onClick={() => navigate("/dashboard")}
            sx={{ fontSize: "16px" }}
          >
            Cancel
          </CustomButtonOutlined>
        </Grid>
      </Grid>
      <Typography variant="h6" sx={{ my: 3, fontSize: "18px" }}>
        Delete Account
      </Typography>
      <Grid container spacing={2} sx={{paddingBottom: "16px"}}>
        <Grid item xs={12} sm={4} sx={{marginTop: "auto"}}>
          <Link
            color="#FF0000"
            onClick={deleteAccount}
            disabled={deleting}
          >
            Delete
          </Link>
        </Grid>
      </Grid>
    </form>
  );
};

export default ProfileForm;
