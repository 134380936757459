import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import ReactPlayer from "react-player";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { UPLOAD_INSTRUCTIONS_VIDEO } from "../../_constants/globals"

const VideoModal = (props) => {
  const {
    isOpen,
    handleClose,
    videoUrl = UPLOAD_INSTRUCTIONS_VIDEO,
    frame_height = 0,
    frame_width = 0,
  } = props;
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    height: "100%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 6,
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
  const closeIcon = {
    position: "absolute",
    top: "10px",
    right: "10px",
    zIndex: 2,
    color: "primary",
    fontSize: "30px",
    cursor: "pointer",
  };
  const aspectRatio =
    frame_height &&
    frame_width &&
    parseInt(frame_height) / parseInt(frame_width);
  return (
    <div style={{ position: "relative" }}>
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <CloseOutlinedIcon sx={closeIcon} onClick={() => handleClose()} />
          {aspectRatio ? (
            <ReactPlayer
              url={videoUrl}
              height={aspectRatio > 1 ? "100%" : "auto"}
              width={aspectRatio < 1 ? "100%" : "auto"}
              style={{ margin: "0px auto" }}
              controls={true}
            />
          ) : (
            <ReactPlayer
              url={videoUrl}
              height="100%"
              width="unset"
              style={{ margin: "0px auto" }}
              controls={true}
            />
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default VideoModal;
