import { Skeleton, Typography, Box } from "@mui/material";
import React from "react";

const ListingPlaceHolder = () => {
  const avatarRow = () => {
    return (
      <Box sx={{ display: "flex", alignItems: "center", flex: 1 }}>
        <Box sx={{ width: "100%", display: "flex", flex: 1 }}>
          <Skeleton width="100%" height={118}>
            <Typography>.</Typography>
          </Skeleton>
        </Box>
      </Box>
    );
  };
  return (
    <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
      {avatarRow()}
      {avatarRow()}
      {avatarRow()}
      {avatarRow()}
    </Box>
  );
};

export default ListingPlaceHolder;
