import React, { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import VideoModal from "../../../components/modal/FullScreenModal";
import moment from "moment";
import CustomButton from "../../../components/button/CustomButton";
import { useNavigate } from "react-router-dom";
import { hasError, resourceURL } from "../../../utils/commonFunctions";

const LatestSwing = (props) => {
  const {
    thumbnail_path,
    video_path,
    video_name,
    score = 0,
    frame_height = 0,
    frame_width = 0,
    createdAt,
    id,
    comment,
  } = props.data;
  let createdDate = new Date(0); // Epoch
  createdDate.setUTCSeconds(createdAt.seconds); // set seconds and convert it back to local date
  let navigate = useNavigate();

  const [videoUrl, setVideoUrl] = useState("");
  const hasErrorScore = hasError(props.data);
  return (
    <>
      <Typography
        component="p"
        variant="p"
        sx={{ marginTop: "35px", marginBottom: "-20px", fontSize: "14px" }}
      >
        Latest Swing
      </Typography>
      <Grid
        container
        sx={{
          justifyContent: "space-between",
          backgroundColor: "primary.main",
          padding: "20px",
          margin: "30px 0px",
        }}
      >
        <Grid
          item
          xs={12}
          sm={5}
          sx={{
            backgroundColor: "#EEECEC",
            width: { xs: "100%", md: "355px" },
            maxHeight: "260px",
            padding: "0px !important",
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
            overflow: "hidden",
            marginLeft: 1,
            cursor: "pointer",
          }}
          onClick={() => setVideoUrl(video_path)}
        >
          <img
            src={resourceURL(thumbnail_path)}
            style={{
              width: "100%",
            }}
            alt={video_name}
          />
          <Box
            sx={{
              height: 1,
              width: 1,
              position: "absolute",
              top: 0,
              backgroundColor: "#00000077",
            }}
          >
            <img
              src={"/assets/images/play.svg"}
              alt="latest swing play"
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-25px",
                marginLeft: "-25px",
                width: "50px",
              }}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{ alignItems: "center", textAlign: "center" }}
        >
          <Typography
            variant="p"
            component="p"
            sx={{
              fontFamily: "Inter",
              fontSize: "18px",
              lineHeight: "24px",
            }}
          >
            {moment(createdDate).format("ddd DD MMM, HH:mm a")}
          </Typography>
          {!hasErrorScore && (
            <Typography
              variant="h2"
              component="h2"
              sx={{
                fontFamily: "JetBrains Mono",
                fontSize: "55px",
                lineHeight: "65px",
                margin: "20px 0px",
              }}
            >
              {Math.floor(score * 100)}
            </Typography>
          )}
          <Typography
            variant="p"
            component="p"
            sx={{
              fontFamily: "Inter",
              fontSize: "16px",
              lineHeight: "23px",
              margin: "20px 0px",
            }}
          >
            {comment}
          </Typography>
          <CustomButton
            onClick={() => !hasErrorScore && navigate("/swing-breakdown/" + id)}
            style={{ cursor: hasErrorScore ? "not-allowed" : "pointer" }}
          >
            <Typography sx={{ fontSize: "16px" }}>{"Full Analysis"}</Typography>
          </CustomButton>
        </Grid>
      </Grid>
      {
        //elements for modal
        !!videoUrl && (
          <VideoModal
            isOpen={!!videoUrl}
            handleClose={() => setVideoUrl("")}
            videoUrl={resourceURL(videoUrl)}
            frame_width={frame_width}
            frame_height={frame_height}
          />
        )
      }
    </>
  );
};

export default LatestSwing;
