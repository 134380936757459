import React, { useState, useEffect } from "react";
import {
  Container,
  Accordion,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import SubHeader from "../../../components/subHeader";
import VideoModal from "../../../components/modal/FullScreenModal";
import { getSampleDrills } from "../../../api/drills";
import { resourceURL } from "../../../utils/commonFunctions";
import ListingPlaceHolder from "../../../components/placeHolder/ListingPlaceHolder";

const Drills = () => {
  const [videoUrl, setVideoUrl] = useState("");
  const [drills, setDrills] = React.useState([]);
  const [loading, toggleLoading] = React.useState(true);
  const breadCrumbArr = [
    { displayLink: "Dashboard", path: "/dashboard" },
    { displayLink: "Drills", path: "/drills" },
  ];

  useEffect(() => {
    if(drills.length === 0) {
      getDrills();
    }
  }, [drills]);

  const getDrills = async () => {
    let sampleDrills = await getSampleDrills();
    setDrills(sampleDrills);
    toggleLoading(false);
  };

  const renderFixes = () => {
    return (
      <>
      { drills?.length > 0 && drills?.map((item, index) => {
            return (
        <Accordion
          expanded={true}
          sx={{ marginBottom: 2 }}
          key={`drill_${index}`}
        >
          <Box
            sx = {{
              minHeight: "64px",
              margin: "20px 0px",
              padding: "0px 16px",
            }}
          >
            <Typography
              component={"h5"}
              variant={"h5"}
              sx={{
                fontSize: "20px",
                fontFamily: "JetBrains Mono",
                textTransform: "capitalize",
              }}
            >
              {item.title}
            </Typography>
            <Typography
              component={"p"}
              variant="p"
              sx={{
                fontSize: "18px",
                fontFamily: "Inter",
                lineHeight: "22px",
              }}
            >
              {item.sub_title}
            </Typography>
          </Box>
          <Grid container sx={{ flexDirection: { xs: "column", sm: "row" } }}>
            <Grid
              item
              xs={12}
              sm={5}
              sx={{
                backgroundColor: "#EEECEC",
                width: { xs: "100%", md: "355px" },
                maxHeight: "260px",
                padding: "0px !important",
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
                overflow: "hidden",
                marginLeft: 1,
                cursor: "pointer",
              }}
              onClick={() => setVideoUrl(item.fix_video)}
            >
              <img
                src={resourceURL(item.image_path)}
                style={{
                  width: "100%",
                }}
                alt=""
              />
              <Box
                sx={{
                  height: 1,
                  width: 1,
                  position: "absolute",
                  top: 0,
                  backgroundColor: "#00000077",
                }}
              >
                <img
                  src={"/assets/images/play.svg"}
                  alt="latest swing play"
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-25px",
                    marginLeft: "-25px",
                    width: "50px",
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm sx={{ paddingLeft: 2 }}>
            <Box>
                <Typography
                  component={"h5"}
                  variant={"h5"}
                  sx={{
                    fontSize: "18px",
                    fontFamily: "Inter",
                  }}
                >
                  {item.impact}
                </Typography>
              </Box>
              <br></br>
              <Box>
                <Typography
                  component={"h5"}
                  variant={"h5"}
                  sx={{
                    fontSize: "18px",
                    fontFamily: "Inter",
                  }}
                >
                  {item.fix}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Accordion>
        )
        })
      }
    </>
    );
  };

  return (
    <Container maxWidth="xl">
      <SubHeader breadCrumbArr={breadCrumbArr} />
      <Grid item sm={12}>
        <Typography
          sx={{
            fontFamily: "Inter",
            fontWeight: "400",
            fontSize: "18px",
            lineHeight: "22px",
            paddingTop: "22px",
          }}
        >
          Below are some common drills that have proven to help our members. uCOACHu has a collection of over 300 drills, when you upload your swing, your analysis will provide you with the most appropriate ones to work at any time.
        </Typography>
      </Grid>
      { loading && <ListingPlaceHolder /> }
      { drills.length > 0 && renderFixes() }
      <VideoModal
            isOpen={!!videoUrl}
            handleClose={() => setVideoUrl("")}
            videoUrl={resourceURL(videoUrl)}
            frame_width="600"
            frame_height="0"
          />
    </Container>
  );
};

export default Drills;
