import React from "react";
import { SvgIcon } from '@mui/material';

const HomeIcon = (props) => {
  return (
    <svg width="31" height="32" viewBox="0 0 31 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M25.7863 30.0017H3.30286C2.42689 30 1.58728 29.6575 0.967872 29.0492C0.348464 28.4408 -0.000262598 27.6162 -0.00195312 26.7558V11.3286C-0.00195312 9.75178 1.04706 7.83778 2.38604 6.96663L12.0596 0.684346C13.4518 -0.220303 15.6352 -0.220303 17.0296 0.684346L26.7031 6.96663C28.0357 7.8315 29.1017 9.7455 29.1017 11.3286V26.7621C29.0989 27.6234 28.7482 28.4483 28.1265 29.0557C27.5049 29.6631 26.6632 30.0034 25.7863 30.0017ZM14.5456 1.88217C14.044 1.87027 13.5492 1.99883 13.1192 2.25283L3.44572 8.53511C2.63337 9.06282 1.9191 10.3654 1.9191 11.3244V26.7579C1.91967 27.1188 2.06586 27.4647 2.32564 27.7198C2.58543 27.975 2.93761 28.1185 3.305 28.1191H25.7863C26.1537 28.1185 26.5058 27.975 26.7656 27.7198C27.0254 27.4647 27.1716 27.1188 27.1722 26.7579V11.3286C27.1722 10.3695 26.46 9.06701 25.6477 8.5393L15.9742 2.25701C15.5438 2.00149 15.0484 1.87148 14.5456 1.88217Z" fill="white"/>
    </svg>
  );
}

export default HomeIcon