import { ref, uploadBytesResumable } from "firebase/storage";
import React from "react";
import { ErrorCode } from "react-dropzone";
import { useLocation } from "react-router-dom";
import { getGolferFromUserId, getGolferFromUserIdViewAs } from "../api/db";
import { addSwing } from "../api/swing";
import { auth, storage } from "../config/firebase";
import { toggleToast } from "../store/toastSlice";
import {
  INVALID_FILE_ERROR,
  LARGE_FILE_SIZE_ERROR,
  SMALL_FILE_SIZE_ERROR,
  SWING_UPLOADED,
  UPLOADING_SWING,
  CDN
} from "../_constants/globals";
import { Capacitor } from "@capacitor/core";

export const isNative = Capacitor.isNativePlatform()

export const generateRandomFileName = (fileName) => {
  const fileExtension = fileName.split(".").pop();
  // If the user uploads multiple files, there is a risk that fileName could be a duplicate, so create a unique file name to avoid this
  return (
    Date.now().toString() +
    Math.floor(Math.random() * 100) +
    "." +
    fileExtension
  );
};

export const swingDescriptionText = (string) => {
  return string.length > 45 ? string.slice(0, 42) + "..." : string;
};

export const generateVideoFileName = () => {
  const date = new Date();
  const dateStr =
    date.getFullYear() +
    ("00" + (date.getMonth() + 1)).slice(-2) +
    ("00" + date.getDate()).slice(-2) +
    ("00" + date.getHours()).slice(-2) +
    ("00" + date.getMinutes()).slice(-2) +
    ("00" + date.getSeconds()).slice(-2);
  return dateStr
}

export const isSubscriptionActive = (golfer) => {
  return (
    golfer.subscriptionStatus === "active" ||
    golfer.subscriptionStatus === "limited"
  );
};

export const checkIfSubscriptionIsLimited = (golfer) => {
  return golfer.subscriptionStatus === "limited";
};

export const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

export const hasError = (swingObj) => {
  return swingObj.processed === -1;
};

export const toastError = (errorMsg, dispatch) => {
  dispatch(
    toggleToast({
      msg: errorMsg,
      type: "error",
    })
  );
  return true;
};

export const toastSuccess = (successMsg, dispatch) => {
  dispatch(
    toggleToast({
      msg: successMsg,
      type: "success",
    })
  );
};

export const clearToast = (dispatch) => {
  dispatch(
    toggleToast({
      msg: "",
      type: "",
    })
  );
};

export const rejectedFileError = (fileRejections) => {
  let errorMsg = "";
  if (fileRejections[0].errors[0].code === ErrorCode.FileInvalidType) {
    errorMsg = INVALID_FILE_ERROR;
  } else if (fileRejections[0].errors[0].code === ErrorCode.FileTooLarge) {
    errorMsg = LARGE_FILE_SIZE_ERROR;
  } else if (fileRejections[0].errors[0].code === ErrorCode.FileTooSmall) {
    errorMsg = SMALL_FILE_SIZE_ERROR;
  } else {
    errorMsg = INVALID_FILE_ERROR;
  }
  return errorMsg;
};

export const uploadVideoFile = async ({
  fileRejections,
  acceptedFiles,
  setProgress,
  dispatch,
  toggleLoading,
  handleClose = () => {},
  golferId,
  viewAs,
}) => {
  if (fileRejections.length > 0) {
    let errorMsg = rejectedFileError(fileRejections);
    toastError(errorMsg, dispatch);
  }
  if (acceptedFiles.length > 0) {
    toggleLoading(true);

    const file = acceptedFiles[0];
    const uniqueFileName = generateRandomFileName(file.name);
    let golfer;
    let video_path = "video_uploads/" + auth.currentUser.uid + "/" + uniqueFileName;
    // switch query and video path based on viewAs flag to fetch the different golfer
    if(viewAs) {
      golfer = await getGolferFromUserIdViewAs(golferId);
      video_path = "video_uploads/" + golferId + "/"
        + auth.currentUser.uid + "/" + uniqueFileName;
    } else {
      golfer = await getGolferFromUserId();
    }

    setProgress(UPLOADING_SWING);
    const videoRef = ref(storage, video_path);
    const uploadVideo = uploadBytesResumable(videoRef, file);

    uploadVideo.on(
      "state_changed",
      (snapshot) => {
        setProgress(
          Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
        );
      },
      (error) => {
        console.log(error);
        setProgress(0);
        toggleLoading(false);
      },
      async () => {
        await addSwing(golfer, file, video_path);
        setProgress(0);
        handleClose();
        toastSuccess(SWING_UPLOADED, dispatch);
        toggleLoading(false);
      }
    );
  }
};

export const resourceURL = (resourcePath) => {
  return CDN+resourcePath;
};

export const getUserAgent = () => {
  return window?.navigator?.userAgent;
};

export const getReferrer = () => {
  return document?.referrer;
};

export const getCategoryByLevel = (level) => {
  if (level === 0)
    return "Set-up"
  else if (level >= 1 && level < 4)
    return "Backswing";
  else if (level >= 4 && level < 6)
    return "Downswing";
  else if (level >= 6 && level < 8)
    return "Impact-zone";
  else if (level >= 8 && level < 10)
    return "Follow-through";
  else
    return null;
}