export const getIpLocation = async () => {
    const url = `https://api.db-ip.com/v2/free/self`;
    let loc = {};
    try{
        await fetch(url, { method: "GET" }).then(function(response) {
            return response.json();
        }).then(function(data) {
            loc = data;
        });
    } catch(e){
        // do nothing as this is not critical
    }
    return loc;
};