import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showToast: { msg: "", type: "" },
  showUpgradeModal: false,
  upgrading: false
};

export const toastSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    toggleToast: (state, action) => {
      state.showToast = action.payload;
    },
    toggleUpgradeModal: (state, action) => {
      state.showUpgradeModal = action.payload
    },
    toggleUpgrading: (state, action) => {
      state.upgrading = !state.upgrading
    }
  },
});

// Action creators are generated for each case reducer function
export const { toggleToast, toggleUpgradeModal, toggleUpgrading } = toastSlice.actions;

export default toastSlice.reducer;
