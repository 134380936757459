import React, { useContext, useState, useEffect } from "react";
import {
  Grid,
  Typography,
  TextField,
  CircularProgress,
  Alert,
  AlertTitle,
  InputLabel,
  MenuItem,
  Container,
  Box,
  Link as TextLink,
  styled,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { signup, signInWithGoogle, signInWithFacebook } from "../../api/auth";
import { emailValidate, passwordValidate } from "./validation";
import ButtonUnstyled from "@mui/base/ButtonUnstyled";
import {
  customButtonStyle,
  inputLabelProps,
  textFieldProps,
} from "../../styles/auth";
import { AuthContext } from "../../contexts/AuthContext";
import { addGolfer, updateGolfer, getGolferFromUserId } from "../../api/db";
import TagManager from 'react-gtm-module';
import { getIpLocation } from "../../config/geo";
import { getReferrer, isNative } from "../../utils/commonFunctions";
import "./Signup.css";
import AuthComponent from "./AuthComponent";

const LoadingWrapper = styled(Box)(() => ({
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  position: 'absolute',
  padding: 0,
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 100,
}))

const Signup = () => {
  const [user, setUser] = useState({
    name: "",
    email: "",
    password: "",
  });
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState({
    height: "",
    ability: "",
    handicap: "",
    golfMembershipId: "",
  });
  const [display, setDisplay] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [registeredUserData, setRegisteredUserData] = useState({});
  const [location, setLocation] = useState("");

  const { name, email, password } = user;
  const { height, ability, handicap, golfMembershipId } = details;
  const { updateUserData } = useContext(AuthContext);

  const CustomButtonRoot = customButtonStyle;
  const navigate = useNavigate();

  useEffect(() => {
    if (!location) {
        getGeo();
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: 'smooth',
        });
    }
  }, [location]);

  // fetch geolocation and set for sending with the golfer record
  const getGeo = async () => {
    const loc = await getIpLocation();
    setLocation({...loc, referrer: getReferrer()});
  };

  useEffect(() => {
    return () => {
      setUser({});
    };
  }, []);

  function CustomButton(props) {
    return <ButtonUnstyled {...props} component={CustomButtonRoot} />;
  }

  const handleTermChange = () => {
    setTermsChecked(!termsChecked)
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setNameError(false);
    setEmailError(false);
    setPasswordError(false);

    const validateEmail = emailValidate(email);
    const validatePassword = passwordValidate(password);

    if (name === "") {
      setNameError(true);
      setErrorMessage("Please enter a valid name")
      setLoading(false);
      return
    }
    if (email === "" || !validateEmail) {
      setEmailErrorMessage("Please enter a valid email address");
      setErrorMessage("Please enter a valid email address")
      setEmailError(true);
      setLoading(false);
      return
    }

    if (validatePassword) {
      setPasswordErrorMessage(validatePassword);
      setErrorMessage(validatePassword)
      setPasswordError(true);
      setLoading(false);
      return
    }

    if (!termsChecked) {
      setErrorMessage("Please accept our terms and policy")
      setLoading(false)
      return
    }

    if (name && email && password && validateEmail && !validatePassword && termsChecked) {
      setErrorMessage("");
      const data = await signup(user);
      setLoading(false);
      if (data.error) {
        setErrorMessage(data.error);
      } else {
        setRegisteredUserData(data.user);
        addGolfer({
          height: height ? height : null,
          ability: ability ? ability : null,
          handicap: handicap ? handicap : null,
          userId: data.user.uid,
          name,
          _id: data.user.uid,
          golfMembershipId,
          registrationChannel : isNative ? "android" : "web",
          registrationChannelInfo : location
        }).then(() => {    
          //send data layer sign up form event
          TagManager.dataLayer({
            dataLayer: {
              user_id: data.user.uid,
              event: "sign_up"
            }
          });
          updateUserData(data.user);
          // redirect to the dashboard
          setTimeout(() => {
            setLoading(false);
            navigate("/dashboard");
          }, 500);
        });
        setDisplay(false);
      }
    } else {
      setErrorMessage("There are error(s), please see below.");
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value.trim() });
  };

  const handleDetails = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const optionRange = (start, stop, step) =>
    Array.from(
      { length: (stop - start) / step + 1 },
      (_, i) => start + i * step
    );
  const handicapOptions = optionRange(0, 66, 1);
  const heightOptions = optionRange(140, 210, 1);
  const handicapDisplay = (item) => {
    let value = item - 56;
    if(value <=0)
      return Math.abs(value);
    else
      return '+'+Math.abs(value);
  };

  const handleClick = (e) => {
    e.preventDefault();
    setLoading(true);
    updateGolfer({
      heightInCentimeters: height ? height : null,
      ability: ability ? ability : null,
      handicap: handicap ? handicap : null,
      userId: registeredUserData.uid,
      name,
      golfMembershipId,
      registrationChannel : isNative ? "android" : "web",
      registrationChannelInfo : location
    }).then(() => {
      updateUserData(registeredUserData);

      setTimeout(() => {
        setLoading(false);
        navigate("/dashboard");
      }, 500);
    });
  };

  const navigateToLogin = () => {
    navigate('/login')
  }

  const newUser = async (data) => {
    addGolfer({
      height: height ? height : null,
      ability: ability ? ability : null,
      handicap: handicap ? handicap : null,
      userId: data.user.uid,
      name,
      _id: data.user.uid,
      golfMembershipId,
      registrationChannel : isNative ? "android" : "web",
      registrationChannelInfo : location
    }).then(() => {
      //send data layer sign up form event
      TagManager.dataLayer({
        dataLayer: {
          user_id: data.user.uid,
          event: "sign_up"
        }
      });
      updateUserData(data.user);
      // redirect to the dashboard
      setTimeout(() => {
        setLoading(false);
        navigate("/dashboard");
      }, 500);
    });
    setDisplay(false);
  }

  const afterSocialLogin = (data) => {
    setRegisteredUserData(data.user);
      
      getGolferFromUserId(data.user.uid).then((golfer) => {
        if (golfer && golfer.id) {
          updateUserData(data.user);
          // redirect to the dashboard
          setTimeout(() => {
            setLoading(false);
            navigate("/dashboard");
          }, 500);
        } else {
          newUser(data)
        }
      })
  }

  const handleGoogleLogin = () => {
    setLoading(true)
    signInWithGoogle().then((data) => {
      afterSocialLogin(data)
    }).catch(() => {
      setLoading(false)
    })
  }

  const handleFacebookLogin = () => {
    setLoading(true)
    signInWithFacebook().then((data) => {
      afterSocialLogin(data)
    }).catch(() => {
      setLoading(false)
    })
  }

  return (
    <Container disableGutters maxWidth={false} sx={{position: 'relative'}}>
      {!display ? (
        <AuthComponent
          isRegistration
          navigateFunc={navigateToLogin}
          errorMessage={errorMessage}
          nameError={nameError}
          passwordErrorMessage={passwordErrorMessage}
          emailErrorMessage={emailErrorMessage}
          onChange={handleChange}
          onSubmit={handleSubmit}
          loading={loading}
          termsChecked={termsChecked}
          onTermChange={handleTermChange}
          onGoogle={handleGoogleLogin}
          onFacebook={handleFacebookLogin}
        />
      ) : (
        <Grid container sx={{ justifyContent: "center", textAlign: "center" }}>
          <Grid item elevation={0} xs={12} md={4}>
            <Grid>
              <Typography variant="h2" component="h2">
                Welcome, {name}!
              </Typography>
              <Typography
                variant="body"
                component="p"
                sx={{ fontFamily: "Inter" }}
              >
                To help us give you the useful analytics,
              </Typography>
              <Typography
                variant="body"
                component="p"
                sx={{ fontFamily: "Inter" }}
              >
                please tell us a bit more about yourself.{" "}
                <TextLink
                    sx={{ 
                        fontFamily: "Inter", 
                        fontWeight: 600,
                        cursor: "pointer",
                      }}
                    onClick={handleClick}
                    color="secondary"
                  >
                  Skip
                </TextLink>
              </Typography>
              <CircularProgress
                color="inherit"
                style={{ display: loading ? "" : "none" }}
              />
            </Grid>
            <form onSubmit={handleClick} noValidate autoComplete="off">
              <Grid sx={{ mt: 4 }}>
                <InputLabel sx={{ ...inputLabelProps, mt: 4 }}>
                  Height
                </InputLabel>
                <TextField
                  sx={{ ...textFieldProps, textAlign: "start" }}
                  required
                  variant="outlined"
                  color="secondary"
                  name="height"
                  value={height}
                  onChange={handleDetails}
                  select
                  fullWidth
                >
                  {heightOptions.map((option) => {
                    return (
                      <MenuItem value={option} key={option}>
                        {option} cm
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid sx={{ mt: 4 }}>
                <InputLabel sx={{ ...inputLabelProps }}>
                  Golf Ability
                </InputLabel>
                <TextField
                  sx={{ ...textFieldProps, textAlign: "start" }}
                  required
                  variant="outlined"
                  color="secondary"
                  name="ability"
                  value={ability}
                  onChange={handleDetails}
                  select
                  fullWidth
                >
                  <MenuItem value={0}>Pro Golfer</MenuItem>
                  <MenuItem value={1}>Handicap Golfer</MenuItem>
                  <MenuItem value={2}>Social Golfer</MenuItem>
                  <MenuItem value={3}>Absolute Beginner</MenuItem>
                </TextField>
              </Grid>
              <Grid sx={{ mt: 4 }}>
                <InputLabel sx={{ ...inputLabelProps }}>Handicap</InputLabel>
                <TextField
                  sx={{ ...textFieldProps, textAlign: "start" }}
                  required
                  variant="outlined"
                  color="secondary"
                  name="handicap"
                  value={handicap}
                  onChange={handleDetails}
                  select
                  fullWidth
                >
                  {handicapOptions.map((item) => {
                    return (
                      <MenuItem value={item} key={item}>
                        {handicapDisplay(item)}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid sx={{ mt: 4 }}>
                <InputLabel sx={{ ...inputLabelProps }}>
                  Golf Membership Id
                </InputLabel>
                <TextField
                  sx={{ ...textFieldProps }}
                  autoFocus
                  required
                  variant="outlined"
                  color="secondary"
                  name="golfMembershipId"
                  onChange={handleDetails}
                  value={golfMembershipId}
                  fullWidth
                />
                <Typography
                  component="p"
                  variant="p"
                  sx={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "9px",
                    lineHeight: "25px",
                    color: "#FDFBFB",
                    textAlign: "left",
                  }}
                >
                  *You may qualify for a promotional offer based on your
                  membership
                </Typography>
              </Grid>
              <Grid>
                <CustomButton
                  type="submit"
                  onClick={handleClick}
                  sx={{ width: 1, my: 4 }}
                >
                  Finished
                </CustomButton>
              </Grid>
            </form>
          </Grid>
        </Grid>
      )}
      {
        loading && (
          <LoadingWrapper>
            <CircularProgress style={{color: '#FFF'}}></CircularProgress>
          </LoadingWrapper>
        )
      }
    </Container>
  );
};

export default Signup;
