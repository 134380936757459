import React, { useContext, useState } from "react";
import BasePage from "../base/base";
import {
  Typography,
  Grid,
  Box,
  styled,
  Button,
  Container,
  CircularProgress,
} from "@mui/material";
import DownPerson from "../../../assets/icons/DownPerson";
import FacePerson from "../../../assets/icons/FacePerson";
import DownOnboard from "../../../assets/icons/DownOnboard";
import FaceOnboard from "../../../assets/icons/FaceOnboard";
import { Ucoachu, RecordResult } from "ucoachu-plugin";
import {
  createVideoData,
  updateDocumentWithStorageVideo,
} from "../../../api/swing";
import { AuthContext } from "../../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isSubscriptionActive } from "../../../utils/commonFunctions";
import { toggleUpgradeModal } from "../../../store/toastSlice";
import UploadVideo from "../../../components/uploadVideo/UploadVideo";
import {
  NATIVE_VIDEO_UPLOAD_FAIL,
  NATIVE_VIDEO_UPLOAD_SUCCESS,
} from "../../../_constants/globals";
import { recordNonFatalException } from "../../../api/crashlytics";

const PageWrapper = styled(Container)(() => ({
  background: "url(../../../assets/background.png) no-repeat center",
  padding: 0,
  position: "relative",
}));

const GridItemWrapper = styled(Grid)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const Item = styled(Box)(() => ({
  backgroundColor: "rgba(0, 0, 0, 0.6)",
  width: "140px",
  height: "230px",
  alignSelf: "center",
  padding: "5px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}));

const BlueButton = styled(Button)(() => ({
  backgroundColor: "#1D70F9",
  borderRadius: "4px",
  height: "50px",
  width: "108px",
  color: "white",
  textTransform: "none",
  fontSize: "14px",
  "&:hover": {
    backgroundColor: "#1D70F9",
  },
}));

const ImageWrapper = styled(Box)(() => ({
  flex: "1",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
  alignItems: "center",
  paddingBottom: "16px",
}));

const CameraWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
  alignItems: "center",
  height: "137px",
}));

const TextBox = styled(Box)(() => ({
  width: "140px",
  height: "59px",
  fontSize: "12px",
  color: "white",
  textAlign: "center",
}));

const PickVideo = styled(Button)(() => ({
  fontSize: "14px",
  fontWeight: "bold",
  color: "white",
  textAlign: "center",
  textTransform: "none",
  textDecoration: "underline",
  alignSelf: "center",
  "&:hover": {
    textDecoration: "underline",
  },
}));

const LoadingWrapper = styled(Box)(() => ({
  backgroundColor: "rgba(0, 0, 0, 0.8)",
  position: "absolute",
  padding: 0,
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

export default function NewSwing() {
  const { userData } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const golferData = useSelector(state => state.golfer.golferObj);
  const isSubscribed = isSubscriptionActive(golferData);

  const afterRecord = async result => {
    if (
      result.clickedButton === "Analyze" ||
      result.clickedButton === "AnalyzeLater"
    ) {
      setLoading(true);
      const uploadResult = await createVideoData(
        userData?.uid,
        result.videoTitle,
        result.recordMode,
        result.videoFile
      );
      if (result.clickedButton === "Analyze") {
        Ucoachu.uploadVideo({
          videoPath: result.videoFile,
          fileName: uploadResult.fileName,
          userId: userData?.uid,
        })
          .then(async result => {
            setLoading(false);
            if (result && result.videoPath) {
              await updateDocumentWithStorageVideo(
                userData?.uid,
                uploadResult.documentId,
                result.videoPath,
                uploadResult.fileName
              );
              alert(NATIVE_VIDEO_UPLOAD_SUCCESS);
              navigate("/");
            }
            if (result.error) {
              setLoading(false);
              alert(NATIVE_VIDEO_UPLOAD_FAIL);
              recordNonFatalException(result.error);
            }
          })
          .catch(error => {
            setLoading(false);
            alert(NATIVE_VIDEO_UPLOAD_FAIL);
          });
      } else {
        setLoading(false);
        navigate("/");
      }
    } else {
      navigate("/");
    }
  };

  const openRecording = landscape => {
    if (!isSubscribed) {
      dispatch(toggleUpgradeModal(true));
      return;
    }
    if (landscape) Ucoachu.recordVideo({ mode: "landscape" }).then(afterRecord);
    else Ucoachu.recordVideo({ mode: "portrait" }).then(afterRecord);
  };

  const openPick = () => {
    if (!isSubscribed) {
      dispatch(toggleUpgradeModal(true));
      return;
    }
    Ucoachu.pickVideo().then(afterRecord);
  };
  return (
    <BasePage>
      <PageWrapper>
        <Typography
          component="p"
          variant="h4"
          sx={{ marginTop: "26px", marginLeft: "30px" }}
        >
          How will you be recording?
        </Typography>
        <Grid container sx={{ marginTop: "32px" }}>
          <GridItemWrapper xs={6} md={6}>
            <Item>
              <ImageWrapper>
                <DownPerson />
              </ImageWrapper>
              <BlueButton onClick={() => openRecording(false)}>
                Down Plane
              </BlueButton>
            </Item>
          </GridItemWrapper>
          <GridItemWrapper xs={6} md={6}>
            <Item>
              <ImageWrapper>
                <FacePerson />
              </ImageWrapper>
              <BlueButton onClick={() => openRecording(true)}>
                Face On
              </BlueButton>
            </Item>
          </GridItemWrapper>
        </Grid>
        <Grid container sx={{ marginTop: "16px" }}>
          <GridItemWrapper xs={6} md={6}>
            <TextBox>Record in portrait looking down the line</TextBox>
          </GridItemWrapper>
          <GridItemWrapper xs={6} md={6}>
            <TextBox>Record in landscape facing the camera</TextBox>
          </GridItemWrapper>
        </Grid>
        {/* <Grid container sx={{marginTop: '12px'}}>
          <GridItemWrapper xs={6} md={6}>
            <CameraWrapper>
              <DownOnboard />
            </CameraWrapper>
          </GridItemWrapper>
          <GridItemWrapper xs={6} md={6}>
            <CameraWrapper>
              <FaceOnboard />
            </CameraWrapper>
          </GridItemWrapper>
        </Grid> */}
        <Grid container sx={{ marginTop: "12px" }}>
          <GridItemWrapper xs={12} md={12}>
            <PickVideo onClick={() => openPick()}>
              Upload Pre-recorded
            </PickVideo>
          </GridItemWrapper>
        </Grid>
      </PageWrapper>
      {loading && (
        <LoadingWrapper>
          <CircularProgress style={{ color: "#FFF" }}></CircularProgress>
        </LoadingWrapper>
      )}
      <UploadVideo subscribeOnly />
    </BasePage>
  );
}
