import isEmail from "validator/lib/isEmail";

export const emailValidate = (email) => {
  if (isEmail(email)) {
    return true;
  }
  return false;
};

export const passwordValidate = (password) => {
  if (password === "") {
    return "Please enter a valid password";
  }
  if (password.length < 6) {
    return "Please enter a password of minimum 6 characters";
  }
  return "";
};
