import React from "react";
import { Box, Typography, Link } from "@mui/material";
import { CustomButtonBlue } from "../button/CustomButton";
import { CURRENCY_EXCHANGE } from "./../../_constants/globals"
import { isNative } from "../../utils/commonFunctions";

const SubscriptionBox = (props) => {
  const productTitle = props.title;
  const currencyHREF = CURRENCY_EXCHANGE.replace("_AMOUNT", props.amount);

  // Modification for trial
  const subscriptionTrialUsed = props?.golferData?.subscriptionTrialUsed;
  let buttonText = "Purchase";
  if (productTitle === "Day Pass") {
    buttonText = "Purchase";
  } else if (subscriptionTrialUsed) {
    buttonText = "Subscribe";
  } else
    buttonText = "Free Trial";

  let planDescription = props.description;
  if (productTitle === "Monthly" && !subscriptionTrialUsed)
    planDescription = "Ongoing advantage, try it risk free for 7 days"
  else if (productTitle === "Yearly" && !subscriptionTrialUsed)
    planDescription = "Unbelievable price, try it risk free for 7 days"

  return (
    <>
      <Box
        sx={{
          padding: "10px 5px",
          background: "#000000",
          border: props.isActive ? "2px solid #F2F1F5" : "2px solid #363541",
          borderRadius: "2px",
          width: isNative ? "200px" : "165px",
          margin: { xs: "0px auto 20px auto", md: "0px 10px" },
          cursor: "pointer",
          minHeight: "180px",
        }}
        onClick={props.onSelect}
      >
        <Typography
          sx={{
            fontSize: "16px",
            color: "#FDFBFB",
            margin: "10px 0",
            fontFamily: "JetBrains Mono",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "18px",
          }}
        >
          {props.title}
        </Typography>
        <Typography
          sx={{
            fontSize: "30px",
            lineHeight: "40px",
            color: "#FDFBFB",
            margin: "10px 0",
            fontFamily: "JetBrains Mono",
            fontStyle: "normal",
            fontWeight: "500",
          }}
        >
          {!isNative ? `$${props.amount}` : props.amount} 
        </Typography>
        <Typography
          sx={{
            fontSize: "11px",
            lineHeight: "18px",
            color: "#FDFBFB",
            margin: "10px 0",
            fontFamily: "JetBrains Mono",
            fontStyle: "normal",
            fontWeight: "500",
          }}
        >
          {planDescription}
        </Typography>
      </Box>
      {props.isActive && (
        <Box
          sx={{ marginTop: "20px", marginBottom: { xs: "30px", md: "0px" } }}
        >
          <CustomButtonBlue
            onClick={props.onSubscribeClick}
            sx={{ width: "165px" }}
            disabled={props?.loading}
          >
            <Typography
              component={"p"}
              sx={{
                padding: "10px 4px",
                fontFamily: "JetBrains Mono",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "16px",
                lineHeight: "18px",
              }}
            >
              {buttonText}
            </Typography>
          </CustomButtonBlue>
          <Typography
            sx={{
              fontSize: "11px",
              lineHeight: "18px",
              color: "#FDFBFB",
              margin: "10px 0",
              fontFamily: "JetBrains Mono",
              fontStyle: "normal",
              fontWeight: "500",
            }}
          >
            {!isNative && (props.isNZD ? <Link
                underline="hover"
                color="text.primary"
                target="_blank"
                rel="noopener"
                href={currencyHREF}
              >
                *all prices are in NZD
              </Link>
              : "*all prices are in USD"
            )}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default SubscriptionBox;
