import React from "react";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const BackButton = () => {
  const navigate = useNavigate();
  return (
    <Button
      startIcon={
        <img
          src={"/assets/images/rightArrow.svg"}
          alt="back button"
          style={{
            width: "12px",
            transform: "rotateY(180deg)",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        />
      }
      sx={{ paddingX: 0 }}
      onClick={() => navigate(-1)}
    >
      <Typography
        sx={{
          color: "secondary.main",
          fontSize: "14px",
          fontFamily: "Inter",
        }}
      >
        Back
      </Typography>
    </Button>
  );
};

export default BackButton;
