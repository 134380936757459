import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
  Box,
} from "@mui/material";
import React, { useState } from "react";
import VideoModal from "../../../components/modal/FullScreenModal";
import { resourceURL } from "../../../utils/commonFunctions";

const AccordionData = (props) => {
  const [expanded, setExpanded] = useState("Overview");
  const [videoUrl, setVideoUrl] = useState("");
  const { comparison_images, graphic_path, line, name, penalty } = props.data;

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const headerComp = (title) => {
    return (
      <AccordionSummary
        expandIcon={
          <img
            src={"/assets/images/play.svg"}
            alt="line breakdown header play"
            style={{
              width: "25px",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          />
        }
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        sx={{ paddingX: 0 }}
      >
        <Box>
          <Typography
            component={"h5"}
            variant={"h5"}
            sx={{
              fontSize: expanded === title ? "20px" : "16px",
              fontFamily: "JetBrains Mono",
            }}
          >
            {title}
          </Typography>
        </Box>
      </AccordionSummary>
    );
  };

  const accordionData = (field) => {
    const defaultTitle = `${name} at line ${line}`;
    const defaultVideo = props.data[field + "_video"];
    let videos = props.data[field + "_videos"];
    if ((!videos || videos.length === 0) && defaultVideo) {
      videos = [{
        url : defaultVideo,
        title : defaultTitle
      }]
    }
    return (
      <AccordionDetails sx={{ paddingX: 0 }}>
        <Typography
          component={"p"}
          variant="p"
          sx={{ fontSize: "14px", fontFamily: "Inter" }}
        >
          {props.data[field]}
        </Typography>
        { videos &&  videos.length > 0 && videos.map((videoURL, i) =>  <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            marginTop: 2,
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={() => setVideoUrl(videoURL.url)}
          key={i}
        >
          <img
            src={"/assets/images/play.svg"}
            alt="line breakdown detail play"
            style={{
              width: "50px",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          />
          <Box sx={{ marginLeft: 2 }}>
            <Typography
              component={"p"}
              variant="p"
              sx={{ fontSize: "16px", fontFamily: "Inter" }}
            >
              View Video
            </Typography>
            <Typography
              component={"p"}
              variant="p"
              sx={{
                fontSize: "16px",
                fontFamily: "Inter",
                textTransform: "capitalize",
              }}
            >
              {videoURL.title}
            </Typography>
          </Box>
        </Box>)}
      </AccordionDetails>
    );
  };
  return (
    <AccordionDetails>
      <Grid container sx={{ flexDirection: { xs: "column", sm: "row" } }}>
        <Grid
          item
          sx={{
            display: { xs: "flex" },
            justifyContent: "center",
            alignItems: "center",
            flex: { xs: 1 },
            flexDirection: { sm: "row" },
          }}
        >
          <img
            src={resourceURL(graphic_path)}
            alt="shot"
            style={{
              width: "50%",
            }}
          />
          {comparison_images && comparison_images[0]?<img
            src={resourceURL(comparison_images[0]?.image_path)}
            alt="shot"
            style={{
              width: "50%",
            }}
          />:null}
        </Grid>
        <Grid item xs={12} sm sx={{ paddingLeft: 2 }}>
          <Accordion
            expanded={expanded === "Overview"}
            onChange={handleChange("Overview")}
          >
            {headerComp("Overview")}
            {accordionData("problem")}
          </Accordion>
          {penalty > 0 && (
            <>
              <Accordion
                expanded={expanded === "Consequence of poor execution"}
                onChange={handleChange("Consequence of poor execution")}
              >
                {headerComp("Consequence of poor execution")}
                {accordionData("impact")}
              </Accordion>
              <Accordion
                expanded={expanded === "How to fix it"}
                onChange={handleChange("How to fix it")}
              >
                {headerComp("How to fix it")}
                {accordionData("fix")}
              </Accordion>
            </>
          )}
        </Grid>
      </Grid>
      {
        //elements for modal
        !!videoUrl && (
          <VideoModal
            isOpen={!!videoUrl}
            handleClose={() => setVideoUrl("")}
            videoUrl={resourceURL(videoUrl)}
          />
        )
      }
    </AccordionDetails>
  );
};

export default AccordionData;
