import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
  Alert,
  AlertTitle,
  CircularProgress,
  Grid,
} from "@mui/material";
import { sendPasswordResetMail } from "../../api/auth";
import { emailValidate } from "./validation";

export default function ForgotPassword({ isDialogOpened, handleCloseDialog }) {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState(false);

  const handleClose = () => {
    handleCloseDialog(false);
  };

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const validateEmail = emailValidate(email);
    if (email === "" || !validateEmail) {
      setError("Please enter a valid email address");
      setLoading(false);
      return;
    }

    sendPasswordResetMail(email).then((data) => {
      setLoading(false);
      setMessage(true);
    });
  };

  return (
    <>
      <Dialog open={isDialogOpened} onClose={handleClose}>
        <DialogTitle sx={{ fontFamilyL: "JetBrains" }}>
          Reset password
        </DialogTitle>
        <Grid align="center">
          <CircularProgress
            color="inherit"
            sx={{ display: loading ? "" : "none" }}
          />
        </Grid>
        <DialogContent>
          <Alert
            variant="filled"
            severity="error"
            sx={{ display: error ? "" : "none" }}
          >
            <AlertTitle>
              <strong>Error: {error}</strong>
            </AlertTitle>
          </Alert>
          
          <Typography
            variant="body"
            sx={{ display: message ? "" : "none", fontFamily: "Inter" }}
          >
            Email sent to {email}
          </Typography>
          <div
            style={{
              display: message ? "none" : "",
              fontFamily: "Inter",
              color: "secondary.main",
            }}
          >
            <DialogContentText
              sx={{ color: "secondary.main", fontFamily: "Inter" }}
            >
              To reset password, please enter your email address here. We will
              send email with further instructions.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              name="email"
              label="Email Address"
              type="email"
              fullWidth
              variant="standard"
              onChange={handleChange}
              color="secondary"
            />
          </div>
        </DialogContent>
        <DialogActions style={{ display: message ? "none" : "" }}>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="secondary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

ForgotPassword.propTypes = {
  isDialogOpened: PropTypes.bool,
  handleCloseDialog: PropTypes.func,
};
