/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Card, LinearProgress, Typography, Popover } from "@mui/material";
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import CustomButton, { CustomButtonBlue } from "../button/CustomButton";
import { uploadVideoFile, getUserAgent, isNative } from "../../utils/commonFunctions";
import { useDispatch, useSelector } from "react-redux";
import ReactPlayer from "react-player";
import "./index.css";
import { UPLOAD_INSTRUCTIONS_VIDEO, APPLE_DOWNLOAD } from "../../_constants/globals";
import DeviceDetector from "device-detector-js";

export const EmptyDashboard = () => {
  const [progress, setProgress] = useState(0);
  const [loading, toggleLoading] = useState(false);
  const dispatch = useDispatch();
  const golferData = useSelector((state) => state.golfer.golferObj);
  const showToast = useSelector((state) => state.toast.showToast);
  const userAgent = getUserAgent();
  const deviceDetector = new DeviceDetector();
  const device = deviceDetector.parse(userAgent);
  // if the device is a desktop or OS is iOS, we'll display the Apple download button
  const displayAppleDownload = device.device?.type === "desktop" || device.os?.name === "iOS"

  const onDrop = useCallback(async (acceptedFiles, fileRejections) => {
    uploadVideoFile({
      fileRejections,
      acceptedFiles,
      setProgress,
      dispatch,
      toggleLoading,
      golferId : golferData?.userId,
      viewAs : golferData?.viewAs,
    });
  }, [golferData]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "video/*",
    maxFiles: 1,
    maxSize: 31457280, //30MB
    minSize: 2097152, //2MB
    disabled: loading,
  });


  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const nonNativePart = () => {
    return(
      <>
          <Typography
          component="p"
          sx={{
            fontFamily: "JetBrains Mono",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "30px",
            lineHeight: "40px",
            color: "#FDFBFB",
            marginTop: "40px",
          }}
        >
          Upload your first swing
        </Typography>
        <Box
          textAlign="center"
          sx={{
            backgroundColor: "#000",
            padding: "24px 16px",
            marginTop: "20px",
          }}
        >
          <Typography
            component="p"
            sx={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: "16px",
              lineHeight: "22px",
              color: "#FFFFFF",
              textAlign: "center",
              maxWidth: "90%",
              margin: "0 auto",
            }}
          >
            Record and upload a swing to learn how it works.
          </Typography>
          <Card
            sx={{
              fontFamily: "Inter",
              mt: 4,
              background: "#000000",
              margin: "5% auto",
              border:
                showToast.type === "error"
                  ? "1px dashed #e57373"
                  : "1px dashed #BFC0C1",
              borderRadius: "4px",
              width: "90%",
              padding: "6px 0px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
            {...getRootProps({ className: "dropzone" })}
          >
            <input {...getInputProps()} />
            {parseInt(progress) !== 0 || loading ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mt: 3,
                  width: "100%",
                  flex: 1,
                  flexDirection: "column",
                }}
              >
                <img
                  src={"/assets/images/spinner.svg"}
                  alt="React Logo"
                  height={140}
                  width={140}
                />
                <Typography
                  variant="body1"
                  color={"secondary"}
                  sx={{ margin: "20px 0" }}
                >
                  Uploading...
                </Typography>
                <Box sx={{ width: "80%", margin: "0 auto" }}>
                  <LinearProgress
                    variant="determinate"
                    color="secondary"
                    value={parseInt(progress)}
                  />
                </Box>
                <Box sx={{ minWidth: 35 }}>
                  <Typography
                    variant="h3"
                    color={"secondary"}
                    sx={{ margin: "20px 0" }}
                  >{`${progress}%`}</Typography>
                </Box>
              </Box>
            ) : (
              <>
                <FileUploadOutlinedIcon sx={{ height: "40px", width: "40px" }} />
                <Typography
                  component={"p"}
                  sx={{
                    padding: "10px",
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: "normal",
                    fontSize: "16px",
                    lineHeight: "19px",
                  }}
                >
                  Drag and drop a video file here
                  <br />
                  <br />
                  OR
                  <br />
                  <br />
                </Typography>
                <CustomButtonBlue>
                  <Typography
                    component={"p"}
                    sx={{
                      padding: "10px",
                      fontFamily: "JetBrains Mono",
                      fontStyle: "normal",
                      fontWeight: "normal",
                      fontSize: "16px",
                      lineHeight: "18px",
                    }}
                  >
                    Browse Files
                  </Typography>
                </CustomButtonBlue>

                <Typography
                  component={"p"}
                  sx={{
                    padding: "10px",
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: "normal",
                    fontSize: "14px",
                    lineHeight: "16px",
                    color: "#BFC0C1",
                  }}
                >
                  <br />
                  Upload a video be between 2MB and 30MB
                </Typography>
              </>
            )}
          </Card>
          <CustomButton 
            aria-describedby={id}
            onClick={handleClick}
            sx={{ mr: 2, fontSize: "16px", margin: 2}}
          >
            See sample dashboard
          </CustomButton>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
          <Box
            component="img"
            alt="Sample Dashboard"
            src="/assets/images/sample_dashboard.jpeg"
          />
          </Popover>
          { displayAppleDownload && 
            <Card
              sx={{
                margin: "5% auto",
                background: "#000000"
              }}
            >
              <Typography
                component="p"
                sx={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "normal",
                  fontSize: "16px",
                  lineHeight: "22px",
                  color: "#FFFFFF",
                  textAlign: "center",
                  maxWidth: "90%",
                  margin: "0 auto",
                  paddingBottom: "8px;"
                }}
              >
                uCOACHu is available for Apple devices, install and login for an in-app video capture experience.
              </Typography>
              <img
                src={"/assets/images/apple_button.png"}
                alt="Download on Apple Store"
                height={40}
                width={120}
                style={{cursor: "pointer"}}
                onClick={() => window.open(APPLE_DOWNLOAD)}
              />
            </Card>
          }
      </Box>
    </>
    )
  }

  return (
    <>
        {isNative ? <></> : nonNativePart()}
        <Typography
          component={"p"}
          sx={{
            padding: "10px",
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "16px",
            lineHeight: "25px",
            color: "#FDFBFB",
            marginBottom: "20px",
          }}
        >
          How to Record a Swing
        </Typography>
        <ReactPlayer
          url={UPLOAD_INSTRUCTIONS_VIDEO}
          width="90%"
          controls={true}
          className="dashboard-video"
        />
    </>
  );
};

export default EmptyDashboard;
