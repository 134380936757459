import React from "react";
import { SvgIcon } from '@mui/material';

const AccountIcon = (props) => {
  return (
    <SvgIcon width="28" height="30" viewBox="0 0 28 30" fill="none" {...props}>
      <g clip-path="url(#clip0_3340_4740)">
      <path d="M24.7102 30.0002H3.16693C2.3275 29.9986 1.52292 29.6644 0.929357 29.0709C0.335794 28.4773 0.00161999 27.6727 0 26.8333V22.9513C0.0265124 22.1107 0.313125 21.2991 0.820327 20.6283C1.32753 19.9576 2.03034 19.4606 2.83184 19.2061L11.5256 16.7543C13.1115 16.3723 14.7656 16.3723 16.3516 16.7543L25.0473 19.2061C25.8486 19.4607 26.5511 19.9577 27.058 20.6285C27.5649 21.2993 27.8511 22.1109 27.8771 22.9513V26.8333C27.8761 27.6729 27.542 28.4778 26.9484 29.0715C26.3547 29.6652 25.5498 29.9992 24.7102 30.0002ZM13.9386 18.3051C13.2951 18.2952 12.6531 18.368 12.0282 18.5217L3.33242 20.9755C2.9173 21.1223 2.55445 21.3878 2.28892 21.7391C2.0234 22.0903 1.8669 22.5118 1.83886 22.9513V26.8333C1.8394 27.1854 1.9795 27.5229 2.22844 27.7718C2.47739 28.0207 2.81487 28.1608 3.16693 28.1614H24.7102C25.0623 28.1608 25.3998 28.0207 25.6487 27.7718C25.8976 27.5229 26.0377 27.1854 26.0383 26.8333V22.9513C26.0118 22.5114 25.8559 22.0891 25.5902 21.7376C25.3244 21.3861 24.9607 21.1209 24.5447 20.9755L15.851 18.5237C15.2255 18.3693 14.5828 18.2958 13.9386 18.3051Z" fill="white"/>
      <path d="M13.9388 15.663C12.39 15.6626 10.8761 15.203 9.58845 14.3422C8.30084 13.4814 7.29737 12.2581 6.70493 10.8271C6.1125 9.39602 5.9577 7.82143 6.26011 6.30241C6.56252 4.78338 7.30856 3.38813 8.4039 2.29308C9.49924 1.19802 10.8947 0.45235 12.4138 0.150335C13.9329 -0.15168 15.5074 0.00352742 16.9383 0.596336C18.3693 1.18914 19.5922 2.19293 20.4527 3.48077C21.3131 4.76861 21.7724 6.28267 21.7724 7.83151C21.7702 9.90824 20.9442 11.8993 19.4755 13.3675C18.0068 14.8358 16.0156 15.6614 13.9388 15.663ZM13.9388 1.83886C12.7537 1.83927 11.5953 2.19107 10.6101 2.8498C9.62485 3.50853 8.85708 4.44459 8.40382 5.53964C7.95057 6.63469 7.83218 7.83954 8.06364 9.00187C8.2951 10.1642 8.866 11.2318 9.70417 12.0697C10.5423 12.9076 11.6101 13.4781 12.7725 13.7092C13.9349 13.9402 15.1398 13.8214 16.2346 13.3678C17.3295 12.9142 18.2653 12.1461 18.9237 11.1606C19.5821 10.1752 19.9335 9.01665 19.9335 7.83151C19.9314 6.24247 19.299 4.71917 18.1752 3.59574C17.0514 2.47231 15.5279 1.84048 13.9388 1.83886Z" fill="white"/>
      </g>
      <defs>
      <clipPath id="clip0_3340_4740">
      <rect width="27.8771" height="30" fill="white"/>
      </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default AccountIcon