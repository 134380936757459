export const UPLOAD_INSTRUCTIONS_VIDEO = "//youtu.be/RFTbX54REE0";
// Youtube error still shows up on console, see https://github.com/CookPete/react-player/issues/508
export const INVALID_FILE_ERROR = "Please upload a valid video file";
export const LARGE_FILE_SIZE_ERROR =
  "File size too large, please use a file between 2MB and 30MB";
export const SMALL_FILE_SIZE_ERROR =
  "File size too small, please use a file between 2MB and 30MB";
export const SWING_UPLOADED = "Swing uploaded successfully";
export const UPLOADING_SWING = "Uploading your swing";
export const SUBSCRIBED_MSG = "Subscription added successfully";
export const SUBSCRIPTION_CANCELLED_MSG = "Subscription canceled successfully";
export const SUBSCRIPTION_CANCELLED_FAILED =
  "Cancellation failed. Please try again";
export const SUBSCRIPTION_FAILED_MSG = "Subscription failed. Please try again";
export const FEEDBACK_MSG = "Thank you for your feedback";
export const FEEDBACK_FAIL_MSG = "Oops, something went wrong. Please try again";
export const SUCCESSFULLY_UPDATED = "Successfully updated";
export const PASSWORD_CHANGED_MSG = "Password changed successfully";
export const SUBSCRIPTION_ACTIVE = "Please cancel your subscription before deleting";
export const DELETE_ERROR = "We require you to validate your credentials again, please logout, log back in and then delete.";
export const INSPECTLET_WID = 117546850;
export const GA_MEASUREMENT_ID = "G-691TE0RLGH";
export const GTM_ID = "GTM-MK4BD6Q";
export const CURRENCY_EXCHANGE =
  "https://www.xe.com/currencyconverter/convert/?Amount=_AMOUNT&From=NZD&To=USD";
export const CDN = "https://cdn.ucoachugolf.com/";
export const APPLE_DOWNLOAD = "https://apps.apple.com/nz/app/ucoachu/id1542132815";
export const WAIT_FILE_PROCESS = "Please wait while we process your file";

// only for native
export const ANDROID_DAY_PASS_PRODUCT = "com.ucoachugolf.pass.24hour";
export const ANDROID_MONTHLY_SUBSCRIBE = "com.ucoachugolf.sub.monthly";
export const ANDROID_ANNUALLY_SUBSCRIBE = "com.ucoachugolf.sub.annual";
export const NATIVE_VIDEO_UPLOAD_SUCCESS = "Video is uploaded successfully.";
export const NATIVE_VIDEO_UPLOAD_FAIL = "Video upload failed";
export const NATIVE_PAYMENT_SUCCESS = "Payment processed. It could take a few moments for this to be reflected.";
export const NATIVE_PAYMENT_FAIL = "There was an issue with your payment: ";

