import React from "react";

const ErrorIcon = (props) => {
  return (
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="50" height="50" fill="#030311"/>
      <circle cx="26" cy="26" r="9" stroke="#E21414" stroke-width="2"/>
      <path d="M25.3587 27.738L25.0507 22.712V20.78H26.9547V22.712L26.6467 27.738H25.3587ZM25.7927 31.07C25.5034 31.07 25.2701 30.986 25.0927 30.818C24.9154 30.6407 24.8267 30.4167 24.8267 30.146C24.8267 29.8753 24.9154 29.656 25.0927 29.488C25.2701 29.3107 25.5034 29.222 25.7927 29.222H26.2127C26.5114 29.222 26.7447 29.306 26.9127 29.474C27.0901 29.642 27.1787 29.866 27.1787 30.146C27.1787 30.4167 27.0901 30.6407 26.9127 30.818C26.7354 30.986 26.4974 31.07 26.1987 31.07H25.7927Z" fill="#E21414"/>
    </svg>
  );
}

export default ErrorIcon