import React, { useContext, useState } from "react";
import {
  Container,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import CustomButton, {
  CustomButtonOutlined,
} from "../../../components/button/CustomButton";
import { AuthContext } from "../../../contexts/AuthContext";
import SubHeader from "../../../components/subHeader";
import { inputLabelProps } from "../../../styles/auth";
import { sendFeedback } from "../../../api/db";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toastError, toastSuccess } from "../../../utils/commonFunctions";
import { FEEDBACK_FAIL_MSG, FEEDBACK_MSG } from "../../../_constants/globals";

const Feedback = () => {
  const breadCrumbArr = [
    { displayLink: "Dashboard", path: "/dashboard" },
    { displayLink: "Feedback", path: "/profile" },
  ];

  const [feedbackError, setFeedbackError] = useState(false);
  const [feedbackText, setFeedbackText] = useState("");
  const { userData } = useContext(AuthContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFeedbackError(false);

    if (feedbackText === "") {
      setFeedbackError(true);
    } else {
      const status = await sendFeedback({
        email: userData.email,
        golferId: userData.uid,
        name: userData.displayName,
        feedback: feedbackText,
        userId: userData.uid,
      });

      if (status) {
        setFeedbackText("");
        toastSuccess(FEEDBACK_MSG, dispatch);
      } else {
        toastError(FEEDBACK_FAIL_MSG, dispatch);
      }
    }
  };

  return (
    <Container maxWidth="xl">
      <SubHeader breadCrumbArr={breadCrumbArr} />
      <Grid container sx={{ alignItems: "stretch", mt: 6 }} columnSpacing={2}>
        <Grid item sm={12} md={10}>
          <Grid item sm={12}>
            <Typography
              sx={{
                fontFamily: "Inter",
                fontWeight: "400",
                fontSize: "18px",
                lineHeight: "22px",
              }}
            >
              We'd love your feedback to help us improve your uCOACHu
              experience.
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: 5 }}>
            <InputLabel sx={{ ...inputLabelProps }}>Your Comment</InputLabel>
            <TextField
              sx={{
                backgroundColor: "black",
                mt: 1,
                width: 1,
                padding: 0,
              }}
              required
              autoFocus
              variant="outlined"
              color="secondary"
              name="name"
              minRows={8}
              maxRows={8}
              onChange={(e) => setFeedbackText(e.target.value)}
              value={feedbackText}
              error={feedbackError}
              multiline={true}
              helperText={feedbackError ? "Please provide your feedback" : ""}
            />
          </Grid>
          <Grid container spacing={2} sx={{ my: 2 }}>
            <Grid item xs={12} sm={6}>
              <CustomButton
                type="submit"
                onClick={handleSubmit}
                sx={{ mr: 2, fontSize: "16px" }}
              >
                Send
              </CustomButton>
              <CustomButtonOutlined
                onClick={() => navigate(-1)}
                sx={{ fontSize: "16px" }}
              >
                Cancel
              </CustomButtonOutlined>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Feedback;
