export const redirectToPayment = async ({
  token,
  product,
  successPage,
  cancelPage,
}) => {
  const headers = new Headers();
  // const token = await auth.currentUser.getIdToken()
  headers.append("Authorization", "Bearer " + token);
  headers.append("Content-Type", "application/json");
  const url = `https://us-central1-ninelines-254623.cloudfunctions.net/get-checkout-session-url-dev?product=${product}&success-url=${successPage}?result=success&cancel-url=${cancelPage}?result=cancel`;
  const response = await fetch(url, { headers: headers, method: "POST" });
  if (response.ok) {
    const json = await response.json();
    window.location.href = json.session_url;
  }
};

export const cancelSubscription = async ({ token }) => {
  const headers = new Headers();
  // const token = await auth.currentUser.getIdToken()
  headers.append("Authorization", "Bearer " + token);
  headers.append("Content-Type", "application/json");
  const url = `https://us-central1-ninelines-254623.cloudfunctions.net/cancel-stripe-dev`;
  const response = await fetch(url, { headers: headers, method: "POST" });
  return response;
};
