import React, { createContext, useEffect, useState } from "react";
import { ANDROID_ANNUALLY_SUBSCRIBE, ANDROID_DAY_PASS_PRODUCT, ANDROID_MONTHLY_SUBSCRIBE, NATIVE_PAYMENT_FAIL, NATIVE_PAYMENT_SUCCESS } from "../_constants/globals";
import { uploadPurchaseData } from "../api/db";
import { Ucoachu, ProductType } from 'ucoachu-plugin'
import { auth } from "../config/firebase";
import { useDispatch } from "react-redux";
import { toggleUpgradeModal, toggleUpgrading } from "../store/toastSlice";

export const PurchaseContext = createContext();

const PurchaseContextProvider = (props) => {
  const [error, setError] = useState(null);
  const [dayProduct, setDayProduct] = useState(null);
  const [monthSub, setMonthSub] = useState(null);
  const [yearSub, setYearSub] = useState(null);
  const dispatch = useDispatch()

  useEffect(() => {
    Ucoachu.connectToGooglePlay().then(() => {
      Ucoachu.getSkuDetails({skus: [
        {
          productType: ProductType.CONSUMABLE,
          productId: ANDROID_DAY_PASS_PRODUCT
        }
      ]})
      .then((result) => {
        result[ANDROID_DAY_PASS_PRODUCT] && setDayProduct(result[ANDROID_DAY_PASS_PRODUCT])
      })
      Ucoachu.getSkuDetails({skus: [
        {
          productType: ProductType.SUBSCRIPTION,
          productId: ANDROID_MONTHLY_SUBSCRIBE
        }
      ]})
      .then((result) => {
        result[ANDROID_MONTHLY_SUBSCRIBE] && setMonthSub(result[ANDROID_MONTHLY_SUBSCRIBE])
      })
      Ucoachu.getSkuDetails({skus: [
        {
          productType: ProductType.SUBSCRIPTION,
          productId: ANDROID_ANNUALLY_SUBSCRIBE
        }
      ]})
      .then((result) => {
        result[ANDROID_ANNUALLY_SUBSCRIBE] && setYearSub(result[ANDROID_ANNUALLY_SUBSCRIBE])
      })
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const managePurchaseResult = (result, userId) => {
    if (result["succeeded"] === "true") {
      const orderId = result["orderId"]
      const subscriptionId = result["purchaseSku"]
      const purchaseToken = result["purchaseToken"]
      //Write to golfers/{userId}/android_receipts to validate purchase
      
      uploadPurchaseData({
        orderId,
        subscriptionId,
        purchaseToken
      }, userId)
      
      //Acknowledge purchase. This stops Google Play from sending the same purchase over and over again. 
      Ucoachu.acknowledgePurchase({ "purchaseToken":purchaseToken })
      dispatch(toggleUpgrading())
      dispatch(toggleUpgradeModal(false))
      alert(NATIVE_PAYMENT_SUCCESS)
    } else {
      const responseCode = result["responseCode"]
      dispatch(toggleUpgrading())
      alert(NATIVE_PAYMENT_FAIL + responseCode)
    }
  }

  const failedPurchase = () => {
    dispatch(toggleUpgrading())
  }

  const purchaseDayProduct = (userId = auth?.currentUser?.uid) => {
    if (dayProduct) {
      Ucoachu.purchaseProduct({sku: ANDROID_DAY_PASS_PRODUCT, uid: userId, productType: ProductType.CONSUMABLE})
        .then((result) => managePurchaseResult(result, userId))
        .catch(() => failedPurchase())
    }
  }

  const purchaseMonthSub = (userId = auth?.currentUser?.uid) => {
    if (monthSub) {
      Ucoachu.purchaseProduct({sku: ANDROID_MONTHLY_SUBSCRIBE, uid: userId, productType: ProductType.SUBSCRIPTION})
        .then((result) => managePurchaseResult(result, userId))
        .catch(() => failedPurchase())
    }
  }

  const purchaseYearSub = (userId = auth?.currentUser?.uid) => {
    if (yearSub) {
      Ucoachu.purchaseProduct({sku: ANDROID_ANNUALLY_SUBSCRIBE, uid: userId, productType: ProductType.SUBSCRIPTION})
        .then((result) => managePurchaseResult(result, userId))
        .catch(() => failedPurchase())
    }
  }

  return (
    <PurchaseContext.Provider
      value={{
        error,
        dayProduct,
        monthSub,
        yearSub,
        purchaseDayProduct,
        purchaseMonthSub,
        purchaseYearSub
      }}
    >
      {props.children}
    </PurchaseContext.Provider>
  );
};

export default PurchaseContextProvider;
