import React, { useState } from "react";
import { Box, Divider, Grid, Typography } from "@mui/material";
import moment from "moment";
import VideoModal from "../../../components/modal/FullScreenModal";
import { resourceURL } from "../../../utils/commonFunctions";

const SwingRow = (props) => {
  const {
    thumbnail_path,
    video_path,
    createdAt,
    processed,
    video_name,
    warnings = [],
    score = 0,
    frame_height = 0,
    frame_width = 0,
    title,
  } = props.videoData;
  const isAnalyzing = processed === 0 ? true : false;
  const warningMsg = warnings.length > 0 ? warnings[0] : "";
  const [videoUrl, setVideoUrl] = useState("");
  let createdDate = new Date(0); // Epoch
  createdDate.setUTCSeconds(createdAt.seconds); // set seconds and convert it back to local date

  return (
    <>
      <Grid
        item
        container
        sx={{
          color: !isAnalyzing ? "secondary.main" : "primary.main",
          display: "flex",
          my: 1,
        }}
        spacing={1}
      >
        <Grid
          item
          sx={{
            backgroundColor: "#EEECEC",
            width: { xs: "100%", md: "255px" },
            maxHeight: "154px",
            padding: "0px !important",
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
            overflow: "hidden",
            marginLeft: 1,
            cursor: "pointer",
          }}
          onClick={() => setVideoUrl(video_path)}
        >
          <img
            src={resourceURL(thumbnail_path)}
            style={{
              width: "100%",
            }}
            alt={video_name}
          />
          <Box
            sx={{
              height: 1,
              width: 1,
              position: "absolute",
              top: 0,
              backgroundColor: "#00000077",
            }}
          >
            <img
              src={"/assets/images/play.svg"}
              alt="swing breakdown row play"
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-25px",
                marginLeft: "-25px",
                width: "50px",
              }}
            />
          </Box>
        </Grid>
        <Grid
          container
          alignItems={"center"}
          justifyContent={"center"}
          columnSpacing={4}
          item
          sx={{ flex: 1, pb: 2 }}
        >
          <Grid item container xs={3} md={2.5} sx={{ alignItems: "center" }}>
            {!isAnalyzing ? (
              <Typography
                variant="h2"
                component="h2"
                sx={{ fontFamily: "JetBrains Mono", fontSize: "65px" }}
              >
                {Math.floor(score * 100)}
              </Typography>
            ) : (
              <Box sx={{ textAlign: "center" }}>
                <img
                  src={"/assets/images/spinner.svg"}
                  alt="React Logo"
                  height={90}
                  width={90}
                />
                <Typography
                  variant="p"
                  component="p"
                  sx={{
                    fontSize: "14px",
                    fontFamily: "Inter",
                    fontWeight: "500",
                  }}
                >
                  Analyzing Swing
                </Typography>
              </Box>
            )}
          </Grid>
          <Divider
            variant={"middle"}
            orientation={"vertical"}
            color={"#CDCDCF"}
            sx={{ height: 36, display: { sm: "block", xs: "none" } }}
          />
          <Grid item container sm={8} md={9} columnSpacing={2}>
            <Grid
              item
              container
              sm={12}
              md={6}
              sx={{ alignItems: "center", pt: 1 }}
            >
              <Grid item xs={12}>
                <Typography
                  variant="p"
                  component="p"
                  sx={{
                    fontSize: "12px",
                    fontFamily: "JetBrains Mono",
                    color: "text.gray",
                  }}
                >
                  Date & Time
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="p"
                  component="p"
                  sx={{
                    fontSize: "18px",
                  }}
                >
                  {moment(createdDate).format("ddd DD MMM HH:mm a")}
                </Typography>
              </Grid>
            </Grid>
            <Divider
              variant={"middle"}
              orientation={"vertical"}
              color={"#CDCDCF"}
              sx={{ height: 36, display: { md: "block", xs: "none" } }}
            />
            <Grid item sm={12} md={5.7} sx={{ pt: 1 }}>
              <Typography
                variant="p"
                component="p"
                sx={{
                  fontSize: "12px",
                  fontFamily: "JetBrains Mono",
                  color: "text.gray",
                }}
              >
                Description
              </Typography>
              <Typography
                variant="p"
                component="p"
                sx={{
                  fontSize: "18px",
                  overflowWrap: "break-word",
                }}
              >
                {title}
              </Typography>
            </Grid>
          </Grid>

          {!!warningMsg && (
            <Grid item xs={12}>
              <Typography
                variant="p"
                component="p"
                sx={{
                  fontSize: "14px",
                  color: "yellow.secondary",
                  mb: "-20px",
                  margin: { xs: "0px", sm: "0px 15px" },
                }}
              >
                {warningMsg}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
      {
        //elements for modal
        !!videoUrl && (
          <VideoModal
            isOpen={!!videoUrl}
            handleClose={() => setVideoUrl("")}
            videoUrl={resourceURL(videoUrl)}
            frame_width={frame_width}
            frame_height={frame_height}
          />
        )
      }
    </>
  );
};

export default SwingRow;
