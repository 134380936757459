import React from "react";
import { SvgIcon } from '@mui/material';

const SwingIcon = (props) => {
  return (
    <svg width="31" height="32" viewBox="0 0 31 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.89283 16.0416C9.55093 12.2148 10.0505 9.45818 8.17072 8.05363C7.0166 7.1919 5.37962 7.1423 4.23946 7.62844C1.38137 8.86007 0.890137 13.8419 1.10784 16.6623C1.10784 16.6765 1.10784 16.6864 1.10784 16.6949C1.16925 17.449 1.70514 22.8857 5.12005 24.595C7.13802 25.6041 10.0952 25.1889 12.035 23.6752C17.7944 19.1781 11.2786 7.00907 17.0339 2.45243C18.8718 0.998274 21.4996 0.668042 23.4729 1.31859C26.6911 2.37164 28.0336 5.98719 28.6923 7.75883C31.5392 15.4208 30.0334 27.3333 23.191 30.2742C21.0977 31.1756 18.1671 31.3726 15.87 30.0814C10.8934 27.2836 10.084 18.1945 9.89283 16.0416Z" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
    </svg>
  );
}

export default SwingIcon