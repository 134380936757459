import {
  collection,
  query,
  where,
  limit,
  getDoc,
  getDocs,
  doc,
  startAfter,
  orderBy,
  onSnapshot,
  runTransaction,
  addDoc,
  Timestamp,
  setDoc,
  updateDoc,
  deleteDoc
} from "@firebase/firestore";
import { db } from "../config/firebase";
import * as _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import * as Sentry from "@sentry/react";
import { generateVideoFileName } from "../utils/commonFunctions";
import { Ucoachu } from 'ucoachu-plugin'
import { NATIVE_VIDEO_UPLOAD_FAIL, NATIVE_VIDEO_UPLOAD_SUCCESS } from "../_constants/globals";

export const getSwingHistoryQuery = async ({
  userId,
  setSwings,
  toggleLoading,
  lastDoc = null,
  setLastDoc, //if passed meaning need to set last doc
  existingSwings = [],
  viewAs = false,
  loggedinUserId,
  forMobile = false,
}) => {
  const golfer = await fetchGolfer(userId, loggedinUserId, viewAs);
  if (!golfer)
    return () => {};

  const SWING_SAMPLE = {
    id: '',
    access: '',
    createdAt: '', // Date format
    lastUpdated: '', // Date format
    title: '',
    video_name: '',
    shot_type: '',
    hit_type: '',
    contact: '',
    video_path: '',
    upload_date: '', // ISO format date,
    score: 0.0,
    priority_fix: '',
    primary_fixes: [],
    processed_changed: '', // ISO format date
    processed: -1, // Value can be -1, 0 or 1
                  // -1 - an error occured
                  // 0 - swing is being processed
                  // 1 swing has been processed
    progress: 0.0,
    penalty: 0.0,
    filename: '', // same with id value
    annotated_video_path: null,
    errors: [], // string array,
    warnings: [], // string array,
    lines: [], // Int array,
    line_urls: [], // String array,
    frame_height: 0,
    frame_width: 0,
    metrics: null, // Swing metrics are not available when initially creating a swing.
    is_deleted: false,
    golf_Club: 'iron', // Now app supports only `iron` club
    recording_mode: 'down_plan', // or 'face-on',
    camera: '',
    orientation: 1, // case portrait = 1  case portraitUpsideDown = 2  case landscapeRight = 3  case landscapeLeft = 4
    workOnId: ''
  }

  let queryRef = "";
  if (!forMobile) {
    if (!!lastDoc && lastDoc.docs && lastDoc.docs.length > 1) {
      queryRef = query(
        collection(golfer.ref, "video_summaries"),
        where("has_path", "==", true),
        orderBy("createdAt", "desc"),
        startAfter(lastDoc.docs[lastDoc.docs.length - 1]),
        limit(20)
      );
    } else {
      queryRef = query(
        collection(golfer.ref, "video_summaries"),
        where("has_path", "==", true),
        orderBy("createdAt", "desc"),
        limit(20)
      );
    }
  } else {
    if (!!lastDoc && lastDoc.docs && lastDoc.docs.length > 1) {
      queryRef = query(
        collection(golfer.ref, "video_summaries"),
        where("is_deleted", "==", false),
        orderBy("createdAt", "desc"),
        startAfter(lastDoc.docs[lastDoc.docs.length - 1]),
        limit(20)
      );
    } else {
      queryRef = query(
        collection(golfer.ref, "video_summaries"),
        where("is_deleted", "==", false),
        orderBy("createdAt", "desc"),
        limit(20)
      );
    }
  }
  const snapshot = onSnapshot(queryRef, async (querySnapshot) => {
    let videos = [];
    querySnapshot.docs.forEach((video) => {
      videos.push({ ...video.data(), id: video.id });
    });
    // sort order by created date
    videos = _.orderBy(
      videos,
      [
        function (item) {
          return item.createdAt;
        },
      ],
      ["desc"]
    );
    if (!!setLastDoc) {
      setLastDoc(querySnapshot);
      if (existingSwings.length === 0) {
        setSwings(videos);
      } else {
        setSwings([...existingSwings, ...videos]);
      }
    } else {
      setSwings(videos);
    }
    toggleLoading(false);
  });
  return snapshot;
};

export const getSwingDetail = async (userId, docId, viewAs, loggedinUserId) => {
  const golfer = await fetchGolfer(userId, loggedinUserId, viewAs);

  if (!golfer)
    return;

  try {
    const q = doc(golfer.ref, "videos", docId);
    const querySnapshot = await getDoc(q);
    return querySnapshot.data();
  } catch (e) {
    Sentry.captureMessage("failed getDoc for golfer videos in swing.js", "debug");
    Sentry.captureMessage(e.message+";"+e.code, "debug");
    return;
  }
};

export const addSwing = async (golfer, file, video_path) => {
  const docId = uuidv4().replace(/-/g, "");

  await runTransaction(db, async (transaction) => {
    transaction.set(doc(db, `golfers/${golfer.id}/videos`, docId), {
      video_name: file.name, //retain the original filename, so that we can use it if the user wants to download their video later on
      title: file.name,
      video_path: video_path,
      is_deleted: false,
      createdAt: new Date(),
      lastUpdated: new Date(),
      processed: 0,
      progress: 0,
    });

    //create a matching doc in video_summaries; the server will do this automatically, but setting it here will speed up the process so that the Analysing card gets displayed sooner
    //merge not needed because in a transaction, so being created for first time here
    transaction.set(doc(db, `golfers/${golfer.id}/video_summaries`, docId), {
      title: file.name,
      video_path: video_path,
      has_path: true,
      is_deleted: false,
      createdAt: new Date(),
      processed: 0,
      progress: 0,
    });
  });
};

const fetchGolfer = async (userId, loggedinUserId, viewAs) => {
  let golfer;
  // added as a fix for sentry issue 3323773185
  if (!userId)
    return golfer;
  // if viewing as someone else, the query path is different, so we use this flag
  // to fetch the golfer reference correctly
  if (!viewAs) {
    try{
      const golferDocs = await getDocs(
        query(collection(db, "golfers"), where("userId", "==", userId), limit(1))
      );
      golfer = golferDocs?.docs[0];
    } catch (e) {
      Sentry.captureMessage("failed query for golfers based on userId in swing.js", "debug");
      Sentry.captureMessage(e.message+";"+e.code, "debug");
      return golfer;
    }
  } else {
    try{
      golfer = await getDoc(doc(db, "golfers", userId));
    } catch(e) {
      // the golferId is differen than userId in some cases, the code below will handle it
      Sentry.captureMessage("failed getDoc for golfers based on userId in swing.js", "debug");
      Sentry.captureMessage(e.message+";"+e.code, "debug");
    }

    if (!golfer && loggedinUserId) {
      const golferDocs = await getDocs(
        query(collection(db, "golfers"), 
          where('coachIds','array-contains', loggedinUserId),
          where("userId", "==", userId),
          limit(1)
        )
      );
      golfer = golferDocs.docs[0];
    }
  }
  return golfer;
};

export const createVideoData = async (userId, videoTitle, recordMode, videoPath) => {
  const fileName = generateVideoFileName() + ".mp4";
  const createdAt = Timestamp.fromDate(new Date());
  const golferDocs = await getDocs(
    query(collection(db, "golfers"), where("userId", "==", userId), limit(1))
  );
  const golfer = golferDocs.docs[0];

  const fireStoreData = {
    camera: "back",
    createdAt: createdAt,
    golf_Club: "iron",
    golferid: userId,
    has_path: false,
    is_deleted: false,
    orientation: 1,
    primary_fixes: [],
    processed: 0,
    recording_mode: recordMode,
    score: -1,
    title: videoTitle,
    video_name: videoPath
  } 
  const videosDoc = await addDoc(collection(golfer.ref, "videos"), fireStoreData)
  await setDoc(doc(collection(golfer.ref, "video_summaries"), videosDoc.id), fireStoreData)
  return {
    fileName,
    documentId: videosDoc.id
  }
}

export const updateDocumentWithStorageVideo = async (userId, documentId, videoPath = '', videoName = '') => {
  const golferDocs = await getDocs(
    query(collection(db, "golfers"), where("userId", "==", userId), limit(1))
  );
  const golfer = golferDocs.docs[0];
  let pathValue = videoPath
  if (videoPath.startsWith('/')) {
    pathValue = videoPath.substring(1)
  }
  await updateDoc(doc(collection(golfer.ref, "videos"), documentId), {video_path: pathValue, video_name: videoName, has_path: true})
  await updateDoc(doc(collection(golfer.ref, "video_summaries"), documentId), {video_path: pathValue, video_name: videoName, has_path: true})
}

export const uploadVideoWithSwingData = async (videoPath, documentId, userId) => {
  return new Promise((resolve, reject) => {
    const fileName = generateVideoFileName() + ".mp4";
    Ucoachu.uploadVideo({ videoPath, fileName, userId}).then(async (result) => {
      if (result && result.videoPath) {
        await updateDocumentWithStorageVideo(userId, documentId, result.videoPath, fileName)
        alert(NATIVE_VIDEO_UPLOAD_SUCCESS)
        resolve(true)
      }
      reject()
    }).catch(() => {
      alert(NATIVE_VIDEO_UPLOAD_FAIL)
      reject()
    })
  })
}

export const deleteDocument = async (userId, documentId) => {
  const golferDocs = await getDocs(
    query(collection(db, "golfers"), where("userId", "==", userId), limit(1))
  );
  const golfer = golferDocs.docs[0];
  await updateDoc(doc(collection(golfer.ref, "videos"), documentId), {is_deleted: true})
  await updateDoc(doc(collection(golfer.ref, "video_summaries"), documentId), {is_deleted: true})
  console.log("Documents Deleted");
}
