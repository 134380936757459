import React from "react";
import ButtonUnstyled from "@mui/base/ButtonUnstyled";
import { customButtonStyle, customButtonOutlineStyle } from "../../styles/auth";
import CircularProgress from "@mui/material/CircularProgress";
import { Button } from "@mui/material";
import "./button.css";

const CustomButtonRoot = customButtonStyle;

const CustomButton = (props) => {
  const { disabled = false } = props;
  return (
    <ButtonUnstyled {...props} component={CustomButtonRoot} disabled={disabled}>
      {disabled && (
        <CircularProgress
          size={props.loadingSize || 18}
          sx={{ mr: "10px" }}
          color={"secondary"}
          thickness={5}
        />
      )}
      {props.children}
    </ButtonUnstyled>
  );
};
export const CustomButtonOutlined = (props) => {
  const { disabled = false } = props;
  return (
    <ButtonUnstyled
      {...props}
      component={customButtonOutlineStyle}
      disabled={disabled}
    >
      {disabled && (
        <CircularProgress
          size={props.loadingSize || 18}
          sx={{
            mr: "10px",
            color: "#cccccc",
            position: "absolute",
            right: "50%",
          }}
          thickness={5}
        />
      )}
      {props.children}
    </ButtonUnstyled>
  );
};

export const CustomButtonBlue = (props) => {
  const { disabled = false } = props;
  return (
    <Button
      {...props}
      component={CustomButtonRoot}
      disabled={disabled}
      className="customButton"
    >
      {disabled && (
        <CircularProgress
          size={props.loadingSize || 18}
          sx={{ mr: "10px" }}
          color={"secondary"}
          thickness={5}
        />
      )}
      {props.children}
    </Button>
  );
};
export default CustomButton;
