/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Link,
  Typography,
  AppBar,
  MenuItem,
  Avatar,
  Menu,
  Container,
  Grid,
  Snackbar,
  Alert,
} from "@mui/material";
import { logout } from "../../api/auth";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { isEqual } from "lodash";
import { updateGolfer } from "../../store/golferSlice";
import { useSelector, useDispatch } from "react-redux";
import {
  clearToast,
  isNative,
  toastError,
  toastSuccess,
  useQuery,
} from "../../utils/commonFunctions";
import {
  getGolferData,
  getRelatedGolferData,
  getGolferFromUserIdViewAs,
} from "../../api/db";
import {
  SUBSCRIBED_MSG,
  SUBSCRIPTION_FAILED_MSG,
} from "../../_constants/globals";
import insp from "inspectlet-es";
import ReactGA from "react-ga4";
import TagManager from 'react-gtm-module';
import "./header.css";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import * as Sentry from "@sentry/react";
import { getIpLocation } from "../../config/geo";

const Header = (props) => {
  const navigate = useNavigate();
  const { updateUserData, userData } = useContext(AuthContext);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [userFullName, setUserFullName] = useState(userData?.displayName || "Guest");
  const uid = userData?.uid || "Guest";
  let golferDataOnSnapShot = function () {};
  const golferData = useSelector((state) => state.golfer.golferObj);
  const showToast = useSelector((state) => state.toast.showToast);
  const dispatch = useDispatch();
  const queryObj = useQuery();
  const [relatedGolfers, setRelatedGolfers] = useState("");
  const [viewAs, setViewAs] = useState(false);
  const [originalGolfer, setOriginalGolfer] = useState(golferData);
  const [location, setLocation] = useState({});

  // send identification to inspectlet
  insp(["identify", uid]);

  // send user_id to GA
  ReactGA.gtag({ user_id: uid });

  //send data layer to google tag
  TagManager.dataLayer({
    dataLayer: {
      user_id: userData?.uid
    }
  });

  // set user in setry
  Sentry.setUser({ id: uid });

  useEffect(() => {
    golferDataOnSnapShot = getGolferDataFun();
    return () => golferDataOnSnapShot();
  }, []);

  useEffect(() => {
    const result = queryObj.get("result");

    //toast message if result was set
    if (!!result && result === "success") {
      toastSuccess(SUBSCRIBED_MSG, dispatch);
      // send GA purchase event
      TagManager.dataLayer({
        dataLayer: {
          plan: golferData?.subscriptionPlan,
          event: "purchase"
        }
      });
    } else if (!!result && result === "cancel") {
      toastError(SUBSCRIPTION_FAILED_MSG, dispatch);
    }
    //replace result queryObj params if set
    if (!!result) {
      queryObj.delete("result");
      navigate(
        {
          search: queryObj.toString(),
        },
        { replace: true }
      );
    }
  }, [golferData]);

  // get geolocation from golfer data or API conditionally
  useEffect(() => {
    if (!location?.countryCode && golferData?.userId
        && ( golferData?.subscriptionStatus === "inactive"
        || golferData?.subscriptionStatus === "limited" )) {
        getGeo();
    }
  }, [golferData, location]);

  // fetch geolocation and set to store
  const getGeo = async () => {
    let loc={}
    // use web registration info if available, else fetch
    if (golferData?.registrationChannelInfo?.countryCode)
      loc = {countryCode: golferData.registrationChannelInfo.countryCode}
    else
      loc = await getIpLocation();
    // default to NZ if we can't get a location
    if (!loc || loc === {} || loc?.error)
      loc = {countryCode: "NZ"}
    setLocation(loc);
    // add it to the store for Upload video to fetch
    const golferLocation = {...golferData, location: loc};
    dispatch(updateGolfer(golferLocation));
  };

  const getGolferDataFun = () => {
    return getGolferData(userData?.uid, golferData, updateGolferData);
  };

  const updateGolferData = (golfer, golferData) => {
    // if golfer does not exist (this can happen when someone abandons after user create), we logout and display a message
    if (!golfer && !isNative) {
      Sentry.captureMessage("golfer record not found", "debug");
      logout().then(() => {
        updateUserData({});
        setTimeout(() => {
          navigate("/login?error=incorrect_signup");
        }, 500);
      });
    }

    if (golfer) {
      const updatedGolferData = golfer?.data();
      if (updatedGolferData && !isEqual(updatedGolferData, golferData)) {
        dispatch(updateGolfer(updatedGolferData));
        // as an addition, pull related golfers if this golfer is of type coach
        if (updatedGolferData.isCoach && !relatedGolfers) {
          const fetchData = async () => {
            const g = await getRelatedGolferData(uid);
            setRelatedGolfers(g);
          };
          fetchData();
        }
      }
    }
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    logout().then(() => {
      updateUserData({});
      isNative ? navigate("/signup") : navigate("/login");
    });
  };

  const handleViewAs = async (index) => {
    // preserve the original golfer
    if (golferData.userId === userData.uid)
      setOriginalGolfer(golferData);

    // refresh golfer data
    let relatedGolferHolder = relatedGolfers;
    let viewAsGolfer = await getGolferFromUserIdViewAs(relatedGolferHolder[index].userId, userData.uid);
    viewAsGolfer = viewAsGolfer?.data();
    // if the latest data is successfully fetched, update the state data with it
    if(viewAsGolfer) {
      relatedGolferHolder[index] = viewAsGolfer;
      setRelatedGolfers(relatedGolferHolder);
    }

    const switchedGolfer = {...relatedGolfers[index], viewAs: true};
    dispatch(updateGolfer(switchedGolfer));
    // set the display name
    setUserFullName(switchedGolfer.name);
    handleCloseUserMenu();
    // set the state when a golfer is being viewed as based on userId mismatch
    if (switchedGolfer.userId !== userData.uid) {
      setViewAs(true);
      navigate("/dashboard");
    }
  };

  const handleViewAsYou = () => {
    dispatch(updateGolfer({...originalGolfer, viewAs: false}));
    // set the display name
    setUserFullName(originalGolfer.name);
    // set the state when a golfer is being viewed as based on userId mismatch
    setViewAs(false);
    navigate("/dashboard");
  }

  // return related golfers for the user
  const renderGolfersData = () => {
    return (
      <MenuItem>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              sx={{
                fontSize: "14px",
                fontFamily: "Inter",
                textDecoration: "none",
                color: "secondary.main",
              }}
            >
              Related Golfers
            </Typography>
          </AccordionSummary>
          {relatedGolfers.map((item, index) => {
              return (
                <AccordionDetails key={index}>
                  <Link
                    id={item.userId}
                    component="button"
                    variant="body2"
                    color="secondary"
                    underline="none"
                    onClick={() => handleViewAs(index)}
                    sx={{
                      fontSize: "14px",
                      fontFamily: "Inter",
                      textDecoration: "none",
                      color: "secondary.main",
                    }}
                  >
                    View As "{item.name}"
                  </Link>
                </AccordionDetails>
                );
            })}
        </Accordion>
      </MenuItem>
    );
  };

  return (
    <AppBar
      position="static"
      style={{
        background: "none",
        boxShadow: "none",
        paddingTop: 50,
      }}
    >
      { viewAs && <div className="float-banner">
          <Link
            component="button"
            variant="body2"
            underline="none"
            onClick={handleViewAsYou}
            sx={{
              fontSize: "14px",
              fontFamily: "Inter",
              fontStyle: "italic",
              textDecoration: "none",
              color: "#020202",
            }}
          >Viewing as {userFullName}, click to view as yourself.</Link>
        </div>
      }
      <Container maxWidth="xl">
        <Grid container>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ textAlign: { xs: "center", sm: "start" } }}
          >
            <Link underline="none" component={RouterLink} to="/">
              <img
                alt="Logo"
                src="/assets/images/ucoachu_logo_small.png"
                style={{
                  width: "100%",
                  maxWidth: "300px",
                  verticalAlign: "middle",
                }}
              />
            </Link>
          </Grid>
          {!!props.authenticated ? (
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                alignItems: "center",
                justifyContent: { xs: "center", sm: "flex-end" },
                display: "flex",
              }}
            >
              <Button
                variant="text"
                endIcon={<KeyboardArrowDownIcon color={"secondary"} />}
                onClick={handleOpenUserMenu}
                sx={{ p: 0 }}
                style={{ textAlign: "center" }}
              >
                <Avatar alt="User">{userFullName.charAt(0)}</Avatar>
                <Typography variant="p" color={"secondary"} sx={{ ml: 1 }}>
                  {userFullName}
                </Typography>
              </Button>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {!viewAs && <MenuItem onClick={handleCloseUserMenu}>
                  <Link
                    component={RouterLink}
                    to="/profile"
                    sx={{
                      fontSize: "14px",
                      fontFamily: "Inter",
                      textDecoration: "none",
                      color: "secondary.main",
                      width: "100%",
                      textAlign: "right",
                    }}
                  >
                    Account
                  </Link>
                </MenuItem>
                }
                {!viewAs && <MenuItem onClick={handleCloseUserMenu}>
                  <Link
                    component={RouterLink}
                    to="/feedback"
                    sx={{
                      fontSize: "14px",
                      fontFamily: "Inter",
                      textDecoration: "none",
                      color: "secondary.main",
                      width: "100%",
                      textAlign: "right",
                    }}
                  >
                    Support
                  </Link>
                </MenuItem>
                }
                <MenuItem onClick={handleCloseUserMenu}>
                  <Link
                    component="button"
                    variant="body2"
                    color="secondary"
                    underline="none"
                    onClick={handleLogout}
                    sx={{
                      fontSize: "14px",
                      fontFamily: "Inter",
                      textDecoration: "none",
                      color: "secondary.main",
                      width: "100%",
                      textAlign: "right",
                    }}
                  >
                    Logout
                  </Link>
                </MenuItem>
                {viewAs && <MenuItem onClick={handleCloseUserMenu}>
                  <Link
                    component="button"
                    variant="body2"
                    underline="none"
                    onClick={handleViewAsYou}
                    sx={{
                      fontSize: "14px",
                      fontFamily: "Inter",
                      textDecoration: "none",
                      color: "secondary.main",
                      width: "100%",
                      textAlign: "right",
                    }}
                  >View As Yourself
                  </Link>
                </MenuItem>
                }
                { relatedGolfers?.length !== 0 && renderGolfersData() }
              </Menu>
            </Grid>
          ) : null}
        </Grid>
      </Container>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={!!showToast.msg}
        onClose={() => clearToast(dispatch)}
        autoHideDuration={3000}
      >
        {!!showToast.type ? (
          <Alert
            onClose={() => clearToast(dispatch)}
            severity={showToast.type}
            sx={{ width: "100%" }}
            variant={"outlined"}
          >
            {showToast.msg}
          </Alert>
        ) : null}
      </Snackbar>
    </AppBar>
  );
};

export default Header;
