export interface RecordResult {
  clickedButton: string,
  videoFile: string,
  videoTitle: string,
  recordMode: string
}

export interface UploadResult {
  videoPath?: string,
  error?: string
}

export interface PurchaseResult {
  succeeded: string, // "true" or "false"
  orderId?: string,
  purchaseToken?: string,
  purchaseSku?: string,
  responseCode?: number
}

export enum ProductType {
  SUBSCRIPTION,
  CONSUMABLE
}

export interface SkuProduct {
  productId: string,
  productType: ProductType // subscrition, consumable
}

export interface SkuDetailParam {
  skus: Array<SkuProduct> // product id array
}

export interface PurchaseParam {
  sku: string,
  productType: ProductType,
  uid: string, // can be empty string
}

export interface acknowledgeParam {
  purchaseToken: string
}

export interface PriceDetail {
  price: string,
  currency: string
}

export type SkuDetailResult = Record<string, PriceDetail>

export interface UcoachuPlugin {
  recordVideo(options: { mode: string }): Promise<RecordResult>;
  uploadVideo(options: { videoPath: string, fileName: string, userId: string }): Promise<UploadResult>;
  deleteVideo(options: { videoPath: string}): Promise<{deleted: boolean}>;
  connectToGooglePlay(): Promise<any>;
  acknowledgePurchase(options: acknowledgeParam): Promise<any>;
  purchaseProduct(options: PurchaseParam): Promise<PurchaseResult>;
  getSkuDetails(options: SkuDetailParam): Promise<SkuDetailResult>
}
