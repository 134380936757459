import { Box, Grid, Typography, Tooltip } from "@mui/material";
import React from "react";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useNavigate } from "react-router-dom";

const SwingLineRow = (props) => {
  let navigate = useNavigate();
  const { swingBreakdownData, swingId, rowId } = props;

  const { isLocked = false } = props; //false by default now
  const failCount = Math.round(swingBreakdownData.fail * 100);
  const warningCount = Math.round(swingBreakdownData.warning * 100);
  const passCount = 100 - failCount - warningCount;

  const underLineRow = () => {
    return (
      <Box sx={{ width: "95%", display: "inline-block",  marginLeft: "5%"}}>
        <Box
          sx={{
            width: `${passCount}%`,
            backgroundColor: "green.primary",
            display: "inline-block",
            height: "2px",
          }}
        ></Box>
        <Box
          sx={{
            width: `${warningCount}%`,
            backgroundColor: "yellow.primary",
            display: "inline-block",
            height: "2px",
          }}
        ></Box>
        <Box
          sx={{
            width: `${failCount}%`,
            backgroundColor: "red.primary",
            display: "inline-block",
            height: "2px",
          }}
        ></Box>
      </Box>
    );
  };

  return (
    <>
      <Grid
        container
        sx={{
          padding: 2,
          marginBottom: "-15px",
          cursor: "pointer",
          ":hover": {
            bgcolor: "primary.main",
          },
          width: "95%",
          marginLeft: "5%",
        }}
        onClick={() =>
          isLocked
            ? props.onLockedClick()
            : navigate(`/swing-breakdown/${swingId}/line/${rowId}`, {
                state: {
                  videoData: props.videoData,
                },
              })
        }
      >
        <Grid item xs>
          <Typography
            sx={{
              fontSize: "22px",
              fontFamily: "Inter",
              color: "secondary.main",
            }}
          >
            {swingBreakdownData.label}
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              fontFamily: "Inter",
              color: "secondary.main",
            }}
          >
            {swingBreakdownData.subtitle}
          </Typography>
        </Grid>
        <Grid
          item
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width:"30px",
          }}
        >
          {isLocked ? (
            <Box sx={{ display: "block", textAlign: "center" }}>
              <Tooltip title="Unlock Report">
                <LockOutlinedIcon color={"secondary"} sx={{ fontSize: 30 }} />
              </Tooltip>
            </Box>
          ) : (
            <img
              src={"/assets/images/rightArrow.svg"}
              alt="line detail"
              style={{
                width: "15px",
              }}
            />
          )}
        </Grid>
      </Grid>
      {underLineRow()}
    </>
  );
};

export default SwingLineRow;
