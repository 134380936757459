import { styled } from "@mui/system";
import { buttonUnstyledClasses } from "@mui/base/ButtonUnstyled";

export const customButtonStyle = styled("button")`
  background-color: #686870;
  padding: 12px 15px;
  color: #fff;
  font-weight: 400;
  border: 2px solid #686870;
  border-radius: 2px;
  font-family: JetBrains Mono;
  font-size: 20px;
  transition: all 200ms ease;
  cursor: pointer;
  min-width: 100px;
  &:hover {
    background-color: #202020;
  }

  &.${buttonUnstyledClasses.active} {
    background-color: #202020;
  }

  &.${buttonUnstyledClasses.focusVisible} {
    box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1),
      0 0 0 5px rgba(0, 127, 255, 0.5);
    outline: none;
  }
`;

export const customButtonOutlineStyle = styled("button")`
  padding: 12px 15px;
  color: white;
  border: 2px solid #bfc0c1;
  border-radius: 2px;
  font-weight: 400;
  font-family: JetBrains Mono;
  font-size: 20px;
  transition: all 200ms ease;
  cursor: pointer;
  background-color: transparent;
  min-width: 100px;
  &:hover {
    background-color: #616161;
    color: #fff;
  }

  &.${buttonUnstyledClasses.active} {
    background-color: #616161;
    color: #fff;
  }

  &.${buttonUnstyledClasses.focusVisible} {
    box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1),
      0 0 0 5px rgba(0, 127, 255, 0.5);
    outline: none;
  }
`;

export const cardStyleProps = {
  padding: 20,
  margin: "20px auto",
  backgroundColor: "#202020",
  border: "none",
  boxShadow: "none",
};

export const inputLabelProps = {
  color: "white",
  fontFamily: "Inter",
  textAlign: "start",
};

export const textFieldProps = {
  backgroundColor: "black",
};
