import React, { useState } from "react";

import {
    Grid,
    InputLabel,
    Button,
    Box,
    styled,
    useTheme,
    useMediaQuery,
    InputBase,
    FormControl,
    FormControlLabel,
    Checkbox,
    Alert,
    AlertTitle
  } from "@mui/material";
import { Brightness1Rounded, CheckCircle} from '@mui/icons-material'
import ForgotPassword from "./ForgotPassword";

const PageWrapper = styled(Box)(() => ({
    backgroundColor: '#030311',
    height: '100vh',
    overflowY: 'auto',
    '&::-webkit-scrollbar':{
        width: 0,
        display: 'none'
    }
}))

const SideBox = styled(Box)(() => ({
    position: 'relative',
}))

const ImageWrapper = styled(Box)((props) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: props.isNative ? 'flex-end' : 'flex-start',
}))

const LogoWrapper = styled(Box)((props) => ({
    position: 'absolute',
    left: 82,
    top: 19.63,
    width: 97,
    height: 67,
    [props.theme.breakpoints.down('sm')]: {
        left: 24,
        top: 20,
        width: 71,
        height: 50,
    },
    zIndex: 110
}))

const HeaderText = styled(Box)(() => ({
    fontFamily: 'Inter',
    fontSize: 42,
    fontWeight: 800,
    color: 'white'
}))

const MainBox = styled(Box)((props) => ({
    paddingTop: 120,
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 100,
    [props.theme.breakpoints.down('sm')]: {
        paddingTop: 10,
        paddingLeft: 10,
        paddingRight: 10,
    },
}))

const BaseButton = styled(Button)(() => ({
    width: '100%',
    height: 52,
    borderRadius: 6,
    fontFamily: 'JetBrains Mono',
    fontSize: 16,
    fontWeight: 800,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#D5FE71',
    textTransform: 'none',
}))

const SocialButton = styled(BaseButton)(() => ({
    width: '100%',
    color: 'white',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
}))

const SocialIcon = styled(Box)(() => ({
    width: 20,
    height: 20,
    marginRight: 10
}))

const LineBar = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 35,
    paddingBottom: 35,
}))

const Line = styled(Box)(() => ({
    flex: 1,
    height: 1,
    backgroundColor: '#C4C4C4'
}))

const OrBox = styled(Box)(() => ({
    fontFamily: 'JetBrains Mono',
    fontSize: 16,
    fontWeight: 800,
    paddingLeft: 27,
    paddingRight: 27,
}))

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: '#1C1C29',
        fontSize: 16,
        width: '100%',
        padding: '10px 12px',
        transition: theme.transitions.create([
        'border-color',
        'background-color',
        'box-shadow',
        ]),
        fontFamily: [
        'JetBrains Mono',
        ].join(','),
        '&:focus': {
        borderColor: theme.palette.primary.main,
        },
    },
}));

const FormItemLabel = styled(InputLabel)(() => ({
    color: '#FFFFFF',
    fontFamily: 'JetBrains Mono',
    fontSize: 16,
    fontWeight: 700,
    '&.Mui-focused': {
        color: '#FFFFFF'
    }
}))

const TCBox = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: '15px'
}))

const MainButton = styled(BaseButton)(() => ({
    backgroundColor: '#D5FE71',
    color: '#1C1C29',
    marginTop: 40,
    '&:hover': {
        backgroundColor: '#D5FE71',
    },
    '&.Mui-disabled': {
        backgroundColor: '#030311',
        color: '#FFFFFF'
    }
}))

const NavButton = styled(Button)((props) => ({
    textTransform: 'none',
    textDecoration: 'underline',
    fontFamily: 'JetBrains Mono',
    fontSize: 16,
    fontWeight: 800,
    color: '#FFFFFF',
    position: 'absolute',
    top: 49,
    right: 119,
    [props.theme.breakpoints.down('sm')]: {
        top:31,
        right: 12,
    },
}))

const TroubleButton = styled(Button)((props) => ({
    textTransform: 'none',
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeight: 400,
    color: '#FFFFFF',
    alignSelf: 'center',
    marginTop: 28,
}))

export default function AuthComponent({
    isRegistration = false,
    navigateFunc,
    onChange,
    errorMessage,
    nameError = false,
    emailErrorMessage = "",
    passwordErrorMessage = "",
    onSubmit,
    loading = false,
    termChecked,
    onTermChange,
    onGoogle,
    onFacebook,
}) {

    const [isOpen, setIsOpen] = useState(false);

    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    const desktopImg = isRegistration ? "/assets/images/sign_up_desktop.png" : "/assets/images/login_desktop.png"
    const mobileImg = isRegistration ? "/assets/images/sign_up_mobile.png" : "/assets/images/login_mobile.png"
    const title1 = isRegistration ? "Let's get you" : "Welcome back,"
    const title2 = isRegistration ? "started." : "let's up your game!"

    const renderNavButton = () =>
        (<NavButton onClick={() => navigateFunc && navigateFunc()}>{ isRegistration ? 'Login' : 'Sign up'}</NavButton>)
        
    const handleOpen = () => {
        setIsOpen(!isOpen);
    };

    return (
        <PageWrapper>
        <Grid container>
          <Grid item xs={12} md={6}>
            <SideBox>
              <ImageWrapper isNative={matches}>
                <img src={matches ? mobileImg : desktopImg} alt="main" style={{
                  maxWidth: '100%'
                }}/>
              </ImageWrapper>
              <LogoWrapper>
                <img
                  alt="Logo"
                  src="/assets/images/logo_auth.png"
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                />
              </LogoWrapper>
              {matches && renderNavButton()}
            </SideBox>
          </Grid>
          <Grid item xs={12} md={6} sx={{position: 'relative'}}>
            {!matches && renderNavButton()}
            <MainBox>
              <HeaderText>{title1}</HeaderText>
              <HeaderText sx={{marginBottom: matches ? '5px' : '32px'}}>{title2}</HeaderText>
                <Alert
                    severity="error"
                    variant="filled"
                    style={{
                        display: errorMessage ? "" : "none",
                        marginBottom: "8px",
                    }}
                    >
                    <AlertTitle>
                        <strong>Error: {errorMessage}</strong>
                    </AlertTitle>
                </Alert>
              <SocialButton sx={{marginBottom: '16px'}} onClick={() => onFacebook && onFacebook()}>
                <SocialIcon><img src="/assets/images/facebook_logo.png" style={{width: '100%'}} alt="facebook" /></SocialIcon>
                <div>{isRegistration ? 'Sign up' : 'Log in'} with Facebook</div>
              </SocialButton>
              {/* <SocialButton sx={{marginBottom: '16px'}}>
                <SocialIcon><img src="/assets/images/apple_logo.png" style={{width: '100%'}} alt="apple" /></SocialIcon>
                <div>{isRegistration ? 'Sign up' : 'Log in'} with Apple</div>
              </SocialButton> */}
              <SocialButton  onClick={() => onGoogle && onGoogle()}>
                <SocialIcon><img src="/assets/images/google_logo.png" style={{width: '100%'}} alt="google"/></SocialIcon>
                <div>{isRegistration ? 'Sign up' : 'Log in'} with Google</div>
              </SocialButton>
              <LineBar>
                  <Line></Line>
                  <OrBox>Or</OrBox>
                  <Line></Line>
              </LineBar>
              {
                isRegistration && (
                    <FormControl sx={{width: '100%'}} variant="standard">
                        <FormItemLabel shrink htmlFor="bootstrap-input">
                            NAME
                        </FormItemLabel>
                        <BootstrapInput 
                            id="bootstrap-input" name="name" required
                            error={nameError}
                            // helperText={nameError ? "Please enter a valid name" : ""}
                            onChange={onChange}
                        />
                    </FormControl>
                )
              }
              <FormControl sx={{width: '100%', marginTop: isRegistration ? '15px' : 0}} variant="standard">
                <FormItemLabel shrink htmlFor="bootstrap-input">
                  EMAIL
                </FormItemLabel>
                <BootstrapInput 
                    id="bootstrap-input" name="email" required type="email"
                    error={emailErrorMessage !== ""}
                    // helperText={emailErrorMessage !== "" ? emailErrorMessage : ""}
                    onChange={onChange}
                />
              </FormControl>
              <FormControl sx={{width: '100%', marginTop: '15px'}} variant="standard">
                <FormItemLabel shrink htmlFor="bootstrap-input">
                  PASSWORD
                </FormItemLabel>
                <BootstrapInput
                    id="bootstrap-input" name="password" required type="password"
                    error={passwordErrorMessage !== ""}
                    // helperText={passwordErrorMessage !== "" ? passwordErrorMessage : ""}
                    onChange={onChange}
                />
              </FormControl>
              {
                isRegistration && (
                    <TCBox>
                        <FormControlLabel sx={{marginRight: 0, paddingLeft: 0}} 
                            control={
                                <Checkbox
                                    sx={{
                                        color: '#D5FE71',
                                        '&.Mui-checked': {
                                        color: '#D5FE71',
                                        },
                                    }} 
                                    icon={<Brightness1Rounded />}
                                    checkedIcon={<CheckCircle />}
                                    checked={termChecked}
                                    onChange={onTermChange}
                                />
                            }
                        label={''}
                        />
                        <Box sx={{fontWeight: '100'}}>
                            I accept <span style={{fontWeight: 800}}><a href="https://www.ucoachugolf.com/terms" target="_blank" style={{textDecoration: 'none'}} rel="noreferrer">T&C</a></span>'s and <span style={{fontWeight: 800}}><a href="https://www.ucoachugolf.com/privacy" target="_blank" style={{textDecoration: 'none'}} rel="noreferrer">Privacy Policy</a></span>
                        </Box>
                    </TCBox>     
                )
              }
              <MainButton data-testid="main-button" onClick={onSubmit} disabled={loading} name={isRegistration ? 'signup' : 'login'}>{isRegistration ? 'Sign up' : 'Log in'}</MainButton>
              {
                !isRegistration && (
                    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <TroubleButton onClick={handleOpen}>Trouble logging in?</TroubleButton>
                    </Box>
                )
              }
            </MainBox>
          </Grid>
        </Grid>
        <ForgotPassword
            isDialogOpened={isOpen}
            handleCloseDialog={() => setIsOpen(false)}
        />
      </PageWrapper>
    )
}