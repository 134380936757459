import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  golferObj: {},
  viewAs: false,
};

export const golferSlice = createSlice({
  name: "golfer",
  initialState,
  reducers: {
    updateGolfer: (state, action) => {
      state.golferObj = action.payload;
      state.viewAs = action.payload.viewAs ? action.payload.viewAs : false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateGolfer } = golferSlice.actions;

export default golferSlice.reducer;
