/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect, useContext, useMemo } from "react";
import Box from "@mui/material/Box";
import {
  Container,
  Card,
  Typography,
  LinearProgress,
  Divider,
  Grid,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useDropzone } from "react-dropzone";
import { auth } from "../../config/firebase";
import {
  isNative,
  isSubscriptionActive,
  uploadVideoFile,
} from "../../utils/commonFunctions";
import { CustomButtonBlue } from "../../components/button/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import SubscriptionBox from "../subscription/SubscriptionBox";
import { redirectToPayment } from "../../config/payment";
import ReactPlayer from "react-player";
import { ANDROID_ANNUALLY_SUBSCRIBE, ANDROID_DAY_PASS_PRODUCT, ANDROID_MONTHLY_SUBSCRIBE, UPLOAD_INSTRUCTIONS_VIDEO, WAIT_FILE_PROCESS } from "../../_constants/globals";
import { useNavigate } from "react-router-dom";
import { PurchaseContext } from "../../contexts/PurchaseContext";
import { toggleUpgradeModal, toggleUpgrading } from "../../store/toastSlice";

const UploadVideo = (props) => {
  const navigate = useNavigate();
  const [subscribeOnly, toggleSubscribeOnly] = useState(false);
  const [progress, setProgress] = useState(0);
  const [loading, toggleLoading] = useState(false);
  const golferData = useSelector((state) => state.golfer.golferObj);
  const isSubscribed = isSubscriptionActive(golferData);
  const [selectedPlan, setSelectedPlan] = useState("monthlyPass");
  const [btnLoading, toggleBtnLoading] = useState(false);
  const dispatch = useDispatch();
  const showToast = useSelector((state) => state.toast.showToast);
  const showUpgradeModal = useSelector((state) => state.toast.showUpgradeModal);
  const [productCurrencyPrefix, setProductCurrencyPrefix] = useState("");

  const { 
      purchaseDayProduct, purchaseMonthSub, purchaseYearSub,
      dayProduct, monthSub, yearSub
    } = useContext(PurchaseContext)

  useEffect(() => {
    if (props?.subscribeOnly) {
      toggleSubscribeOnly(true);
      if (!isNative) {
        setProductPrefix();
      }
    }
  }, [props]);

  const handleClickOpen = () => {
    if (isNative) {
      navigate("/newswing");
    } else {
      if(!isSubscribed && golferData?.viewAs)
        return;
      
      setProductPrefix();
      dispatch(toggleUpgradeModal(true))
    }
  };

  const setProductPrefix= () => {
    // set the product prefix based on the location from store, default to NZ
    const productPrefix = golferData?.location?.countryCode !== "NZ"  ? "_usd" : "";
    setProductCurrencyPrefix(productPrefix)
  }

  const handleClose = () => {
    if (props?.subscribeOnly) {
      props?.onClose && props?.onClose();
    }
    // setOpen(false);
    dispatch(toggleUpgradeModal(false))
  };

  const onDrop = useCallback(async (acceptedFiles, fileRejections) => {
    await uploadVideoFile({
      fileRejections,
      acceptedFiles,
      setProgress,
      dispatch,
      toggleLoading,
      handleClose,
      golferId : golferData?.userId,
      viewAs : golferData?.viewAs,
    });
  }, [golferData]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "video/*",
    maxFiles: 1,
    maxSize: 31457280, //30MB
    minSize: 2097152, //2MB
    disabled: loading,
  });
  const proceedToPayment = async (product) => {
    toggleBtnLoading(true);
    const userToken = await auth.currentUser.getIdToken();
    const successPage = window.location.pathname;
    const cancelPage = window.location.pathname;
    redirectToPayment({ token: userToken, product, successPage, cancelPage });
  };

  const proceedNativePayment = (productId) => {
    dispatch(toggleUpgrading())
    if (productId === ANDROID_DAY_PASS_PRODUCT) {
      purchaseDayProduct()
    } 
    if (productId === ANDROID_MONTHLY_SUBSCRIBE) {
      purchaseMonthSub()
    }
    if (productId === ANDROID_ANNUALLY_SUBSCRIBE) {
      purchaseYearSub()
    }
  }

  const dayAmount = useMemo(() => {
    if (!isNative) return productCurrencyPrefix === "_usd" ? "5.99" : "9.99"
    if (dayProduct && dayProduct.price.includes(dayProduct.currency)) return dayProduct.price
    if (dayProduct) return dayProduct.currency + ' ' + dayProduct.price
  }, [isNative, dayProduct, productCurrencyPrefix])

  const monthAmount = useMemo(() => {
    if (!isNative) return productCurrencyPrefix === "_usd" ? "11.99" : "19.99"
    if (monthSub && monthSub.price.includes(monthSub.currency)) return monthSub.price
    if (monthSub) return monthSub.currency + ' ' + monthSub.price
  }, [isNative, monthSub, productCurrencyPrefix])

  const yearAmount = useMemo(() => {
    if (!isNative) return productCurrencyPrefix === "_usd" ? "89.99" : "149.99"
    if (yearSub && yearSub.price.includes(yearSub.currency)) return yearSub.price
    if (yearSub) return yearSub.currency + ' ' + yearSub.price
  }, [isNative, yearSub, productCurrencyPrefix])

  const renderSubscribeElement = () => {
    return (
      <>
        <Box textAlign="center">
          <Typography
            sx={{ fontSize: "16px", color: "#BFC0C1", margin: "10px 0" }}
          >
            Get unlimited on-demand swing analysis at your finger tips
          </Typography>
          <Typography
            sx={{ fontSize: "16px", color: "#BFC0C1", margin: "30px 0" }}
          >
            Choose one of the plans to transform your game
          </Typography>
          <Grid container sx={{ display: "flex", flexDirection: "row" }}>
            <Grid item xs={12} md={4}>
              <SubscriptionBox
                title="Day Pass"
                amount={ dayAmount }
                description="Unlimited analysis for 24 hours"
                onSelect={() => setSelectedPlan("dayPass")}
                onSubscribeClick={() =>
                  isNative ?
                    proceedNativePayment(ANDROID_DAY_PASS_PRODUCT)
                    :
                    proceedToPayment("24-hour"+productCurrencyPrefix
                )}
                isActive={selectedPlan === "dayPass"}
                loading={btnLoading}
                golferData={golferData}
                isNZD={ productCurrencyPrefix === "_usd" ? false : true }
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <SubscriptionBox
                title="Monthly"
                amount={ monthAmount }
                description="Billed monthly, for ongoing unlimited analysis"
                onSelect={() => setSelectedPlan("monthlyPass")}
                onSubscribeClick={() => 
                  isNative ?
                    proceedNativePayment(ANDROID_MONTHLY_SUBSCRIBE)
                    :
                    proceedToPayment("monthly"+productCurrencyPrefix
                )}
                isActive={selectedPlan === "monthlyPass"}
                loading={btnLoading}
                golferData={golferData}
                isNZD={ productCurrencyPrefix === "_usd" ? false : true }
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <SubscriptionBox
                title="Yearly"
                amount={ yearAmount }
                description="Billed yearly, save over 37% on the monthly"
                onSelect={() => setSelectedPlan("yearlyPass")}
                onSubscribeClick={() =>
                  isNative ?
                    proceedNativePayment(ANDROID_ANNUALLY_SUBSCRIBE)
                    :
                    proceedToPayment("annual"+productCurrencyPrefix
                )}
                isActive={selectedPlan === "yearlyPass"}
                loading={btnLoading}
                golferData={golferData}
                isNZD={ productCurrencyPrefix === "_usd" ? false : true }
              />
            </Grid>
          </Grid>
        </Box>
      </>
    );
  };
  const renderUploadElement = () => {
    return (
      <>
        <Box textAlign="center">
          <Card
            {...getRootProps({ className: "dropzone" })}
            sx={{
              fontFamily: "Inter",
              mt: 4,
              backgroundColor: "#1D70F9",
              cursor: "pointer",
            }}
          >
            <input {...getInputProps()} disabled={loading} />
            <p>{loading ? "Uploading..." : "Choose file"}</p>
          </Card>
          <Typography
            sx={{ fontSize: "16px", color: "#BFC0C1", margin: "10px 0" }}
          >
            File size should be between 2MB and 30MB
          </Typography>
          {showToast?.type === "error" && (
            <Typography
              sx={{ fontSize: "16px", color: "#e57373", margin: "10px 0" }}
            >
              {showToast?.msg}
            </Typography>
          )}
        </Box>
        {parseInt(progress) !== 0 || loading ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mt: 3,
              flex: 1,
              width: "100%",
            }}
          >
            <Box
              sx={{
                width: "100%",
                mr: 1,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <LinearProgress
                variant="determinate"
                color="secondary"
                value={parseInt(progress)}
              />
            </Box>
            <Box sx={{ minWidth: 35 }}>
              <Typography
                variant="body2"
                color={"secondary"}
              >{`${progress}%`}</Typography>
            </Box>
          </Box>
        ) : (
          <>
            <Typography align="center" color="secondary" sx={{ my: 3 }}>
              How to Record a Swing:
            </Typography>
            <ReactPlayer
              url={UPLOAD_INSTRUCTIONS_VIDEO}
              height="160px"
              width="80%"
              controls={true}
            />
          </>
        )}
      </>
    );
  };

  const renderDialog = () => {
    return (
      <Dialog
        open={showUpgradeModal}
        onClose={() => {
          loading
            ? alert(WAIT_FILE_PROCESS)
            : handleClose();
        }}
      >
        <Card
          style={{
            backgroundColor: "#202020",
            color: "black",
            overflow: "auto",
          }}
        >
          <DialogTitle
            style={{
              fontSize: 30,
              textAlign: "center",
              color: "#ffffff",
            }}
          >
            {props?.subscribeOnly ? "Unlimited Analysis" : "Upload a Swing"}
          </DialogTitle>
          <DialogContent
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Divider sx={{ width: "250px" }} />
            {isSubscribed && !subscribeOnly
              ? renderUploadElement()
              : renderSubscribeElement()}
          </DialogContent>
        </Card>
      </Dialog>
    );
  };

  const uploadSwingText = isNative ? "Record New Swing" : "Upload New Swing"

  return (
    <>
      {subscribeOnly ? (
        renderDialog()
      ) : (
        <Container sx={{ padding: "0 !important" }}>
          <Box
            sx={{
              justifyContent: { sx: "center", md: "flex-end" },
              display: "flex",
            }}
          >
            <CustomButtonBlue variant="text" onClick={handleClickOpen}>
              <Typography
                component={"p"}
                sx={{
                  padding: "10px 4px",
                  fontSize: "16px",
                }}
                id={isSubscribed ? "subscribed_upload" : "not_subscribed_upload"}
              >
                {golferData?.viewAs && !isSubscribed ? "Not Subscribed" : uploadSwingText}
              </Typography>
            </CustomButtonBlue>
          </Box>
          {renderDialog()}
        </Container>
      )}
    </>
  );
};

export default UploadVideo;
