import { AccordionSummary, Typography, Box } from "@mui/material";
import React from "react";
import { getCategoryByLevel } from "../../../utils/commonFunctions";

const AccordionHeader = (props) => {
  const { name, text, penalty, line_description, line } = props.data;
  return (
    <AccordionSummary
      expandIcon={
        <img
          src={"/assets/images/upArrow.svg"}
          alt="dashboard accordian header play"
          style={{
            width: "25px",
            transform: "rotateX(180deg)",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        />
      }
      aria-controls="panel1bh-content"
      id="panel1bh-header"
      sx={{
        borderLeftWidth: "2px",
        borderLeftColor:
          penalty === 0
            ? "green.primary"
            : penalty === 1
            ? "red.primary"
            : "yellow.primary",
        borderLeftStyle: "solid",
      }}
    >
      <Box>
        <Typography
          component={"h5"}
          variant={"h5"}
          sx={{
            fontSize: "20px",
            fontFamily: "JetBrains Mono",
            textTransform: "capitalize",
          }}
        >
          {`${getCategoryByLevel(line)} - ${name}`}
        </Typography>
        <Typography
          component={"p"}
          variant="p"
          sx={{
            fontSize: "16px",
            fontFamily: "Inter",
            lineHeight: "20px",
          }}
        >
          <Typography
            component={"span"}
            variant="p"
            sx={{
              color:
                penalty === 0
                  ? "green.primary"
                  : penalty === 1
                  ? "red.primary"
                  : "yellow.primary",
            }}
          >
            {text}
          </Typography>{" "}
          at {line_description}
        </Typography>
      </Box>
    </AccordionSummary>
  );
};

export default AccordionHeader;
