import { configureStore } from "@reduxjs/toolkit";
import golferSlice from "./golferSlice";
import toggleToast from "./toastSlice";

export const store = configureStore({
  reducer: {
    golfer: golferSlice,
    toast: toggleToast,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});
