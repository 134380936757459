import { createTheme, ThemeProvider, CssBaseline } from "@mui/material";
import { BrowserRouter } from "react-router-dom";
import AuthContextProvider from "./contexts/AuthContext";
import Routes from "./routes";
import { StatusBar, Style } from '@capacitor/status-bar';
import { Capacitor } from "@capacitor/core";
import { TextZoom } from '@capacitor/text-zoom';
import { App as CapacitorApp } from '@capacitor/app';
import { store } from "./store/store";
import { Provider } from "react-redux";
import { INSPECTLET_WID, GA_MEASUREMENT_ID, GTM_ID } from "./_constants/globals";
import { install } from "inspectlet-es";
import ReactGA from "react-ga4";
import TagManager from 'react-gtm-module';
import PurchaseContextProvider from "./contexts/PurchaseContext";

// initialize inspectlet
install(INSPECTLET_WID);

// initialize GA
ReactGA.initialize(GA_MEASUREMENT_ID);

// initialize tag manager
TagManager.initialize({ gtmId: GTM_ID });

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 300,
      sm: 600,
      md: 900,
      lg: 1024,
      xl: 1200,
    },
  },
  palette: {
    mode: "dark",
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#ffffff",
    },
    background: {
      default: "#202020",
    },
    blue: {
      primary: "#1D70F9",
      secondary: "#1e88e5",
    },
    red: {
      primary: "#E21414",
      secondary: "#df4b44",
    },
    yellow: {
      primary: "#FFB612",
      secondary: "#ffcf0f",
    },
    green: {
      primary: "#199307",
      secondary: "#23c70b",
    },
    text: {
      primary: "#FFFFFF",
      secondary: "#202020",
      gray: "#686870",
    },
  },
  typography: {
    fontFamily: "JetBrains Mono, Inter",
    fontWeightLight: 600,
    fontWeightMedium: 700,
    fontWeightBold: 800,
  },
});

if (Capacitor.isNativePlatform()) {
  StatusBar.setBackgroundColor({color: "#000000"})

  TextZoom.set({value: 1.0})

  CapacitorApp.addListener('backButton', ({ canGoBack }) => {
    if(canGoBack){
      window.history.back()
    } else {
      CapacitorApp.exitApp()
    }
  });
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <AuthContextProvider>
          <PurchaseContextProvider>
            <BrowserRouter>
              <div className="App">
                <CssBaseline />
                {<Routes />}
              </div>
            </BrowserRouter>
          </PurchaseContextProvider>
        </AuthContextProvider>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
