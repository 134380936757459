import { Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  checkIfSubscriptionIsLimited,
  isSubscriptionActive,
  toastError,
  toastSuccess,
} from "../../../utils/commonFunctions";
import { isEmpty } from "lodash";
import moment from "moment";
import {
  CustomButtonBlue,
  CustomButtonOutlined,
} from "../../../components/button/CustomButton";
import UploadVideo from "../../../components/uploadVideo/UploadVideo";
import { cancelSubscription } from "../../../config/payment";
import { auth } from "../../../config/firebase";
import {
  SUBSCRIPTION_CANCELLED_FAILED,
  SUBSCRIPTION_CANCELLED_MSG,
} from "../../../_constants/globals";
import { toggleUpgradeModal } from "../../../store/toastSlice";

const SubscriptionStatus = () => {
  const golferData = useSelector((state) => state.golfer.golferObj);
  const isSubscribed = isSubscriptionActive(golferData);
  // Modification for trial
  const subscriptionOnTrial = golferData?.subscriptionOnTrial;
  const [btnLoading, toggleBtnLoading] = useState(false);
  const dispatch = useDispatch();

  let subscriptionEndDateText = "";
  let subscriptionEndDate = "";
  let appSubscriptionMsg = "";
  let showMonthlyCancelButton = false;
  let showRightElement = true;

  //condition to calculate subscription end date
  if (golferData?.subscriptionPeriodEndDate) {
    let endDate = new Date(0); // Epoch
    // subscriptionPeriodEndDate returns an object with seconds and nano seconds
    // set seconds and convert it back to local date
    endDate.setUTCSeconds(golferData?.subscriptionPeriodEndDate?.seconds); // moment().format('lll'); -> Mar 28, 2022 1:24 PM
    subscriptionEndDate = moment(endDate).format("lll");
  }

  const cancelProduct = async () => {
    toggleBtnLoading(true);
    const userToken = await auth.currentUser.getIdToken();
    const response = await cancelSubscription({
      token: userToken,
    });
    toggleBtnLoading(false);
    if (response?.status === 200) {
      toastSuccess(SUBSCRIPTION_CANCELLED_MSG, dispatch);
    } else {
      toastError(SUBSCRIPTION_CANCELLED_FAILED, dispatch);
    }
  };

  //condition to handle different text/button on left/right element based on different status
  if (
    isSubscribed &&
    !isEmpty(golferData?.subscriptionPeriodEndDate) &&
    golferData?.subscriptionPlan === "24-hour"
  ) {
    subscriptionEndDateText = `(expires ${subscriptionEndDate})`;
  } else if (
    isSubscribed &&
    isEmpty(golferData?.subscriptionPeriodEndDate) &&
    golferData?.subscriptionPlan === "24-hour"
  ) {
    subscriptionEndDateText = "(pending use)";
  } else if (
    isSubscribed &&
    golferData?.subscriptionAutoRenews &&
    (golferData?.subscriptionPlan === "monthly" ||
      golferData?.subscriptionPlan === "annual")
  ) {
    if (golferData?.subscriptionBillingMethod === "stripe")
      showMonthlyCancelButton = true;
    else
      appSubscriptionMsg = `Subscribed using ${golferData?.subscriptionBillingMethod},
        use your ${golferData?.subscriptionBillingMethod === "apple" ? "apple" : "google"} account to cancel`;
    subscriptionEndDateText = `(renews ${subscriptionEndDate})`;
  } else if (
    isSubscribed &&
    !golferData?.subscriptionAutoRenews &&
    (golferData?.subscriptionPlan === "monthly" ||
      golferData?.subscriptionPlan === "annual")
  ) {
    showRightElement = false;
    subscriptionEndDateText = `(expires ${subscriptionEndDate})`;
  }


  // Condition to add "On Trial" text to the plan name
  let planDisplayName = golferData?.subscriptionPlanDisplayName;
  if (planDisplayName && subscriptionOnTrial)
    planDisplayName = planDisplayName + " - On Trial";

  return (
    <Grid
      container
      spacing={2}
      sx={{ marginBottom: "40px", alignItems: "center" }}
    >
      <Grid item xs={12} sm={4}>
        {isSubscribed ? (
          <Typography
            component="p"
            sx={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: "16px",
              lineHeight: "25px",
              color: "#FDFBFB",
            }}
          >
            {planDisplayName ||
              (checkIfSubscriptionIsLimited(golferData) && "Limited") ||
              "Unknown"}
            {!!subscriptionEndDateText && (
              <>
                <br />
                {subscriptionEndDateText}
              </>
            )}
          </Typography>
        ) : (
          <Typography
            component="p"
            sx={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: "16px",
              lineHeight: "25px",
              color: "#FDFBFB",
            }}
          >
            Not Subscribed
          </Typography>
        )}
      </Grid>
      {showRightElement && (
        <Grid item xs={12} sm={4}>
          {showMonthlyCancelButton && (
            <CustomButtonOutlined
              disabled={btnLoading}
              onClick={() => cancelProduct()}
            >
              <Typography
                component={"p"}
                sx={{
                  fontFamily: "JetBrains Mono",
                  fontStyle: "normal",
                  fontWeight: "normal",
                  fontSize: "16px",
                  lineHeight: "18px",
                }}
              >
                Cancel Subscription
              </Typography>
            </CustomButtonOutlined>
          )}
          {!!appSubscriptionMsg && (
            <Typography
              component={"p"}
              sx={{
                fontFamily: "JetBrains Mono",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "18px",
                color: "#BFC0C1",
              }}
            >
              {appSubscriptionMsg}
            </Typography>
          )}
          {(!isSubscribed || checkIfSubscriptionIsLimited(golferData)) && (
            <CustomButtonBlue onClick={(e) => { e.preventDefault(); dispatch(toggleUpgradeModal(true)) } }>
              <Typography
                component={"p"}
                sx={{
                  padding: "10px",
                  fontFamily: "JetBrains Mono",
                  fontStyle: "normal",
                  fontWeight: "normal",
                  fontSize: "16px",
                  lineHeight: "18px",
                }}
              >
                Subscribe
              </Typography>
            </CustomButtonBlue>
          )}
        </Grid>
      )}
      <UploadVideo subscribeOnly />
    </Grid>
  );
};

export default SubscriptionStatus;
