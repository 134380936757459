import React from 'react'
import { Box, Typography } from "@mui/material";
import UploadIcon from "../../../assets/icons/Upload";
import moment from "moment";
import { uploadVideoWithSwingData } from "../../../api/swing";

const UploadingBox = (props) => {
  const { video_name, id, createdAt } = props.swingObj
  let createdDate = new Date(0); // Epoch
  createdDate.setUTCSeconds(createdAt.seconds); // set seconds and convert it back to local date

  const [uploading, setUploading] = React.useState(false)

  const uploadVideo = async () => {
    setUploading(true)
    await uploadVideoWithSwingData(video_name, id, props.userId)
    setUploading(false)
  }
  
  return (
    <div>
        <Box
          sx={{
            backgroundColor: "primary.main",
            padding: "20px",
            minWidth: "135px",
            minHeight: "179px",
            display: "flex",
            flexDirection: "column",
            flex: 1,
            alignItems: "center",
            justifyContent: "space-around",
          }}
          onClick={() => uploadVideo()}
        >
          {uploading ? (
            <Box sx={{ textAlign: "center", width: '50px', height: '50px' }}>
              <img
                src={"/assets/images/spinner.svg"}
                alt="React Logo"
                height={50}
                width={50}
              />
            </Box>
          ) : (
            <UploadIcon /> 
          )}
          <Typography
            variant="p"
            component="p"
            sx={{
              fontFamily: "JetBrains Mono",
              fontSize: "18px",
              marginTop: "20px",
              fontWeight: "normal",
            }}
          >
            {moment(createdDate).format("ddd DD MMM HH:mm a")}
          </Typography>
        </Box>
      </div>
  )
}

export default UploadingBox