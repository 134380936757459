import { AccordionSummary, Typography, Box } from "@mui/material";
import React from "react";

const AccordionHeader = (props) => {
  const { name, text, penalty } = props.data;
  return (
    <AccordionSummary
      expandIcon={
        <img
          src={"/assets/images/upArrow.svg"}
          alt="line breakdown header play"
          style={{
            width: "25px",
            transform: "rotateX(180deg)",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        />
      }
      aria-controls="panel1bh-content"
      id="panel1bh-header"
      sx={{
        borderLeftWidth: "2px",
        borderLeftColor:
          penalty === 0
            ? "green.primary"
            : penalty === 1
            ? "red.primary"
            : "yellow.primary",
        borderLeftStyle: "solid",
      }}
    >
      <Box>
        <Typography
          component={"h5"}
          variant={"h5"}
          sx={{
            fontSize: "20px",
            fontFamily: "JetBrains Mono",
            textTransform: "capitalize",
          }}
        >
          {name}
        </Typography>
        <Typography
          component={"p"}
          variant="p"
          sx={{
            fontSize: "16px",
            fontFamily: "Inter",
            ":first-letter": {
              textTransform: "capitalize",
            },
          }}
        >
          {text}
        </Typography>
      </Box>
    </AccordionSummary>
  );
};

export default AccordionHeader;
