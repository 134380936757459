import { Grid, Stack, Typography } from "@mui/material";
import React from "react";

const IssueInfoRow = () => {
  const Data = [
    {
      color: "green.primary",
      label: "About Right",
    },
    {
      color: "yellow.primary",
      label: "Slight Issue",
    },
    {
      color: "red.primary",
      label: "Needs Work",
    },
  ];

  const Row = ({ data }) => {
    return (
      <Stack
        direction={"row"}
        sx={{
          flexDirection: "row",
          textAlign: { xs: "center", md: "inherit" },
          alignItems: "center",
          marginRight: 2,
        }}
      >
        <Grid
          sx={{
            bgcolor: data.color,
            borderRadius: 20,
            height: 6,
            display: { xs: "none", md: "flex" },
            width: 20,
            marginRight: 1,
          }}
        >
          {" "}
        </Grid>
        <Grid>
          <Typography
            sx={{
              fontSize: "14px",
              fontFamily: "Inter",
              color: "secondary.main",
              display: "inline-block",
              borderBottomWidth: { xs: 3, md: 0 },
              borderBottomColor: { xs: data.color, md: "transparent" },
              borderBottomStyle: { xs: "solid", md: "none" },
            }}
          >
            {data.label}
          </Typography>
        </Grid>
      </Stack>
    );
  };

  return (
    <>
      <Grid container sx={{ mb: { xs: 2, md: 0 } }}>
        {Data.map((item, index) => (
          <Row data={item} key={index} />
        ))}
      </Grid>
    </>
  );
};

export default IssueInfoRow;
