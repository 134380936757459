import React from "react";

const UploadIcon = (props) => {
  return (
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="50" height="50" fill="#030311"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M21.35 22.3103H18C15.7909 22.3103 14 24.1012 14 26.3103V32.0689C14 34.2781 15.7909 36.0689 18 36.0689H31C33.2091 36.0689 35 34.2781 35 32.0689V26.3103C35 24.1012 33.2091 22.3103 31 22.3103H27.65V24.3103H31C32.1046 24.3103 33 25.2057 33 26.3103V32.0689C33 33.1735 32.1046 34.0689 31 34.0689H18C16.8954 34.0689 16 33.1735 16 32.0689V26.3103C16 25.2057 16.8954 24.3103 18 24.3103H21.35V22.3103Z" fill="#FDFBFB"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M25.5964 30C25.5964 30.5523 25.1487 31 24.5964 31C24.0441 31 23.5964 30.5523 23.5964 30L23.5964 16.2651L21.0079 18.8536C20.6174 19.2442 19.9842 19.2442 19.5937 18.8536C19.2032 18.4631 19.2032 17.83 19.5937 17.4394L23.7937 13.2394C23.9812 13.0519 24.2356 12.9465 24.5008 12.9465C24.766 12.9465 25.0204 13.0519 25.2079 13.2394L29.4079 17.4394C29.7984 17.83 29.7984 18.4631 29.4079 18.8536C29.0174 19.2442 28.3842 19.2442 27.9937 18.8536L25.5964 16.4564L25.5964 30Z" fill="#FDFBFB"/>
    </svg>
  );
}

export default UploadIcon