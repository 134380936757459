import {
  addDoc,
  setDoc,
  updateDoc,
  collection,
  query,
  where,
  limit,
  getDoc,
  getDocs,
  serverTimestamp,
  doc,
  onSnapshot,
} from "@firebase/firestore";
import { db, auth } from "../config/firebase";
import * as Sentry from "@sentry/react";

export const uploadPurchaseData = async (updatedProduct, userId) => {
  addDoc(collection(db, `golfers/${userId}/android_receipts`), {
    ...updatedProduct
  })
}

export const addGolfer = async ({
  height,
  ability,
  handicap,
  userId,
  name,
  _id,
  golfMembershipId,
  registrationChannel,
  registrationChannelInfo
}) => {
  setDoc(doc(db, "golfers", _id), {
    createdAt: serverTimestamp(),
    lastUpdated: serverTimestamp(),
    heightInCentimeters: height,
    ability,
    handicap,
    displayHeightInCentimeters: true,
    name,
    userId,
    golfMembershipId,
    registrationChannel,
    registrationChannelInfo
  })
    .then(() => {
      return true; //success does not return anything so just return true
    })
    .catch((e) => {
      Sentry.captureMessage("failed setDoc for golfers in db.js", "debug");
      Sentry.captureMessage(e.message+";"+e.code, "debug");
      return false;
    });
};
export const updateGolfer = async (data) => {
  const golfer = await getGolferFromUserId(data.userId);
  return updateDoc(doc(db, "golfers", golfer.id), {
    heightInCentimeters: data.heightInCentimeters ? data.heightInCentimeters : null,
    ability: data.ability ? data.ability : null,
    handicap: data.handicap ? data.handicap : null,
    displayHeightInCentimeters: data.displayHeightInCentimeters ? data.displayHeightInCentimeters : true,
    name: data.name,
    userId: data.userId,
    golfMembershipId: data.golfMembershipId ? data.golfMembershipId : "",
    registrationChannel: data.registrationChannel ? data.registrationChannel : "",
    registrationChannelInfo: data.registrationChannelInfo ? data.registrationChannelInfo : {},
    isDeleted: data.isDeleted ? data.isDeleted : false,
  })
    .then(() => {
      return true; //success does not return anything so just return true
    })
    .catch((e) => {
      Sentry.captureMessage("failed updateDoc for golfers in db.js", "debug");
      Sentry.captureMessage(e.message+";"+e.code, "debug");
      return false;
    });
};

export const getGolferFromUserId = async (userId = auth?.currentUser?.uid) => {
  if (userId) {
    const q = query(collection(db, "golfers"), where("userId", "==", userId));
    const querySnapshot = await getDocs(q);
    let golfer = null;
    querySnapshot.forEach((doc) => {
      golfer = doc;
    });
    return golfer;
  }
};

export const getGolferFromUserIdViewAs = async (userId, loggedinUserId = auth?.currentUser?.uid) => {
  if (userId) {
    let golfer;
    try{
      golfer = await getDoc(doc(db, "golfers", userId));
    } catch(e) {
      // the golferId is different than userId in some cases, the code below will handle it
      Sentry.captureMessage("failed getDoc for golfers based on userId in swing.js", "debug");
      Sentry.captureMessage(e.message+";"+e.code, "debug");
    }

    if (!golfer && loggedinUserId) {
      const golferDocs = await getDocs(
        query(collection(db, "golfers"), 
          where('coachIds','array-contains', loggedinUserId),
          where("userId", "==", userId),
          limit(1)
        )
      );
      golfer = golferDocs.docs[0];
    }
    return golfer;
  }
};

export const sendFeedback = async ({ email, golferId, name, feedback, userId }) => {
  return addDoc(collection(db, "user_feedback"), {
    createdAt: serverTimestamp(),
    lastUpdated: serverTimestamp(),
    email,
    golferId,
    name,
    feedback,
    userId,
  })
    .then(() => {
      return true; //success does not return anything so just return true
    })
    .catch((e) => {
      Sentry.captureMessage("failed addDoc for user_feedback in db.js", "debug");
      Sentry.captureMessage(e.message+";"+e.code, "debug");
      return false;
    });
};

export const getGolferData = (userId, golferData, updateGolferData) => {
  if (userId) {
    let golfer;

    try{
      golfer = query(
        collection(db, "golfers"),
        where("userId", "==", userId)
      );
    } catch (e) {
      // most likely because a golfer document doesn't exist for the user
      Sentry.captureMessage("failed query for golfers based on userId in db.js", "debug");
    }

    if (!golfer)
      return () => {};

    const snapshot = onSnapshot(golfer, (querySnapshot) => {
      let golfer = null;
      querySnapshot.forEach((doc) => {
        golfer = doc;
      });
      updateGolferData(golfer, golferData);
    });
    return snapshot;
  }
  return () => {};
};

export const getRelatedGolferData = async (userId) => {
  if (userId) {
    const queryObj = query(
      collection(db, "golfers"),
      where("coachIds", "array-contains", userId)
    );
    try {
      const querySnapshot = await getDocs(queryObj);
      let golfers = [];
      querySnapshot.forEach((doc) => {
        golfers.push(doc.data());
      });
      return golfers;
    } catch (e) {
      Sentry.captureMessage("failed query fetch related golfers", "debug");
      Sentry.captureMessage(e.message+";"+e.code, "debug");
      return [];
    }
  }
};

export const addFCMTokenToUser = async (userId, token) => {
  return updateDoc(doc(db, "golfers", userId), {
    FCMRegistrationToken: token
  })
}
