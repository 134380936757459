import { auth } from "../config/firebase";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  updateProfile,
  updatePassword,
  signInWithCredential,
  GoogleAuthProvider,
  signInWithPopup,
  FacebookAuthProvider,
  fetchSignInMethodsForEmail,
} from "firebase/auth";
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { FirebaseMessaging } from '@capacitor-firebase/messaging';
import { useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { isEmpty } from "lodash";
import { setUserId } from "./crashlytics";
import { isNative } from "../utils/commonFunctions";

export const login = ({ email, password }) => {
  return new Promise((resolve, reject) => {
    signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      if (isNative) {
        setUserId(email)
        FirebaseAuthentication.signInWithEmailAndPassword({
          email,
          password
        })
        .then(() => {
          FirebaseMessaging.checkPermissions().then((value) => {
            if (value.receive !== 'granted') FirebaseMessaging.requestPermissions()
            resolve({user: userCredential.user});
          })
        })
        .catch((error) => {
          console.log("Firebase authentication error ------ ", JSON.stringify(error))
        })
      } else {
        resolve({user: userCredential.user});
      }
    })
    .catch((error) => {
      let message = "";
      switch (error.code) {
        case "auth/invalid-email":
          message = "Invalid credentials";
          break;
        case "auth/user-not-found":
          message = "Invalid credentials";
          break;
        case "auth/wrong-password":
          message = "Invalid credentials";
          break;
        case "auth/too-many-requests":
          message = "Access to this account has been temporarily disabled due to many failed login attempts";
          break;
        default:
          message = error.message;
          break;
      }
      resolve({error: message})
    });
  })
  
};

export const signup = async ({ email, password, name }) => {
  return createUserWithEmailAndPassword(auth, email, password)
    .then(async (userCredential) => {
      if (isNative) {
        await FirebaseAuthentication.signInWithEmailAndPassword({
          email,
          password
        })

        const permissions = await FirebaseMessaging.checkPermissions()
        if (permissions.receive !== 'granted') {
          await FirebaseMessaging.requestPermissions()
        }
      }
      await updateProfile(userCredential.user, { displayName: name }); // update profile name
      return {
        user: userCredential.user,
      };
    })
    .catch((error) => {
      let message = "";
      switch (error.code) {
        case "auth/email-already-in-use":
          message = "The provided email is already in use";
          break;

        default:
          message = error.message;
          break;
      }
      return {
        error: message,
      };
    });
};

export const logout = () => {
  FirebaseAuthentication.signOut()
  return signOut(auth)
    .then(() => {
      // Sign-out successful.
    })
    .catch((error) => {
      // An error occurred.
    });
};

export const useAuth = () => {
  const { userData } = useContext(AuthContext);
  return userData && !isEmpty(userData) ? true : false;
};

export const sendPasswordResetMail = (email) => {
  return sendPasswordResetEmail(auth, email)
    .then(() => {
      return {
        status: true,
      };
    })
    .catch((error) => {
      return {
        error: error.message,
      };
    });
};

export const updateUserPassword = (password, newPassword) => {
  return login({ email: auth.currentUser.email, password }).then((data) => {
    if (isEmpty(data.error)) {
      return updatePassword(auth.currentUser, newPassword)
        .then(() => {
          return {
            status: true,
          };
        })
        .catch((error) => {
          return {
            error: error.message,
          };
        });
    } else {
      return {
        error: data.error,
      };
    }
  });
};

export const updateUserName = (displayName) => {
  return updateProfile(auth.currentUser, { displayName })
    .then(() => {
      return {
        status: true,
      };
    })
    .catch((error) => {
      return {
        error: error.message,
      };
    });
};

export const deleteUser = (password) => {
    return auth.currentUser.delete()
        .then(() => {
          return {
            status: true,
          };
        })
        .catch((error) => {
          return {
            error: error.message,
          };
        });
  };


const signIn = (provider) => signInWithPopup(auth, provider);

export const signInWithGoogle = () => {
  if (isNative) {
    return new Promise((resolve, reject) => {
      console.log("Signin With Google ------ ")
      FirebaseAuthentication.signInWithGoogle()
        .then((value) => {
          const credential = GoogleAuthProvider.credential(
            value.credential?.idToken,
          );
          signInWithCredential(auth, credential)
            .then((data) => resolve(data))
            .catch((err) =>
              console.log('Google Signin Error --- ', JSON.stringify(err)),
            );
        })
        .catch((error) => {
          reject(error)
          console.log("Google Native Login Error ----- ", JSON.stringify(error))
        });
    });
  } else {
    const provider = new GoogleAuthProvider();
    provider.addScope('email');
    return signIn(provider);
  }
};

export const signInWithFacebook = () => {
  if (isNative) {
    return new Promise((resolve, reject) => {
      FirebaseAuthentication.signInWithFacebook()
        .then((value) => {
          const credential = FacebookAuthProvider.credential(
            value.credential?.accessToken,
          );
          signInWithCredential(auth, credential)
            .then((data) => {
              resolve(data);
            })
            .catch((err) => {
              console.log('Facebook Signin Error --- ', JSON.stringify(err));
              reject(err);
            });
        })
        .catch((error) => {
          console.log("Facebook Native Login Error ----- ", JSON.stringify(error))
          reject(error);
        });
    });
  } else {
    const provider = new FacebookAuthProvider();
    provider.addScope('email');
    return signIn(provider);
  }
};

export const fetchEmailProviders = async (email) => {
  return await fetchSignInMethodsForEmail(auth, email);
};