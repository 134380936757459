import React from "react";

const FacePerson = (props) => {
  return (
    <svg width="59" height="118" viewBox="0 0 59 118" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M44 27.5C44 32.1944 40.1944 36 35.5 36C30.8056 36 27 32.1944 27 27.5C27 22.8056 30.8056 19 35.5 19C38.1959 19 40.5987 20.2551 42.1559 22.2128" stroke="white" stroke-width="1.8" stroke-linecap="round"/>
      <path d="M12.7118 40.4529C17.7275 40.8527 23.0973 41.5289 29.7555 42.1152C33.9531 38.9363 36.835 39.4005 40.0431 43.1249C44.1821 43.7174 46.5471 47.2729 44.4784 51.4213C42.8234 54.7401 39.0587 64.0627 37.3832 68.3091C43.2957 79.2716 55.4753 101.73 56.8944 103.863C60.3344 109.034 54.9822 112.987 51.2772 107.418C49.3851 104.574 34.9193 84.5056 27.9228 74.827C27.9228 84.1106 27.9228 103.33 27.9228 105.937C27.9228 112.176 21.4609 112.666 20.8275 107.313C20.4193 103.863 17.2807 78.6786 16.6886 74.827C14.9147 63.2887 20.8275 57.3464 22.6013 53.4947C24.3752 49.643 23.4882 48.4578 20.8275 48.1616C18.1668 47.8653 7.99466 46.8828 4.27179 45.495C-0.153423 43.8454 0.247394 38.6162 3.4627 35.2614C8.1858 30.3333 28.8666 8.85524 36.5688 1C37.7514 2.38243 40.2988 4.18231 41.4334 5.87477C44.0196 9.73245 39.0011 12.7813 39.0011 12.7813L35.4878 9.6666" stroke="white" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
      <circle cx="3.5" cy="3.5" r="3.5" transform="matrix(1 0 0 -1 35 118)" fill="white"/>
    </svg>
  );
}

export default FacePerson