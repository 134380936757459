import React, { createContext, useEffect, useState } from "react";
import { Preferences } from '@capacitor/preferences'
import { isNative } from "../utils/commonFunctions";

export const AuthContext = createContext();

const AuthContextProvider = (props) => {
  const [userData, setUserData] = useState({});
  const [loadingApp, toggleLoadingApp] = useState(true);

  useEffect(() => {
    if (!isNative) {
      const loginData = JSON.parse(localStorage.getItem("loginInfo"));
      setUserData(loginData);
    } else {
      Preferences.get({key: "loginInfo"}).then((result) => {
        result.value && setUserData(JSON.parse(result.value));
      })
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("loginInfo", JSON.stringify(userData));
    toggleLoadingApp(false);
  }, [userData]);

  const updateUserData = (data) => {
    setUserData(data);
    if (isNative) {
      Preferences.set({
        key: "loginInfo",
        value: JSON.stringify(data || {})
      })
    }
  };

  return (
    <AuthContext.Provider
      value={{
        userData,
        updateUserData,
        loadingApp,
      }}
    >
      {!loadingApp ? props.children : null}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
