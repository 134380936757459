import React from "react";
import UploadVideo from "../../../components/uploadVideo/UploadVideo";
import SubHeader from "../../../components/subHeader";
import { Grid } from "@mui/material";
import ProfileForm from "./ProfileForm";
import BasePage from "../base/base";

const Profile = () => {
  const breadCrumbArr = [
    { displayLink: "Dashboard", path: "/dashboard" },
    { displayLink: "My Account", path: "/profile" },
  ];
  return (
    <BasePage>
      <SubHeader breadCrumbArr={breadCrumbArr} rightContent={<UploadVideo />} />
      <Grid container sx={{ alignItems: "stretch", mt: 6 }} columnSpacing={2}>
        <Grid item sm={12} md={10}>
          <ProfileForm />
        </Grid>
      </Grid>
    </BasePage>
  );
};

export default Profile;
