import React from "react";
import {
  Route,
  Routes as RoutesFromLibrary,
  Outlet,
  Navigate,
} from "react-router-dom";
import Login from "../components/auth/Login";
import Signup from "../components/auth/Signup";
import SwingHistory from "../pages/authenticated/swingHistory/SwingHistory";
import Dashboard from "../pages/authenticated/dashboard/Dashboard";
import Profile from "../pages/authenticated/profile/index";
import PageNotFound from "../pages/unAuthenticated/pageNotFound";
import { useAuth } from "../api/auth";
import Header from "../components/header/Header";
// import { onAuthStateChanged } from "firebase/auth";
import SwingBreakdown from "../pages/authenticated/swingBreakdown/SwingBreakdown";
import LineBreakdown from "../pages/authenticated/lineBreakdown/LineBreakdown";
import Feedback from "../pages/authenticated/feedback";
import Drills from "../pages/authenticated/drills";
import NewSwing from "../pages/authenticated/newswing/NewSwing";
import { useLocation } from 'react-router-dom'
import TabBar from "../components/tab/TabBar";
import MobileSwingHistory from "../pages/authenticated/swingHistory/MobileSwingHistory";
import { isNative } from "../utils/commonFunctions";



const Routes = () => {
  const isAuthenticated = useAuth();
  // function to check and return authenticated routes
  const Authenticated = () => {
    const loc = useLocation()
    const noHeader = loc.pathname.includes('/newswing') || loc.pathname.includes('/mobile-swing-history')
    return isAuthenticated ? (
      <>
        {!noHeader && (<Header authenticated={isAuthenticated} />)}
        <Outlet />
        {isNative && (<TabBar />)}
      </>
    ) : isNative ? (<Navigate to="/signup" />) : (<Navigate to="/login" />)
    ;
  };

  //function to check and return un-authenticated routes
  const UnAuthenticated = () => {
    return !isAuthenticated ? (
      <>
        {/* <Header authenticated={isAuthenticated} /> */}
        <Outlet />
      </>
    ) : (
      <Navigate to="/" />
    );
  };

  return (
    <RoutesFromLibrary>
      {/* All authenticated routes */}
      <Route element={<Authenticated />}>
        <Route path="/" element={<Dashboard />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/swing-history" element={<SwingHistory />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/feedback" element={<Feedback />} />
        <Route path="/newswing" element={<NewSwing />} />
        <Route path="/drills" element={<Drills />} />
        <Route path="/mobile-swing-history" element={ <MobileSwingHistory />} />
        <Route path="/swing-breakdown/:swingId" element={<SwingBreakdown />} />
        <Route
          path="/swing-breakdown/:swingId/line/:lineNumber"
          element={<LineBreakdown />}
        />
      </Route>
      {/* All unauthenticated routes */}
      <Route element={<UnAuthenticated />}>
        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<Login />} />
      </Route>
      <Route path="*" element={<PageNotFound />} />
    </RoutesFromLibrary>
  );
};

export default Routes;
