import React from "react";

const DownPerson = (props) => {
  return (
    <svg width="64" height="116" viewBox="0 0 64 116" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M47.1635 40.7044C44.2085 44.8774 43.3585 44.6854 39.1855 45.3804C35.0125 46.0754 30.6855 41.5744 30.6855 36.8804C30.6855 32.1864 34.4915 28.3804 39.1855 28.3804C41.8815 28.3804 44.2845 29.6354 45.8415 31.5934" stroke="white" stroke-width="1.8" stroke-linecap="round"/>
      <path d="M16.258 58.3502C15.364 51.9712 18.126 42.7402 21.83 40.0232C26.722 37.1922 28.216 41.4792 31.424 45.2042C34.868 47.5342 42.053 48.5282 37.943 54.7172C37.111 55.9702 35.128 60.8912 33.738 63.1322C31.699 66.4192 29.064 70.2972 28.068 72.8222C30.63 77.5732 33.9389 82.5632 33.5079 89.5422C32.9429 98.6682 28.203 109.469 29.503 110.114C33.713 112.204 29.718 114.883 24.929 113.495C21.553 112.516 19.6199 116.312 18.9869 110.956C18.5789 107.506 18.373 85.8072 17.108 82.1212C15.793 78.2882 10.716 72.6852 10.385 70.5412C9.43195 64.3712 14.751 61.7352 16.067 58.8772C17.84 55.0262 27.905 58.0132 24.201 51.9802C20.497 45.9472 19.158 42.6992 17.204 38.7102C14.979 34.1692 6.89295 18.4322 1.00795 4.64417C0.625954 4.63617 14.125 -0.341828 15.259 1.35117C17.846 5.20817 16.998 6.86617 16.998 6.86617L10.182 6.88017" stroke="white" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M47.7832 108.213C49.7162 108.213 51.2832 109.78 51.2832 111.713C51.2832 113.646 49.7162 115.213 47.7832 115.213C45.8502 115.213 44.2832 113.646 44.2832 111.713C44.2832 109.78 45.8502 108.213 47.7832 108.213Z" fill="white"/>
      <path d="M45.7168 31.4561C45.7168 31.4561 48.7828 34.1551 48.0748 38.8361L45.7168 31.4561Z" fill="black"/>
      <path d="M45.7168 31.4561C45.7168 31.4561 48.7828 34.1551 48.0748 38.8361" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M34.9395 30.5381C41.3335 33.4751 52.1805 43.3521 52.2215 43.1981" stroke="white" stroke-width="2" stroke-linecap="round"/>
      <path d="M26.8164 49.4541C28.4384 50.9371 32.9744 61.8281 33.7674 62.4991L26.8164 49.4541Z" fill="black"/>
      <path d="M26.8164 49.4541C28.4384 50.9371 32.9744 61.8281 33.7674 62.4991" stroke="white" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M28.7265 109.457C28.5175 107.686 27.3595 100.443 27.1275 93.3042C26.9075 86.5332 23.4355 80.8472 23.4355 80.8472" stroke="white" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
}

export default DownPerson