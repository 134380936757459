import React from "react";
import "./index.css";

import { Container, Grid, Breadcrumbs, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const SubHeader = (props) => {
  const breadCrumbArr = props.breadCrumbArr || [
    { displayLink: "Dashboard", path: "/dashboard" },
    { displayLink: "Swing History", path: "/" },
  ];
  const { rightContent = null } = props;
  return (
    <Container maxWidth="xl" sx={{ padding: "0 !important" }}>
      <Grid container sx={{ mt: 2, alignItems: "center" }}>
        <Grid item xs={12} sm={6}>
          <Breadcrumbs
            separator="|"
            aria-label="breadcrumb"
            color="secondary"
            variant="body2"
            sx={{ justifyContent: "center" }}
          >
            {breadCrumbArr.map((item, index) =>
              breadCrumbArr.length !== index + 1 ? (
                <Link
                  underline="hover"
                  color="text.primary"
                  component={RouterLink}
                  to={item.path}
                  key={index}
                >
                  {item.displayLink}
                </Link>
              ) : (
                <Link
                  color="text.primary"
                  key={index}
                  sx={{
                    textDecoration:
                      breadCrumbArr.length === 1 ? "none" : "underline",
                  }}
                >
                  {item.displayLink}
                </Link>
              )
            )}
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12} sm={6}>
          {rightContent}
        </Grid>
      </Grid>
    </Container>
  );
};

export default SubHeader;
