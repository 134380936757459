/* eslint-disable no-func-assign */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { Accordion, Box, Container, Grid, Typography } from "@mui/material";
import SubHeader from "../../../components/subHeader";
// import SwingUploadingRow from "./SwingUploadingRow";
import { AuthContext } from "../../../contexts/AuthContext";
import Slider from "react-slick";
import "./dashboard.css";
import AccordionHeader from "./AccordionHeader";
import AccordionData from "./AccordionData";
import ListingPlaceHolder from "../../../components/placeHolder/ListingPlaceHolder";
import { getSwingHistoryQuery } from "../../../api/swing";
import moment from "moment";
import CustomButton from "../../../components/button/CustomButton";
import { useNavigate } from "react-router-dom";
import EmptyDashboard from "../../../components/emptyDashboard";
import * as _ from "lodash";
import UploadVideo from "../../../components/uploadVideo/UploadVideo";
import LatestSwing from "./LatestSwing";
import { useSelector } from "react-redux";
import { hasError, isNative } from "../../../utils/commonFunctions";
import BasePage from "../base/base";
import UploadingBox from "./UploadingBox";
import PullToRefresh from 'react-simple-pull-to-refresh';
import { FirebaseMessaging } from '@capacitor-firebase/messaging';
import { addFCMTokenToUser } from "../../../api/db";

export default function Dashboard() {
  const breadCrumbArr = [{ displayLink: "Dashboard", path: "/dashboard" }];
  const { userData } = useContext(AuthContext);
  const [expanded, setExpanded] = React.useState("primary_0");
  const [successSwings, setSuccessSwings] = React.useState([]);
  const [loading, toggleLoading] = React.useState(true);
  const navigate = useNavigate();
  function swingDataOnSnapShot() {} // created as empty function to avoid typeError in Jest
  const golferData = useSelector((state) => state.golfer.golferObj);
  let viewAs = false;

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const updateData = async () => {
    if (golferData?.userId) {
      viewAs = false;
      if (golferData?.userId !== userData?.uid)
        viewAs = true;

      await getSwingData(golferData?.userId);
    } else if (userData?.uid) {
      await getSwingData(userData?.uid);
      viewAs = false;
    }
  }

  useEffect(() => {
    updateData()
    if (isNative && userData.uid) {
      FirebaseMessaging.getToken().then((value) => {
        value.token && value.token !== '' && addFCMTokenToUser(userData.uid, value.token)
      })
    }
    return () => swingDataOnSnapShot();
  }, [userData, golferData]);

  // Scroll to the top of the page when opened
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getSwingData = async (userId) => {
    let historyQuery = {
      userId,
      setSwings: setSuccessSwings,
      toggleLoading,
      viewAs,
      loggedinUserId: userData?.uid,
    }
    if (isNative) {
      historyQuery = {
        ...historyQuery,
        forMobile: true
      }
    }
    swingDataOnSnapShot = await getSwingHistoryQuery(historyQuery);
  };

  const isViewAs = () => {
    if (golferData?.userId && userData?.uid && golferData.userId !== userData.uid)
      return true;
    else
      return false;
  }

  const scoreBox = (swingObj, index) => {
    let createdDate = new Date(0); // Epoch
    createdDate.setUTCSeconds(swingObj.createdAt.seconds); // set seconds and convert it back to local date
    const showError = hasError(swingObj);

    return (
      <div key={index}>
        <Box
          sx={{
            backgroundColor: "primary.main",
            padding: "20px",
            minWidth: "160px",
            minHeight: "180px",
            cursor: showError ? "not-allowed" : "pointer",
          }}
          key={index}
          onClick={() =>
            !showError && navigate("/swing-breakdown/" + swingObj.id)
          }
        >
          {showError ? (
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/error.png`}
              alt="Error"
              height={66}
            />
          ) : (
            <Typography
              variant="h2"
              component="h2"
              sx={{ fontFamily: "JetBrains Mono", fontSize: "55px" }}
            >
              {Math.floor(swingObj.score * 100)}
            </Typography>
          )}
          <Typography
            variant="p"
            component="p"
            sx={{
              fontFamily: "JetBrains Mono",
              fontSize: "18px",
              marginTop: "20px",
              fontWeight: "normal",
            }}
          >
            {moment(createdDate).format("ddd DD MMM HH:mm a")}
          </Typography>
        </Box>
      </div>
    );
  };

  const analyzingBox = (swingObj, index) => {
    let createdDate = new Date(0); // Epoch
    createdDate.setUTCSeconds(swingObj.createdAt.seconds); // set seconds and convert it back to local date
    const progressPercentage =
      Math.round(swingObj.progress * 100) > 100
        ? 100
        : Math.round(swingObj.progress * 100);
    return (
      <div key={index}>
        <Box
          sx={{
            backgroundColor: "secondary.main",
            padding: "20px",
            minWidth: "135px",
            minHeight: "179px",
            display: "flex",
            flexDirection: "column",
            flex: 1,
            alignItems: "center",
            justifyContent: "space-around",
          }}
          key={index}
        >
          <img
            src={"/assets/images/spinner.svg"}
            alt="React Logo"
            height={60}
            width={60}
          />
          <Typography
            variant="h2"
            component="h2"
            sx={{
              fontFamily: "JetBrains Mono",
              fontSize: "14px",
              fontWeight: "500",
              lineHeight: "18px",
              color: "primary.main",
            }}
          >
            {`${progressPercentage || 0}%`}
          </Typography>
          <Typography
            variant="h2"
            component="h2"
            sx={{
              fontFamily: "JetBrains Mono",
              fontSize: "14px",
              color: "primary.main",
              lineHeight: "18px",
            }}
          >
            Analyzing
          </Typography>
        </Box>
      </div>
    );
  };

  const renderFaceOnFixes = () => {
    let faceOnFixes = [];
    if (golferData?.primary_fixes?.length > 0) {
      faceOnFixes = _.orderBy(golferData?.primary_fixes, "line").filter(item => item.angle === 0 );
    }
    return (
      <>
      { faceOnFixes?.length > 0 && <Box
          sx={{
            marginTop: "20px",
            padding: "20px",
            backgroundColor: "primary.main",
          }}
        >
          <Typography component="p" variant="p" sx={{ marginBottom: "25px" }}>
            Face On
          </Typography>
          {faceOnFixes?.map((item, index) => {
            return (
              <Accordion
                expanded={expanded === `primary_${index}`}
                onChange={handleChange(`primary_${index}`)}
                sx={{ marginBottom: 2 }}
                key={`primary_${index}`}
              >
                <AccordionHeader data={item} />
                <AccordionData data={item} />
              </Accordion>
            );
          })}
        </Box>
      }
    </>
    );
  };

  const renderDownPlaneFixes = () => {
    let downPlanFixes = [];
    if (golferData?.primary_fixes?.length > 0) {
      downPlanFixes = _.orderBy(golferData?.primary_fixes, "line").filter(item => item.angle === 90 );
    }
    return (
      <>
      { downPlanFixes?.length > 0 && <Box
          sx={{
            marginTop: "20px",
            padding: "20px",
            backgroundColor: "primary.main",
          }}
        >
          <Typography component="p" variant="p" sx={{ marginBottom: "25px" }}>
            Down Plane
          </Typography>
          {downPlanFixes?.map((item, index) => {
            return (
              <Accordion
                expanded={expanded === `secondary_${index}`}
                onChange={handleChange(`secondary_${index}`)}
                sx={{ marginBottom: 2 }}
                key={`secondary_${index}`}
              >
                <AccordionHeader data={item} />
                <AccordionData data={item} />
              </Accordion>
            );
          })}
        </Box>
      }
      </>
    );
  };

  const viewAllBlock = () => {
    return (
      <div style={{ textAlign: "end", marginTop: "10px" }}>
        <CustomButton onClick={() => {
            navigate("/swing-history")
        }}>
          <Typography sx={{ fontSize: "16px" }}>{"View All Swings"}</Typography>
        </CustomButton>
      </div>
    );
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const renderSwingData = () => {
    return (
      <>
        <Typography
          component="p"
          variant="p"
          sx={{ marginTop: "35px", marginBottom: "-30px", fontSize: "14px" }}
        >
          Swing History
        </Typography>
        <div className="slider-container">
          <Slider {...settings}>
            {successSwings.map((item, index) => {
              if (item.processed === 0) {
                if (isNative) {
                  if (item.has_path === true) return analyzingBox(item, index);
                  // else return UploadingBox(item, index);
                  else return <UploadingBox swingObj={item} key={index} userId={userData?.uid} />
                } else {
                  return analyzingBox(item, index);
                }
              } else {
                return scoreBox(item, index);
              }
            })}
          </Slider>
        </div>
        {successSwings.length > 6 && viewAllBlock()}
        {golferData?.latest_swing && (
          <LatestSwing data={golferData.latest_swing} />
        )}
        { golferData?.primary_fixes && <Typography
            component="p"
            variant="p"
            sx={{ marginTop: "35px", marginBottom: "-10px", fontSize: "14px" }}
          >
            Areas To Work On
          </Typography>
        }
        { renderFaceOnFixes() }
        { renderDownPlaneFixes() }
      </>
    );
  };

  const rederSupportBlock = () => {
    return (
      <>
        <Typography
            component="p"
            variant="p"
            sx={{ marginTop: "35px", marginBottom: "10px", fontSize: "14px" }}
          >
            Support
          </Typography>
          <Grid
            container
            sx={{
              padding: "20px 40px 20px 20px",
              backgroundColor: "primary.main",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              marginBottom: "20px",
            }}
            onClick={() => navigate("/feedback")}
          >
            <Grid item xs>
              <Typography
                variant="h4"
                component="h4"
                sx={{
                  fontFamily: "JetBrains Mono",
                  fontSize: "18px",
                  color: "secondary.main",
                }}
              >
                Any Suggestions?
              </Typography>
              <Typography
                variant="p"
                component="p"
                sx={{
                  fontFamily: "Inter",
                  fontSize: "14px",
                  color: "text.gray",
                  marginTop: "10px",
                }}
              >
                Your feedback will help improve your uCOACHu golfing experience.
              </Typography>
            </Grid>
            <Grid item>
              <img
                src={"/assets/images/rightArrow.svg"}
                alt="React Logo"
                style={{
                  width: "15px",
                }}
              />
            </Grid>
          </Grid>
      </>
    )
  };

  const renderDrillsBlock = () => {
    return (
      <>
        <Typography
            component="p"
            variant="p"
            sx={{ marginTop: "35px", marginBottom: "10px", fontSize: "14px" }}
          >
            uCOACHu Drills
          </Typography>
          <Grid
            container
            sx={{
              padding: "20px 40px 20px 20px",
              backgroundColor: "primary.main",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              marginBottom: "20px",
            }}
            onClick={() => navigate("/drills")}
          >
            <Grid item xs>
              <Typography
                variant="h4"
                component="h4"
                sx={{
                  fontFamily: "JetBrains Mono",
                  fontSize: "18px",
                  color: "secondary.main",
                }}
              >
                Not ready to upload yet?
              </Typography>
              <Typography
                variant="p"
                component="p"
                sx={{
                  fontFamily: "Inter",
                  fontSize: "14px",
                  color: "text.gray",
                  marginTop: "10px",
                }}
              >
                View and practice some common drills that have proven to help our members.
              </Typography>
            </Grid>
            <Grid item>
              <img
                src={"/assets/images/rightArrow.svg"}
                alt="React Logo"
                style={{
                  width: "15px",
                }}
              />
            </Grid>
          </Grid>
      </>
    )
  };

  const handleRefresh = () => {
    return new Promise((resolve, reject) => {
      updateData().then(() => resolve(true)).catch(() => reject())
    })
  }

  const renderPageContent = () => (
    <BasePage>
        {loading && <ListingPlaceHolder />}
        {!loading && (
          <>
            <SubHeader
              breadCrumbArr={breadCrumbArr}
              rightContent={ <UploadVideo />}
            />
            {successSwings.length === 0 && <EmptyDashboard />}
            {successSwings.length !== 0 && renderSwingData()}
            {successSwings.length === 0 && renderDrillsBlock()}
            { !isViewAs() && rederSupportBlock()}
          </>
        )}
      </BasePage>
  )

  return isNative ? (
    <PullToRefresh onRefresh={handleRefresh}>
      {renderPageContent()}
    </PullToRefresh>
  ) : renderPageContent();
}
